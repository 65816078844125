
.fc-theme-kip-bootstrap {
  & a:not([href]) {
    color: inherit;
    text-decoration: inherit;
  }

  & .fc-list,
  & .fc-scrollgrid,
  & td,
  & th {
    border: 1px solid var(--bs-gray-400);
  }

  // HACK: reapply core styles after higher-precedence border statement above
  & .fc-scrollgrid {
    border-right-width: 0;
    border-bottom-width: 0;
  }
}

.fc-theme-kip-bootstrap-shaded {
  background-color: var(--bs-gray-200);
}
