@mixin make-triangle-for-pipeline($bg-color, $color) {
  background: $bg-color;
  color: $color;

  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 3rem;
    height: 0;
    top: 3rem;
    border-top: 1.5rem solid transparent;
    border-bottom: 1.5rem solid transparent;
    border-left: 1.5rem solid $bg-color;
    transform: rotate(90deg);

    @include media-breakpoint-up(lg) {
      transform: none;
      top: 0;
      width: 0;
      left: 100%;
    }
  }
}

.kip-pipeline {
  &__step {
    font-weight: 600;
    letter-spacing: 0.0025em;
    position: relative;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-bottom: 2em;

    @include make-triangle-for-pipeline(rgba(0, 84, 159, 0.15), $primary);

    @include media-breakpoint-up(lg) {
      margin-bottom: auto;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &--enrolled {
      @include make-triangle-for-pipeline(rgba(22, 119, 32, 0.15), #167720);
    }

    &--closed {
      @include make-triangle-for-pipeline(rgba(80, 93, 104, 0.05), #505d68);
    }
  }

  &__actions {
    display: inline-flex;
    flex: 1;
    flex-direction: row;
    font-weight: 600;
    min-height: 3rem;

    @include media-breakpoint-down(md) {
      .btn {
        width: 100%;
        border-radius: 0.25rem !important; // Override .btn-group, apply radius to all buttons in group at medium and below
        height: 3rem;

        &:not(:last-of-type) {
          margin-bottom: 1em;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      padding-left: 3rem;
    }

    &>* {
      flex: 1;
      font-weight: 600;
      letter-spacing: 0.0025em;
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }
}

.kip-event-timeline {
  $icon__font-size: 1rem;
  $icon__size: 2rem;
  $position-top: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  position: relative;

  &__description {
    color: $body-color;
    font-size: 13.56px;
    line-height: 18px;
  }

  &__entry {
    margin-bottom: $spacer;
    display: flex;
    position: relative;

    &:not(:last-child)::before {
      background-color: #cfcfcf;
      bottom: -$spacer;
      content: "";
      margin-left: #{$icon__font-size};
      position: absolute;
      top: $position-top;
      width: 1px;
    }
  }

  &__event {
    background-color: #fff;
    position: relative;
    flex: 1;
    margin: 0.25em 0 0 2em;
  }

  &__event-type {
    align-items: center;
    display: flex;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.02em;
    line-height: 16px;
    text-transform: uppercase;
  }

  &__due-date {
    text-align: right;
  }

  &__icon {
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #cfcfcf;
    display: inline-flex;
    height: $icon__size;
    justify-content: center;
    line-height: 100%;
    padding: 0;
    width: $icon__size;
    z-index: 999;
  }
}
