@use "sass:color";

.kip-tabs-container {
  padding: 0;

  .nav-tabs {
    background-color: #f1f1f1;
    border: 0;
  }

  .nav-link.active {
    border: 0;
    color: #00549f;
  }

  .nav-tabs .nav-item {
    margin: 0;
  }

  .nav-tabs .nav-link:hover,
  .nav-tabs .nav-link:focus {
    border: 0;
  }

  li.nav-item {
    a {
      @include make-overline-style;

      color: grey;
      border: 0;
      padding: 1rem 0.5rem;
      text-decoration: none;

      &:not(.active) {
        @include make-overline-style($color: #505D68);

        box-shadow: 2px 0 4px rgb(212 212 212 / 25%);

        &:hover {
          background-color: rgb(80 93 104 / 10%);
          color: color.adjust(#505d68, $lightness: -20%);
        }
      }
    }
  }
}
