@use "sass:math";
@use "sass:color";

$kip-calendar-heading__height: 2rem;
$interaction-color: #3187c5;
$holiday: #bc613c;
$past: #f8f9fa;
$session: #459d9a;
$assessment: #0d65d8;
$calendar-cards: (
  holiday: $holiday,
  session: $session,
  assessment: $assessment
);

@mixin make-event-text($type, $color) {
  &--#{$type} {
    color: $color;
  }
}

@mixin make-event-utilities($type, $color) {
  .text-#{$type} {
    color: $color;
  }
}

@each $type,
$color in $calendar-cards {
  @include make-event-utilities($type, $color);
}

.kip-calendar-event {
  background-color: #fff;
  border-radius: $border-radius--small;
  border: 1px solid $border-color;
  box-shadow: $box-shadow-sm;
  color: $raven;
  display: flex;
  flex-direction: column;
  transition: all 0.05s linear;

  &--is-selectable {
    box-shadow: $box-shadow;
    position: relative;

    &:hover {
      cursor: pointer;
      background-color: rgba(#f9f9f9, 1);
      border: 1px solid color.adjust($session, $lightness: 10%);
    }
  }

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__menu {
    color: $raven;
    padding: 0;
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__footer {
    background: transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__title {
    font-size: $h2-font-size;
  }

  &__label {
    font-size: 0.823rem;
    font-weight: 600;
    letter-spacing: 0.02em;
    line-height: 1rem;
    text-transform: uppercase;

    @each $type,
    $color in $calendar-cards {
      @include make-event-text($type, $color);
    }
  }

  &__attendee {
    color: $raven;
    font-size: 0.878rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--session-student {
      background-color: rgba($session, 0.25);
      border-radius: 4px;
      padding: 0.25rem 0.5rem;

      &::after {
        color: $session;
      }

      &:hover {
        background-color: rgba($session, 0.35);
        cursor: pointer;
      }
    }

    &--past {
      background-color: rgba($session, 0.1);
      border-radius: 4px;
      padding: 0.25rem 0.5rem;

      &:hover {
        background-color: rgba($session, 0.35);
      }
    }

    &--forfeit {
      text-decoration: line-through;

      &::after {
        content: "F";
        display: block;
        float: right;
        font-weight: bold;
      }

      &:hover {
        background-color: rgba($session, 0.1);
      }
    }

    &--deferred {
      text-decoration: line-through;
      background-color: rgba($session, 0.1);

      &::after {
        content: "D";
        display: block;
        float: right;
        font-weight: bold;
      }

      &:hover {
        background-color: rgba($session, 0.1);
      }
    }

    &--rescheduled {
      text-decoration: line-through;
      background-color: rgba($session, 0.1);

      &::after {
        content: "R";
        display: block;
        float: right;
        font-weight: bold;
      }

      &:hover {
        background-color: rgba($session, 0.1);
      }
    }

    &--home-makeup {
      background-color: rgba($session, 0.1);

      &::after {
        content: "H";
        display: block;
        float: right;
        font-weight: bold;
      }

      &:hover {
        background-color: rgba($session, 0.1);
        cursor: default;
      }
    }
  }

  &__session-student-count {
    align-items: center;
    background-color: $session;
    border-radius: 50%;
    color: #fff;
    display: flex;
    font-size: 0.75rem;
    font-weight: 600;
    height: 1.25rem;
    justify-content: center;
    line-height: 1.25rem;
    position: relative;
    width: 1.25rem;
    white-space: nowrap;
  }

  &__add {
    align-items: center;
    background-color: #fefefe;
    border-radius: 4px;
    border: 1px dashed #ccc;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
    width: 100%;

    &:hover {
      border: 1px solid rgba($assessment, 0.35);
      text-decoration: none;
    }

    &--booked {
      align-items: flex-start;
      border: 1px solid $border-color;
      padding: 0.75rem;

      &:hover {
        border: 1px solid $border-color;
        text-decoration: none;
      }
    }
  }
}

.kip-event-list-break {
  flex-basis: 100%;
  height: 0;
}

.kip-event-list {
  $gutter: 0.25rem;
  $row-gap: 0.5rem;
  $third: math.div(100%, 3);
  $half: math.div(100%, 2);

  padding: math.div($spacer, 2);
  display: block;

  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: math.div($spacer, 4);
  }

  &__event {
    margin: 0 0 $row-gap;
    width: 100%;

    @include media-breakpoint-up(lg) {
      @include make-detail-column($half);

      padding: $gutter;
      margin: 0;
    }

    &--session {
      @include media-breakpoint-up(xxl) {
        @include make-detail-column($third);

        padding: $gutter;
      }
    }
  }
}

.kip-calendar {
  &__heading {
    position: sticky;
    z-index: 1;
    top: 0;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid $border-color;
    display: flex;
    font-size: 0.823rem;
    font-weight: 600;
    height: $kip-calendar-heading__height;
    justify-content: center;
    letter-spacing: 0.02em;
    line-height: 1rem;
    padding: (math.div($spacer, 2)) 0;
    text-transform: uppercase;
  }
}

.kip-action-bar {
  align-items: center;
  background-color: rgb(45 50 56 / 95%);
  border-radius: 10px;
  bottom: 2rem;
  box-shadow: $box-shadow;
  display: flex;
  flex-wrap: nowrap;
  flex: 1 1 auto;
  justify-content: center;
  left: 50%;
  margin-top: -$kip-navbar__height;
  max-width: 33vw;
  min-height: 3rem;
  position: sticky;
  transform: translateX(-50%);
  transition: all 0.15s ease-in;
  z-index: 10;
}

.kip-utility-bar {
  &__holder {
    transition: width 0.25s, display 0.25s;
    background-color: white;
    border-left: 1px solid $border-color;
    box-shadow: $box-shadow-left;

    // width: 15rem;
    &--collapsed {
      width: 2rem;
    }

    &--expanded {
      width: 15rem;
    }
  }

  &__content {
    display: none;
    position: sticky;
    top: 0;
    margin: -1em 2rem 0;
    min-width: 11rem;

    &--expanded {
      display: block;
    }
  }

  &__toggle-button {
    position: sticky;
    top: calc(50% - 0.25rem);
    width: 0;
    height: 0;
    padding: 0.5rem;
    margin-top: -1px;
  }

  &__header {
    @include make-overline-style($raven);

    display: flex;
    align-items: baseline;
    justify-content: space-between;
    letter-spacing: 0.25em;
    border-bottom: 1px solid $border-color;
    padding: 2em 0 1em;
    margin-bottom: 1em;
  }

  &__subhead {
    @include make-overline-style($raven);

    font-weight: 400;
    padding: 1em 0;
    letter-spacing: 0.25em;
  }

  &__option {
    background-color: $interaction-color;
    width: 2.75em;
    height: 2.75em;
    border-radius: 2.75em;
    color: $white;
    text-align: center;
    user-select: none;
    cursor: pointer;

    &:hover {
      background-color: color.adjust($interaction-color, $lightness: -10%);
    }
  }

  &__more-link {
    cursor: pointer;
    color: $interaction-color;
    text-align: center;
    padding: 0.5em 0;
    user-select: none;
  }
}
