@use "sass:math";

.kip-bundle-wrapper {
  $gutter: 1rem;
  $row-gap: 0.5rem;
  $qtr: math.div(100%, 4);
  $third: math.div(100%, 3);
  $half: math.div(100%, 2);

  display: block;

  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: calc(#{$gutter} * -1);
  }

  &__bundle {
    margin: 0 0 $row-gap;
    width: 100%;

    &:last-of-type {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      @include make-detail-column($half);

      padding: $row-gap $gutter;
      margin: 0;
    }

    @include media-breakpoint-up(xl) {
      @include make-detail-column($third);

      padding: $row-gap $gutter;
      margin: 0;
    }

    @include media-breakpoint-up(xxl) {
      @include make-detail-column($qtr);

      padding: $row-gap $gutter;
      margin: 0;
    }
  }
}

.kip-account-wrapper {
  $gutter: 1rem;
  $row-gap: 0.5rem;
  $qtr: math.div(100%, 4);
  $third: math.div(100%, 3);
  $half: math.div(100%, 2);

  display: block;

  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: calc(#{$gutter} * -1);
  }

  &__account {
    margin: 0 0 $row-gap;
    width: 100%;

    &:last-of-type {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      @include make-detail-column($half);

      padding: $row-gap $gutter;
      margin: 0;
    }

    @include media-breakpoint-up(xl) {
      @include make-detail-column($third);

      padding: $row-gap $gutter;
      margin: 0;
    }

    @include media-breakpoint-up(xxl) {
      @include make-detail-column($qtr);

      padding: $row-gap $gutter;
      margin: 0;
    }
  }
}
