@use "sass:color";

.kip-stripe {
  @extend .form-control;

  height: 3rem;
  padding-top: 0.75rem;
}

.StripeElement {
  height: 100%;
}

.StripeElement--invalid {
  border-color: color.adjust($danger, $lightness: 10%);

  &:focus {
    box-shadow: $box-shadow-danger;
  }
}
