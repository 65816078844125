$header__background-image: "~/assets/svg/header.svg";

.kip-header {
  background-clip: border-box;
  background-color: $card-bg;
  background-position: right;
  background-repeat: no-repeat;
  border-bottom: 1px solid rgb(0 0 0 / 12.5%);
  box-shadow: $box-shadow-sm;
  color: $card-color;
  display: flex;
  font-size: $root__type--small;
  flex: 1 1 auto;
  flex-direction: column;
  height: $kip-header__height;
  justify-content: space-between;
  max-height: $kip-header__height;
  min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
  padding: $kip-header__padding;
  position: relative;
  word-wrap: break-word;

  &--no-breadcrumbs {
    justify-content: center;
  }

  @include media-breakpoint-up(sm) {
    background-image: url($header__background-image);
  }
}
