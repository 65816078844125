@charset "UTF-8";
@import "~katex/dist/katex.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "../../../node_modules/@ctrl/ngx-emoji-mart/picker.css";
:root {
  --bs-dark-rgb: 50, 50, 50;
}

:root {
  --bs-dark-rgb: 50, 50, 50;
}

/*!
 * Bootstrap  v5.3.2 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --bs-blue: #00549f;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #9c9c9c;
  --bs-gray-dark: #707070;
  --bs-gray-100: #f6f6f6;
  --bs-gray-200: #f1f1f1;
  --bs-gray-300: #e8e8e8;
  --bs-gray-400: #dfdfdf;
  --bs-gray-500: #bebebe;
  --bs-gray-600: #9c9c9c;
  --bs-gray-700: #797979;
  --bs-gray-800: #707070;
  --bs-gray-900: #424242;
  --bs-primary: #00549f;
  --bs-primary-medium: #257bb9;
  --bs-secondary: #505d68;
  --bs-success: #177060;
  --bs-info: #2f7683;
  --bs-warning: #9e4700;
  --bs-danger: #a03535;
  --bs-light: #f6f6f6;
  --bs-muted: #9c9c9c;
  --bs-dark: #424242;
  --bs-primary-rgb: 0, 84, 159;
  --bs-primary-medium-rgb: 37, 123, 185;
  --bs-secondary-rgb: 80, 93, 104;
  --bs-success-rgb: 23, 112, 96;
  --bs-info-rgb: 47, 118, 131;
  --bs-warning-rgb: 158, 71, 0;
  --bs-danger-rgb: 160, 53, 53;
  --bs-light-rgb: 246, 246, 246;
  --bs-muted-rgb: 156, 156, 156;
  --bs-dark-rgb: 66, 66, 66;
  --bs-primary-text-emphasis: rgb(0, 33.6, 63.6);
  --bs-secondary-text-emphasis: rgb(32, 37.2, 41.6);
  --bs-success-text-emphasis: rgb(9.2, 44.8, 38.4);
  --bs-info-text-emphasis: rgb(5.2, 80.8, 96);
  --bs-warning-text-emphasis: rgb(63.2, 28.4, 0);
  --bs-danger-text-emphasis: rgb(64, 21.2, 21.2);
  --bs-light-text-emphasis: #797979;
  --bs-dark-text-emphasis: #797979;
  --bs-primary-bg-subtle: rgb(204, 220.8, 235.8);
  --bs-secondary-bg-subtle: rgb(220, 222.6, 224.8);
  --bs-success-bg-subtle: rgb(208.6, 226.4, 223.2);
  --bs-info-bg-subtle: rgb(206.6, 244.4, 252);
  --bs-warning-bg-subtle: rgb(235.6, 218.2, 204);
  --bs-danger-bg-subtle: rgb(236, 214.6, 214.6);
  --bs-light-bg-subtle: rgb(250.5, 250.5, 250.5);
  --bs-dark-bg-subtle: #dfdfdf;
  --bs-primary-border-subtle: rgb(153, 186.6, 216.6);
  --bs-secondary-border-subtle: rgb(185, 190.2, 194.6);
  --bs-success-border-subtle: rgb(162.2, 197.8, 191.4);
  --bs-info-border-subtle: rgb(158.2, 233.8, 249);
  --bs-warning-border-subtle: rgb(216.2, 181.4, 153);
  --bs-danger-border-subtle: rgb(217, 174.2, 174.2);
  --bs-light-border-subtle: #f1f1f1;
  --bs-dark-border-subtle: #bebebe;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: "Open Sans", -apple-system, blinkmacsystemfont, "Segoe UI", "Roboto", "Helvetica Neue", arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #505d68;
  --bs-body-color-rgb: 80, 93, 104;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(80, 93, 104, 0.75);
  --bs-secondary-color-rgb: 80, 93, 104;
  --bs-secondary-bg: #f1f1f1;
  --bs-secondary-bg-rgb: 241, 241, 241;
  --bs-tertiary-color: rgba(80, 93, 104, 0.5);
  --bs-tertiary-color-rgb: 80, 93, 104;
  --bs-tertiary-bg: #f6f6f6;
  --bs-tertiary-bg-rgb: 246, 246, 246;
  --bs-heading-color: inherit;
  --bs-link-color: #00549f;
  --bs-link-color-rgb: 0, 84, 159;
  --bs-link-decoration: underline;
  --bs-link-hover-color: rgb(0, 67.2, 127.2);
  --bs-link-hover-color-rgb: 0, 67, 127;
  --bs-code-color: #d63384;
  --bs-highlight-color: #505d68;
  --bs-highlight-bg: rgb(255, 242.6, 205.4);
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #e7e7e7;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
  --bs-box-shadow-lg: 2px 4px 8px rgba(212, 212, 212, 0.25);
  --bs-box-shadow-inset: inset 0 0 0 0.2rem rgba(0, 84, 159, 0.25);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(0, 84, 159, 0.25);
  --bs-form-valid-color: #177060;
  --bs-form-valid-border-color: #177060;
  --bs-form-invalid-color: #a03535;
  --bs-form-invalid-border-color: #a03535;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #e8e8e8;
  --bs-body-color-rgb: 232, 232, 232;
  --bs-body-bg: #424242;
  --bs-body-bg-rgb: 66, 66, 66;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(232, 232, 232, 0.75);
  --bs-secondary-color-rgb: 232, 232, 232;
  --bs-secondary-bg: #707070;
  --bs-secondary-bg-rgb: 112, 112, 112;
  --bs-tertiary-color: rgba(232, 232, 232, 0.5);
  --bs-tertiary-color-rgb: 232, 232, 232;
  --bs-tertiary-bg: #595959;
  --bs-tertiary-bg-rgb: 89, 89, 89;
  --bs-primary-text-emphasis: rgb(102, 152.4, 197.4);
  --bs-secondary-text-emphasis: rgb(150, 157.8, 164.4);
  --bs-success-text-emphasis: rgb(115.8, 169.2, 159.6);
  --bs-info-text-emphasis: rgb(109.8, 223.2, 246);
  --bs-warning-text-emphasis: rgb(196.8, 144.6, 102);
  --bs-danger-text-emphasis: rgb(198, 133.8, 133.8);
  --bs-light-text-emphasis: #f6f6f6;
  --bs-dark-text-emphasis: #e8e8e8;
  --bs-primary-bg-subtle: rgb(0, 16.8, 31.8);
  --bs-secondary-bg-subtle: rgb(16, 18.6, 20.8);
  --bs-success-bg-subtle: rgb(4.6, 22.4, 19.2);
  --bs-info-bg-subtle: rgb(2.6, 40.4, 48);
  --bs-warning-bg-subtle: rgb(31.6, 14.2, 0);
  --bs-danger-bg-subtle: rgb(32, 10.6, 10.6);
  --bs-light-bg-subtle: #707070;
  --bs-dark-bg-subtle: #383838;
  --bs-primary-border-subtle: rgb(0, 50.4, 95.4);
  --bs-secondary-border-subtle: rgb(48, 55.8, 62.4);
  --bs-success-border-subtle: rgb(13.8, 67.2, 57.6);
  --bs-info-border-subtle: rgb(7.8, 121.2, 144);
  --bs-warning-border-subtle: rgb(94.8, 42.6, 0);
  --bs-danger-border-subtle: rgb(96, 31.8, 31.8);
  --bs-light-border-subtle: #797979;
  --bs-dark-border-subtle: #707070;
  --bs-heading-color: inherit;
  --bs-link-color: rgb(102, 152.4, 197.4);
  --bs-link-hover-color: rgb(132.6, 172.92, 208.92);
  --bs-link-color-rgb: 102, 152, 197;
  --bs-link-hover-color-rgb: 133, 173, 209;
  --bs-code-color: rgb(230.4, 132.6, 181.2);
  --bs-highlight-color: #e8e8e8;
  --bs-highlight-bg: rgb(102, 77.2, 2.8);
  --bs-border-color: #797979;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: rgb(117, 183, 152.4);
  --bs-form-valid-border-color: rgb(117, 183, 152.4);
  --bs-form-invalid-color: rgb(234, 133.8, 143.4);
  --bs-form-invalid-border-color: rgb(234, 133.8, 143.4);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.5;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.2674rem + 0.2088vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 1.424rem;
  }
}

h2, .h2 {
  font-size: calc(1.2516rem + 0.0192vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.266rem;
  }
}

h3, .h3 {
  font-size: 1.125rem;
}

h4, .h4 {
  font-size: 1rem;
}

h5, .h5 {
  font-size: 0.9rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.5;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.5;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.5;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.5;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.5;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.5;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #9c9c9c;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1600px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1400px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1600px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 3.25rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 3.25rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 3.5rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 3.5rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 3.75rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 3.75rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 4rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 4rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 4.25rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 4.25rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 3.25rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 3.25rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 3.5rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 3.5rem;
  }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 3.75rem;
  }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 3.75rem;
  }
  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 4rem;
  }
  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 4rem;
  }
  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 4.25rem;
  }
  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 4.25rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 3.25rem;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 3.25rem;
  }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 3.5rem;
  }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 3.5rem;
  }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 3.75rem;
  }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 3.75rem;
  }
  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 4rem;
  }
  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 4rem;
  }
  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 4.25rem;
  }
  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 4.25rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 3.25rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 3.25rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 3.5rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 3.5rem;
  }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 3.75rem;
  }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 3.75rem;
  }
  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 4rem;
  }
  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 4rem;
  }
  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 4.25rem;
  }
  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 4.25rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 3.25rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 3.25rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 3.5rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 3.5rem;
  }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 3.75rem;
  }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 3.75rem;
  }
  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 4rem;
  }
  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 4rem;
  }
  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 4.25rem;
  }
  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 4.25rem;
  }
}
@media (min-width: 1600px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 3.25rem;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 3.25rem;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 3.5rem;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 3.5rem;
  }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 3.75rem;
  }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 3.75rem;
  }
  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 4rem;
  }
  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 4rem;
  }
  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 4.25rem;
  }
  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 4.25rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: rgb(204, 220.8, 235.8);
  --bs-table-border-color: rgb(163.2, 176.64, 188.64);
  --bs-table-striped-bg: rgb(193.8, 209.76, 224.01);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(183.6, 198.72, 212.22);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(188.7, 204.24, 218.115);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: rgb(220, 222.6, 224.8);
  --bs-table-border-color: rgb(176, 178.08, 179.84);
  --bs-table-striped-bg: rgb(209, 211.47, 213.56);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(198, 200.34, 202.32);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(203.5, 205.905, 207.94);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: rgb(208.6, 226.4, 223.2);
  --bs-table-border-color: rgb(166.88, 181.12, 178.56);
  --bs-table-striped-bg: rgb(198.17, 215.08, 212.04);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(187.74, 203.76, 200.88);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(192.955, 209.42, 206.46);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: rgb(206.6, 244.4, 252);
  --bs-table-border-color: rgb(165.28, 195.52, 201.6);
  --bs-table-striped-bg: rgb(196.27, 232.18, 239.4);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(185.94, 219.96, 226.8);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(191.105, 226.07, 233.1);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: rgb(235.6, 218.2, 204);
  --bs-table-border-color: rgb(188.48, 174.56, 163.2);
  --bs-table-striped-bg: rgb(223.82, 207.29, 193.8);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(212.04, 196.38, 183.6);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(217.93, 201.835, 188.7);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: rgb(236, 214.6, 214.6);
  --bs-table-border-color: rgb(188.8, 171.68, 171.68);
  --bs-table-striped-bg: rgb(224.2, 203.87, 203.87);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(212.4, 193.14, 193.14);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(218.3, 198.505, 198.505);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f6f6f6;
  --bs-table-border-color: rgb(196.8, 196.8, 196.8);
  --bs-table-striped-bg: rgb(233.7, 233.7, 233.7);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(221.4, 221.4, 221.4);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(227.55, 227.55, 227.55);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #424242;
  --bs-table-border-color: rgb(103.8, 103.8, 103.8);
  --bs-table-striped-bg: rgb(75.45, 75.45, 75.45);
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: rgb(84.9, 84.9, 84.9);
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: rgb(80.175, 80.175, 80.175);
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1599.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control, .kip-stripe {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control, .kip-stripe {
    transition: none;
  }
}
.form-control[type=file], [type=file].kip-stripe {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]), [type=file].kip-stripe:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus, .kip-stripe:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: rgb(127.5, 169.5, 207);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 84, 159, 0.25);
}
.form-control::-webkit-date-and-time-value, .kip-stripe::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit, .kip-stripe::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder, .kip-stripe::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control:disabled, .kip-stripe:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button, .kip-stripe::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button, .kip-stripe::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button, .kip-stripe:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control, textarea.kip-stripe {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23707070' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: rgb(127.5, 169.5, 207);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 84, 159, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23e8e8e8' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: rgb(127.5, 169.5, 207);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 84, 159, 0.25);
}
.form-check-input:checked {
  background-color: #00549f;
  border-color: #00549f;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #00549f;
  border-color: #00549f;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgb%28127.5, 169.5, 207%29'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check[disabled] + .kip-paginator__button, .btn-check[disabled] + .kip-footer__button, .btn-check:disabled + .btn, .btn-check:disabled + .kip-paginator__button, .btn-check:disabled + .kip-footer__button {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(0, 84, 159, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(0, 84, 159, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #00549f;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: rgb(178.5, 203.7, 226.2);
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #00549f;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: rgb(178.5, 203.7, 226.2);
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control, .form-floating > .kip-stripe,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control, .form-floating > .kip-stripe,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder, .form-floating > .kip-stripe::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .kip-stripe:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .kip-stripe:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill, .form-floating > .kip-stripe:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label, .form-floating > .kip-stripe:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .kip-stripe:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after, .form-floating > .kip-stripe:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .kip-stripe:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label, .form-floating > .kip-stripe:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #9c9c9c;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control, .input-group > .kip-stripe,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus, .input-group > .kip-stripe:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn, .input-group .kip-paginator__button, .input-group .kip-footer__button {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus, .input-group .kip-paginator__button:focus, .input-group .kip-footer__button:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.input-group-lg > .form-control, .input-group-lg > .kip-stripe,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn,
.input-group-lg > .kip-paginator__button,
.input-group-lg > .kip-footer__button {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm > .form-control, .input-group-sm > .kip-stripe,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn,
.input-group-sm > .kip-paginator__button,
.input-group-sm > .kip-footer__button {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .kip-stripe,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .kip-stripe,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control, .input-group > .form-floating:not(:first-child) > .kip-stripe,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .was-validated .kip-stripe:valid, .form-control.is-valid, .is-valid.kip-stripe {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23177060' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .was-validated .kip-stripe:valid:focus, .form-control.is-valid:focus, .is-valid.kip-stripe:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, .was-validated textarea.kip-stripe:valid, textarea.form-control.is-valid, textarea.is-valid.kip-stripe {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23177060' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .was-validated .input-group > .kip-stripe:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .input-group > .kip-stripe:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .was-validated .kip-stripe:invalid, .form-control.is-invalid, .is-invalid.kip-stripe {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23a03535'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23a03535' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .was-validated .kip-stripe:invalid:focus, .form-control.is-invalid:focus, .is-invalid.kip-stripe:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, .was-validated textarea.kip-stripe:invalid, textarea.form-control.is-invalid, textarea.is-invalid.kip-stripe {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23a03535'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23a03535' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .was-validated .input-group > .kip-stripe:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .input-group > .kip-stripe:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn, .kip-paginator__button, .kip-footer__button {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn, .kip-paginator__button, .kip-footer__button {
    transition: none;
  }
}
.btn:hover, .kip-paginator__button:hover, .kip-footer__button:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover, .btn-check + .kip-paginator__button:hover, .btn-check + .kip-footer__button:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible, .kip-paginator__button:focus-visible, .kip-footer__button:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn, .btn-check:focus-visible + .kip-paginator__button, .btn-check:focus-visible + .kip-footer__button {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, .btn-check:checked + .kip-paginator__button, .btn-check:checked + .kip-footer__button, :not(.btn-check) + .btn:active, :not(.btn-check) + .kip-paginator__button:active, :not(.btn-check) + .kip-footer__button:active, .btn:first-child:active, .kip-paginator__button:first-child:active, .kip-footer__button:first-child:active, .btn.active, .active.kip-paginator__button, .active.kip-footer__button, .btn.show, .show.kip-paginator__button, .show.kip-footer__button {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, .btn-check:checked + .kip-paginator__button:focus-visible, .btn-check:checked + .kip-footer__button:focus-visible, :not(.btn-check) + .btn:active:focus-visible, :not(.btn-check) + .kip-paginator__button:active:focus-visible, :not(.btn-check) + .kip-footer__button:active:focus-visible, .btn:first-child:active:focus-visible, .kip-paginator__button:first-child:active:focus-visible, .kip-footer__button:first-child:active:focus-visible, .btn.active:focus-visible, .active.kip-paginator__button:focus-visible, .active.kip-footer__button:focus-visible, .btn.show:focus-visible, .show.kip-paginator__button:focus-visible, .show.kip-footer__button:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .kip-paginator__button:disabled, .kip-footer__button:disabled, .btn.disabled, .disabled.kip-paginator__button, .disabled.kip-footer__button, fieldset:disabled .btn, fieldset:disabled .kip-paginator__button, fieldset:disabled .kip-footer__button {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #00549f;
  --bs-btn-border-color: #00549f;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(0, 71.4, 135.15);
  --bs-btn-hover-border-color: rgb(0, 67.2, 127.2);
  --bs-btn-focus-shadow-rgb: 38, 110, 173;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(0, 67.2, 127.2);
  --bs-btn-active-border-color: rgb(0, 63, 119.25);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #00549f;
  --bs-btn-disabled-border-color: #00549f;
}

.btn-primary-medium {
  --bs-btn-color: #fff;
  --bs-btn-bg: #257bb9;
  --bs-btn-border-color: #257bb9;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(31.45, 104.55, 157.25);
  --bs-btn-hover-border-color: rgb(29.6, 98.4, 148);
  --bs-btn-focus-shadow-rgb: 70, 143, 196;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(29.6, 98.4, 148);
  --bs-btn-active-border-color: rgb(27.75, 92.25, 138.75);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #257bb9;
  --bs-btn-disabled-border-color: #257bb9;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #505d68;
  --bs-btn-border-color: #505d68;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(68, 79.05, 88.4);
  --bs-btn-hover-border-color: rgb(64, 74.4, 83.2);
  --bs-btn-focus-shadow-rgb: 106, 117, 127;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(64, 74.4, 83.2);
  --bs-btn-active-border-color: rgb(60, 69.75, 78);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #505d68;
  --bs-btn-disabled-border-color: #505d68;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #177060;
  --bs-btn-border-color: #177060;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(19.55, 95.2, 81.6);
  --bs-btn-hover-border-color: rgb(18.4, 89.6, 76.8);
  --bs-btn-focus-shadow-rgb: 58, 133, 120;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(18.4, 89.6, 76.8);
  --bs-btn-active-border-color: rgb(17.25, 84, 72);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #177060;
  --bs-btn-disabled-border-color: #177060;
}

.btn-info {
  --bs-btn-color: #fff;
  --bs-btn-bg: #2f7683;
  --bs-btn-border-color: #2f7683;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(39.95, 100.3, 111.35);
  --bs-btn-hover-border-color: rgb(37.6, 94.4, 104.8);
  --bs-btn-focus-shadow-rgb: 78, 139, 150;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(37.6, 94.4, 104.8);
  --bs-btn-active-border-color: rgb(35.25, 88.5, 98.25);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #2f7683;
  --bs-btn-disabled-border-color: #2f7683;
}

.btn-warning {
  --bs-btn-color: #fff;
  --bs-btn-bg: #9e4700;
  --bs-btn-border-color: #9e4700;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(134.3, 60.35, 0);
  --bs-btn-hover-border-color: rgb(126.4, 56.8, 0);
  --bs-btn-focus-shadow-rgb: 173, 99, 38;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(126.4, 56.8, 0);
  --bs-btn-active-border-color: rgb(118.5, 53.25, 0);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #9e4700;
  --bs-btn-disabled-border-color: #9e4700;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #a03535;
  --bs-btn-border-color: #a03535;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(136, 45.05, 45.05);
  --bs-btn-hover-border-color: rgb(128, 42.4, 42.4);
  --bs-btn-focus-shadow-rgb: 174, 83, 83;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(128, 42.4, 42.4);
  --bs-btn-active-border-color: rgb(120, 39.75, 39.75);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #a03535;
  --bs-btn-disabled-border-color: #a03535;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f6f6f6;
  --bs-btn-border-color: #f6f6f6;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgb(209.1, 209.1, 209.1);
  --bs-btn-hover-border-color: rgb(196.8, 196.8, 196.8);
  --bs-btn-focus-shadow-rgb: 209, 209, 209;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgb(196.8, 196.8, 196.8);
  --bs-btn-active-border-color: rgb(184.5, 184.5, 184.5);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f6f6f6;
  --bs-btn-disabled-border-color: #f6f6f6;
}

.btn-muted {
  --bs-btn-color: #000;
  --bs-btn-bg: #9c9c9c;
  --bs-btn-border-color: #9c9c9c;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgb(170.85, 170.85, 170.85);
  --bs-btn-hover-border-color: rgb(165.9, 165.9, 165.9);
  --bs-btn-focus-shadow-rgb: 133, 133, 133;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgb(175.8, 175.8, 175.8);
  --bs-btn-active-border-color: rgb(165.9, 165.9, 165.9);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #9c9c9c;
  --bs-btn-disabled-border-color: #9c9c9c;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #424242;
  --bs-btn-border-color: #424242;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(94.35, 94.35, 94.35);
  --bs-btn-hover-border-color: rgb(84.9, 84.9, 84.9);
  --bs-btn-focus-shadow-rgb: 94, 94, 94;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(103.8, 103.8, 103.8);
  --bs-btn-active-border-color: rgb(84.9, 84.9, 84.9);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #424242;
  --bs-btn-disabled-border-color: #424242;
}

.btn-outline-primary {
  --bs-btn-color: #00549f;
  --bs-btn-border-color: #00549f;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #00549f;
  --bs-btn-hover-border-color: #00549f;
  --bs-btn-focus-shadow-rgb: 0, 84, 159;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #00549f;
  --bs-btn-active-border-color: #00549f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #00549f;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #00549f;
  --bs-gradient: none;
}

.btn-outline-primary-medium {
  --bs-btn-color: #257bb9;
  --bs-btn-border-color: #257bb9;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #257bb9;
  --bs-btn-hover-border-color: #257bb9;
  --bs-btn-focus-shadow-rgb: 37, 123, 185;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #257bb9;
  --bs-btn-active-border-color: #257bb9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #257bb9;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #257bb9;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #505d68;
  --bs-btn-border-color: #505d68;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #505d68;
  --bs-btn-hover-border-color: #505d68;
  --bs-btn-focus-shadow-rgb: 80, 93, 104;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #505d68;
  --bs-btn-active-border-color: #505d68;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #505d68;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #505d68;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #177060;
  --bs-btn-border-color: #177060;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #177060;
  --bs-btn-hover-border-color: #177060;
  --bs-btn-focus-shadow-rgb: 23, 112, 96;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #177060;
  --bs-btn-active-border-color: #177060;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #177060;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #177060;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #2f7683;
  --bs-btn-border-color: #2f7683;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2f7683;
  --bs-btn-hover-border-color: #2f7683;
  --bs-btn-focus-shadow-rgb: 47, 118, 131;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2f7683;
  --bs-btn-active-border-color: #2f7683;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2f7683;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2f7683;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #9e4700;
  --bs-btn-border-color: #9e4700;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #9e4700;
  --bs-btn-hover-border-color: #9e4700;
  --bs-btn-focus-shadow-rgb: 158, 71, 0;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #9e4700;
  --bs-btn-active-border-color: #9e4700;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #9e4700;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #9e4700;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #a03535;
  --bs-btn-border-color: #a03535;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #a03535;
  --bs-btn-hover-border-color: #a03535;
  --bs-btn-focus-shadow-rgb: 160, 53, 53;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #a03535;
  --bs-btn-active-border-color: #a03535;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #a03535;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #a03535;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f6f6f6;
  --bs-btn-border-color: #f6f6f6;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f6f6f6;
  --bs-btn-hover-border-color: #f6f6f6;
  --bs-btn-focus-shadow-rgb: 246, 246, 246;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f6f6f6;
  --bs-btn-active-border-color: #f6f6f6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f6f6f6;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f6f6f6;
  --bs-gradient: none;
}

.btn-outline-muted {
  --bs-btn-color: #9c9c9c;
  --bs-btn-border-color: #9c9c9c;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #9c9c9c;
  --bs-btn-hover-border-color: #9c9c9c;
  --bs-btn-focus-shadow-rgb: 156, 156, 156;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #9c9c9c;
  --bs-btn-active-border-color: #9c9c9c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #9c9c9c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #9c9c9c;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #424242;
  --bs-btn-border-color: #424242;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424242;
  --bs-btn-hover-border-color: #424242;
  --bs-btn-focus-shadow-rgb: 66, 66, 66;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #424242;
  --bs-btn-active-border-color: #424242;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #424242;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #424242;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #9c9c9c;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 38, 110, 173;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn, .btn-group-lg > .kip-paginator__button, .btn-group-lg > .kip-footer__button {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1.728rem;
  --bs-btn-font-size: 1rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn, .btn-group-sm > .kip-paginator__button, .btn-group-sm > .kip-footer__button {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #00549f;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #9c9c9c;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1600px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #e8e8e8;
  --bs-dropdown-bg: #707070;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #e8e8e8;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #00549f;
  --bs-dropdown-link-disabled-color: #bebebe;
  --bs-dropdown-header-color: #bebebe;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn, .btn-group > .kip-paginator__button, .btn-group > .kip-footer__button,
.btn-group-vertical > .btn,
.btn-group-vertical > .kip-paginator__button,
.btn-group-vertical > .kip-footer__button {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn, .btn-group > .btn-check:checked + .kip-paginator__button, .btn-group > .btn-check:checked + .kip-footer__button,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn-check:focus + .kip-paginator__button,
.btn-group > .btn-check:focus + .kip-footer__button,
.btn-group > .btn:hover,
.btn-group > .kip-paginator__button:hover,
.btn-group > .kip-footer__button:hover,
.btn-group > .btn:focus,
.btn-group > .kip-paginator__button:focus,
.btn-group > .kip-footer__button:focus,
.btn-group > .btn:active,
.btn-group > .kip-paginator__button:active,
.btn-group > .kip-footer__button:active,
.btn-group > .btn.active,
.btn-group > .active.kip-paginator__button,
.btn-group > .active.kip-footer__button,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:checked + .kip-paginator__button,
.btn-group-vertical > .btn-check:checked + .kip-footer__button,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn-check:focus + .kip-paginator__button,
.btn-group-vertical > .btn-check:focus + .kip-footer__button,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .kip-paginator__button:hover,
.btn-group-vertical > .kip-footer__button:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .kip-paginator__button:focus,
.btn-group-vertical > .kip-footer__button:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .kip-paginator__button:active,
.btn-group-vertical > .kip-footer__button:active,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .active.kip-paginator__button,
.btn-group-vertical > .active.kip-footer__button {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}
.btn-group > :not(.btn-check:first-child) + .btn, .btn-group > :not(.btn-check:first-child) + .kip-paginator__button, .btn-group > :not(.btn-check:first-child) + .kip-footer__button,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .kip-paginator__button:not(:last-child):not(.dropdown-toggle), .btn-group > .kip-footer__button:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .dropdown-toggle-split.kip-paginator__button:first-child,
.btn-group > .dropdown-toggle-split.kip-footer__button:first-child,
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn-group:not(:last-child) > .kip-paginator__button,
.btn-group > .btn-group:not(:last-child) > .kip-footer__button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3), .btn-group > .kip-paginator__button:nth-child(n+3), .btn-group > .kip-footer__button:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > :not(.btn-check) + .kip-paginator__button,
.btn-group > :not(.btn-check) + .kip-footer__button,
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn-group:not(:first-child) > .kip-paginator__button,
.btn-group > .btn-group:not(:first-child) > .kip-footer__button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .btn-group-sm > .kip-paginator__button + .dropdown-toggle-split, .btn-group-sm > .kip-footer__button + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .btn-group-lg > .kip-paginator__button + .dropdown-toggle-split, .btn-group-lg > .kip-footer__button + .dropdown-toggle-split {
  padding-right: 1.296rem;
  padding-left: 1.296rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn, .btn-group-vertical > .kip-paginator__button, .btn-group-vertical > .kip-footer__button,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .kip-paginator__button:not(:first-child), .btn-group-vertical > .kip-footer__button:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .kip-paginator__button:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .kip-footer__button:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn-group:not(:last-child) > .kip-paginator__button,
.btn-group-vertical > .btn-group:not(:last-child) > .kip-footer__button {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn, .btn-group-vertical > .kip-paginator__button ~ .btn, .btn-group-vertical > .btn ~ .kip-paginator__button, .btn-group-vertical > .kip-paginator__button ~ .kip-paginator__button, .btn-group-vertical > .kip-footer__button ~ .btn, .btn-group-vertical > .kip-footer__button ~ .kip-paginator__button, .btn-group-vertical > .btn ~ .kip-footer__button, .btn-group-vertical > .kip-paginator__button ~ .kip-footer__button, .btn-group-vertical > .kip-footer__button ~ .kip-footer__button,
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .kip-paginator__button,
.btn-group-vertical > .btn-group:not(:first-child) > .kip-footer__button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 84, 159, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #00549f;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2880, 93, 104, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1600px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card, .kip-card, .kip-card-link {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr, .kip-card > hr, .kip-card-link > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group, .kip-card > .list-group, .kip-card-link > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child, .kip-card > .list-group:first-child, .kip-card-link > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child, .kip-card > .list-group:last-child, .kip-card-link > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group, .kip-card > .card-header + .list-group, .kip-card-link > .card-header + .list-group,
.card > .list-group + .card-footer,
.kip-card > .list-group + .card-footer,
.kip-card-link > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card, .card-group > .kip-card, .card-group > .kip-card-link {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card, .card-group > .kip-card, .card-group > .kip-card-link {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card, .card-group > .kip-card + .card, .card-group > .card + .kip-card, .card-group > .kip-card + .kip-card, .card-group > .kip-card-link + .card, .card-group > .kip-card-link + .kip-card, .card-group > .card + .kip-card-link, .card-group > .kip-card + .kip-card-link, .card-group > .kip-card-link + .kip-card-link {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child), .card-group > .kip-card:not(:last-child), .card-group > .kip-card-link:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top, .card-group > .kip-card:not(:last-child) .card-img-top, .card-group > .kip-card-link:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header,
  .card-group > .kip-card:not(:last-child) .card-header,
  .card-group > .kip-card-link:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .kip-card:not(:last-child) .card-img-bottom, .card-group > .kip-card-link:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer,
  .card-group > .kip-card:not(:last-child) .card-footer,
  .card-group > .kip-card-link:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child), .card-group > .kip-card:not(:first-child), .card-group > .kip-card-link:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top, .card-group > .kip-card:not(:first-child) .card-img-top, .card-group > .kip-card-link:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header,
  .card-group > .kip-card:not(:first-child) .card-header,
  .card-group > .kip-card-link:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .kip-card:not(:first-child) .card-img-bottom, .card-group > .kip-card-link:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer,
  .card-group > .kip-card:not(:first-child) .card-footer,
  .card-group > .kip-card-link:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23505d68'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgb%280, 33.6, 63.6%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: rgb(127.5, 169.5, 207);
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(0, 84, 159, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgb%28102, 152.4, 197.4%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgb%28102, 152.4, 197.4%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(0, 84, 159, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #00549f;
  --bs-pagination-active-border-color: #00549f;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge, .kip-badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty, .kip-badge:empty {
  display: none;
}

.btn .badge, .kip-paginator__button .badge, .kip-footer__button .badge, .btn .kip-badge, .kip-paginator__button .kip-badge, .kip-footer__button .kip-badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary, .kip-whiteboard__pdf-buttons .kip-items .kip-item .kip-item-inner.kip-attempts-0:hover, .kip-question-navigator .kip-items .kip-item .kip-item-inner.kip-attempts-0:hover {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-primary-medium {
  --bs-alert-color: var(--bs-primary-medium-text-emphasis);
  --bs-alert-bg: var(--bs-primary-medium-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-medium-border-subtle);
  --bs-alert-link-color: var(--bs-primary-medium-text-emphasis);
}

.alert-secondary, .kip-whiteboard__pdf-buttons .kip-items .kip-item .kip-item-inner.kip-visited.kip-attempts-0, .kip-question-navigator .kip-items .kip-item .kip-item-inner.kip-visited.kip-attempts-0 {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success, .kip-whiteboard__pdf-buttons .kip-items .kip-item .kip-item-inner.kip-correct.kip-attempts-1, .kip-question-navigator .kip-items .kip-item .kip-item-inner.kip-correct.kip-attempts-1, .kip-question .kip-layout .kip-matching-layout .kip-container .kip-column .kip-option.kip-correct {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning, .kip-whiteboard__pdf-buttons .kip-items .kip-item .kip-item-inner.kip-correct.kip-attempts-2, .kip-question-navigator .kip-items .kip-item .kip-item-inner.kip-correct.kip-attempts-2 {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger, .kip-question .kip-layout .kip-matching-layout .kip-container .kip-column .kip-option.kip-incorrect {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-muted {
  --bs-alert-color: var(--bs-muted-text-emphasis);
  --bs-alert-bg: var(--bs-muted-bg-subtle);
  --bs-alert-border-color: var(--bs-muted-border-subtle);
  --bs-alert-link-color: var(--bs-muted-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #00549f;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #00549f;
  --bs-list-group-active-border-color: #00549f;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1600px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-primary-medium {
  --bs-list-group-color: var(--bs-primary-medium-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-medium-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-medium-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-medium-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-medium-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-medium-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-medium-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-medium-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-muted {
  --bs-list-group-color: var(--bs-muted-text-emphasis);
  --bs-list-group-bg: var(--bs-muted-bg-subtle);
  --bs-list-group-border-color: var(--bs-muted-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-muted-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-muted-border-subtle);
  --bs-list-group-active-color: var(--bs-muted-bg-subtle);
  --bs-list-group-active-bg: var(--bs-muted-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-muted-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(0, 84, 159, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1599.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1599.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1599.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1599.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1600px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before, .placeholder.kip-paginator__button::before, .placeholder.kip-footer__button::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-primary-medium {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-medium-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #fff !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-muted {
  color: #000 !important;
  background-color: RGBA(var(--bs-muted-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(0, 67, 127, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 67, 127, var(--bs-link-underline-opacity, 1)) !important;
}

.link-primary-medium {
  color: RGBA(var(--bs-primary-medium-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-medium-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary-medium:hover, .link-primary-medium:focus {
  color: RGBA(30, 98, 148, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(30, 98, 148, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(64, 74, 83, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(64, 74, 83, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(18, 90, 77, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(18, 90, 77, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(38, 94, 105, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(38, 94, 105, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(126, 57, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(126, 57, 0, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(128, 42, 42, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(128, 42, 42, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(248, 248, 248, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(248, 248, 248, var(--bs-link-underline-opacity, 1)) !important;
}

.link-muted {
  color: RGBA(var(--bs-muted-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-muted-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-muted:hover, .link-muted:focus {
  color: RGBA(176, 176, 176, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(176, 176, 176, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(53, 53, 53, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(53, 53, 53, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1600px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-primary-medium {
  --bs-focus-ring-color: rgba(var(--bs-primary-medium-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-muted {
  --bs-focus-ring-color: rgba(var(--bs-muted-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-medium {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-medium-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-muted {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-muted-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-6 {
  margin: 3.25rem !important;
}

.m-7 {
  margin: 3.5rem !important;
}

.m-8 {
  margin: 3.75rem !important;
}

.m-9 {
  margin: 4rem !important;
}

.m-10 {
  margin: 4.25rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-6 {
  margin-right: 3.25rem !important;
  margin-left: 3.25rem !important;
}

.mx-7 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}

.mx-8 {
  margin-right: 3.75rem !important;
  margin-left: 3.75rem !important;
}

.mx-9 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-10 {
  margin-right: 4.25rem !important;
  margin-left: 4.25rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-6 {
  margin-top: 3.25rem !important;
  margin-bottom: 3.25rem !important;
}

.my-7 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-8 {
  margin-top: 3.75rem !important;
  margin-bottom: 3.75rem !important;
}

.my-9 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-10 {
  margin-top: 4.25rem !important;
  margin-bottom: 4.25rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-6 {
  margin-top: 3.25rem !important;
}

.mt-7 {
  margin-top: 3.5rem !important;
}

.mt-8 {
  margin-top: 3.75rem !important;
}

.mt-9 {
  margin-top: 4rem !important;
}

.mt-10 {
  margin-top: 4.25rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-6 {
  margin-right: 3.25rem !important;
}

.me-7 {
  margin-right: 3.5rem !important;
}

.me-8 {
  margin-right: 3.75rem !important;
}

.me-9 {
  margin-right: 4rem !important;
}

.me-10 {
  margin-right: 4.25rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-6 {
  margin-bottom: 3.25rem !important;
}

.mb-7 {
  margin-bottom: 3.5rem !important;
}

.mb-8 {
  margin-bottom: 3.75rem !important;
}

.mb-9 {
  margin-bottom: 4rem !important;
}

.mb-10 {
  margin-bottom: 4.25rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-6 {
  margin-left: 3.25rem !important;
}

.ms-7 {
  margin-left: 3.5rem !important;
}

.ms-8 {
  margin-left: 3.75rem !important;
}

.ms-9 {
  margin-left: 4rem !important;
}

.ms-10 {
  margin-left: 4.25rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.m-n6 {
  margin: -3.25rem !important;
}

.m-n7 {
  margin: -3.5rem !important;
}

.m-n8 {
  margin: -3.75rem !important;
}

.m-n9 {
  margin: -4rem !important;
}

.m-n10 {
  margin: -4.25rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n6 {
  margin-right: -3.25rem !important;
  margin-left: -3.25rem !important;
}

.mx-n7 {
  margin-right: -3.5rem !important;
  margin-left: -3.5rem !important;
}

.mx-n8 {
  margin-right: -3.75rem !important;
  margin-left: -3.75rem !important;
}

.mx-n9 {
  margin-right: -4rem !important;
  margin-left: -4rem !important;
}

.mx-n10 {
  margin-right: -4.25rem !important;
  margin-left: -4.25rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n6 {
  margin-top: -3.25rem !important;
  margin-bottom: -3.25rem !important;
}

.my-n7 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.my-n8 {
  margin-top: -3.75rem !important;
  margin-bottom: -3.75rem !important;
}

.my-n9 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.my-n10 {
  margin-top: -4.25rem !important;
  margin-bottom: -4.25rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.mt-n6 {
  margin-top: -3.25rem !important;
}

.mt-n7 {
  margin-top: -3.5rem !important;
}

.mt-n8 {
  margin-top: -3.75rem !important;
}

.mt-n9 {
  margin-top: -4rem !important;
}

.mt-n10 {
  margin-top: -4.25rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.me-n6 {
  margin-right: -3.25rem !important;
}

.me-n7 {
  margin-right: -3.5rem !important;
}

.me-n8 {
  margin-right: -3.75rem !important;
}

.me-n9 {
  margin-right: -4rem !important;
}

.me-n10 {
  margin-right: -4.25rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.mb-n6 {
  margin-bottom: -3.25rem !important;
}

.mb-n7 {
  margin-bottom: -3.5rem !important;
}

.mb-n8 {
  margin-bottom: -3.75rem !important;
}

.mb-n9 {
  margin-bottom: -4rem !important;
}

.mb-n10 {
  margin-bottom: -4.25rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.ms-n6 {
  margin-left: -3.25rem !important;
}

.ms-n7 {
  margin-left: -3.5rem !important;
}

.ms-n8 {
  margin-left: -3.75rem !important;
}

.ms-n9 {
  margin-left: -4rem !important;
}

.ms-n10 {
  margin-left: -4.25rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-6 {
  padding: 3.25rem !important;
}

.p-7 {
  padding: 3.5rem !important;
}

.p-8 {
  padding: 3.75rem !important;
}

.p-9 {
  padding: 4rem !important;
}

.p-10 {
  padding: 4.25rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-6 {
  padding-right: 3.25rem !important;
  padding-left: 3.25rem !important;
}

.px-7 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.px-8 {
  padding-right: 3.75rem !important;
  padding-left: 3.75rem !important;
}

.px-9 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-10 {
  padding-right: 4.25rem !important;
  padding-left: 4.25rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-6 {
  padding-top: 3.25rem !important;
  padding-bottom: 3.25rem !important;
}

.py-7 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-8 {
  padding-top: 3.75rem !important;
  padding-bottom: 3.75rem !important;
}

.py-9 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-10 {
  padding-top: 4.25rem !important;
  padding-bottom: 4.25rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-6 {
  padding-top: 3.25rem !important;
}

.pt-7 {
  padding-top: 3.5rem !important;
}

.pt-8 {
  padding-top: 3.75rem !important;
}

.pt-9 {
  padding-top: 4rem !important;
}

.pt-10 {
  padding-top: 4.25rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pe-6 {
  padding-right: 3.25rem !important;
}

.pe-7 {
  padding-right: 3.5rem !important;
}

.pe-8 {
  padding-right: 3.75rem !important;
}

.pe-9 {
  padding-right: 4rem !important;
}

.pe-10 {
  padding-right: 4.25rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pb-6 {
  padding-bottom: 3.25rem !important;
}

.pb-7 {
  padding-bottom: 3.5rem !important;
}

.pb-8 {
  padding-bottom: 3.75rem !important;
}

.pb-9 {
  padding-bottom: 4rem !important;
}

.pb-10 {
  padding-bottom: 4.25rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.ps-6 {
  padding-left: 3.25rem !important;
}

.ps-7 {
  padding-left: 3.5rem !important;
}

.ps-8 {
  padding-left: 3.75rem !important;
}

.ps-9 {
  padding-left: 4rem !important;
}

.ps-10 {
  padding-left: 4.25rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.gap-6 {
  gap: 3.25rem !important;
}

.gap-7 {
  gap: 3.5rem !important;
}

.gap-8 {
  gap: 3.75rem !important;
}

.gap-9 {
  gap: 4rem !important;
}

.gap-10 {
  gap: 4.25rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.row-gap-6 {
  row-gap: 3.25rem !important;
}

.row-gap-7 {
  row-gap: 3.5rem !important;
}

.row-gap-8 {
  row-gap: 3.75rem !important;
}

.row-gap-9 {
  row-gap: 4rem !important;
}

.row-gap-10 {
  row-gap: 4.25rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.column-gap-6 {
  column-gap: 3.25rem !important;
}

.column-gap-7 {
  column-gap: 3.5rem !important;
}

.column-gap-8 {
  column-gap: 3.75rem !important;
}

.column-gap-9 {
  column-gap: 4rem !important;
}

.column-gap-10 {
  column-gap: 4.25rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.2674rem + 0.2088vw) !important;
}

.fs-2 {
  font-size: calc(1.2516rem + 0.0192vw) !important;
}

.fs-3 {
  font-size: 1.125rem !important;
}

.fs-4 {
  font-size: 1rem !important;
}

.fs-5 {
  font-size: 0.9rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-primary-medium {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-medium-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-primary-medium {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-medium-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-muted {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-muted-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary-medium {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-medium-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-muted {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-muted-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-6 {
    margin: 3.25rem !important;
  }
  .m-sm-7 {
    margin: 3.5rem !important;
  }
  .m-sm-8 {
    margin: 3.75rem !important;
  }
  .m-sm-9 {
    margin: 4rem !important;
  }
  .m-sm-10 {
    margin: 4.25rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-6 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }
  .mx-sm-7 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-sm-8 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-sm-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-sm-10 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-6 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }
  .my-sm-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-sm-8 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-sm-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-sm-10 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-6 {
    margin-top: 3.25rem !important;
  }
  .mt-sm-7 {
    margin-top: 3.5rem !important;
  }
  .mt-sm-8 {
    margin-top: 3.75rem !important;
  }
  .mt-sm-9 {
    margin-top: 4rem !important;
  }
  .mt-sm-10 {
    margin-top: 4.25rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-6 {
    margin-right: 3.25rem !important;
  }
  .me-sm-7 {
    margin-right: 3.5rem !important;
  }
  .me-sm-8 {
    margin-right: 3.75rem !important;
  }
  .me-sm-9 {
    margin-right: 4rem !important;
  }
  .me-sm-10 {
    margin-right: 4.25rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 3.25rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 3.5rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 3.75rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 4rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 4.25rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-6 {
    margin-left: 3.25rem !important;
  }
  .ms-sm-7 {
    margin-left: 3.5rem !important;
  }
  .ms-sm-8 {
    margin-left: 3.75rem !important;
  }
  .ms-sm-9 {
    margin-left: 4rem !important;
  }
  .ms-sm-10 {
    margin-left: 4.25rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .m-sm-n6 {
    margin: -3.25rem !important;
  }
  .m-sm-n7 {
    margin: -3.5rem !important;
  }
  .m-sm-n8 {
    margin: -3.75rem !important;
  }
  .m-sm-n9 {
    margin: -4rem !important;
  }
  .m-sm-n10 {
    margin: -4.25rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-sm-n6 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }
  .mx-sm-n7 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-sm-n8 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }
  .mx-sm-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-sm-n10 {
    margin-right: -4.25rem !important;
    margin-left: -4.25rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-sm-n6 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }
  .my-sm-n7 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-sm-n8 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }
  .my-sm-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-sm-n10 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -3rem !important;
  }
  .mt-sm-n6 {
    margin-top: -3.25rem !important;
  }
  .mt-sm-n7 {
    margin-top: -3.5rem !important;
  }
  .mt-sm-n8 {
    margin-top: -3.75rem !important;
  }
  .mt-sm-n9 {
    margin-top: -4rem !important;
  }
  .mt-sm-n10 {
    margin-top: -4.25rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n5 {
    margin-right: -3rem !important;
  }
  .me-sm-n6 {
    margin-right: -3.25rem !important;
  }
  .me-sm-n7 {
    margin-right: -3.5rem !important;
  }
  .me-sm-n8 {
    margin-right: -3.75rem !important;
  }
  .me-sm-n9 {
    margin-right: -4rem !important;
  }
  .me-sm-n10 {
    margin-right: -4.25rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -3.25rem !important;
  }
  .mb-sm-n7 {
    margin-bottom: -3.5rem !important;
  }
  .mb-sm-n8 {
    margin-bottom: -3.75rem !important;
  }
  .mb-sm-n9 {
    margin-bottom: -4rem !important;
  }
  .mb-sm-n10 {
    margin-bottom: -4.25rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n5 {
    margin-left: -3rem !important;
  }
  .ms-sm-n6 {
    margin-left: -3.25rem !important;
  }
  .ms-sm-n7 {
    margin-left: -3.5rem !important;
  }
  .ms-sm-n8 {
    margin-left: -3.75rem !important;
  }
  .ms-sm-n9 {
    margin-left: -4rem !important;
  }
  .ms-sm-n10 {
    margin-left: -4.25rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .p-sm-6 {
    padding: 3.25rem !important;
  }
  .p-sm-7 {
    padding: 3.5rem !important;
  }
  .p-sm-8 {
    padding: 3.75rem !important;
  }
  .p-sm-9 {
    padding: 4rem !important;
  }
  .p-sm-10 {
    padding: 4.25rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-6 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }
  .px-sm-7 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-sm-8 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-sm-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-sm-10 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-6 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }
  .py-sm-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-sm-8 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-sm-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-sm-10 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pt-sm-6 {
    padding-top: 3.25rem !important;
  }
  .pt-sm-7 {
    padding-top: 3.5rem !important;
  }
  .pt-sm-8 {
    padding-top: 3.75rem !important;
  }
  .pt-sm-9 {
    padding-top: 4rem !important;
  }
  .pt-sm-10 {
    padding-top: 4.25rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pe-sm-6 {
    padding-right: 3.25rem !important;
  }
  .pe-sm-7 {
    padding-right: 3.5rem !important;
  }
  .pe-sm-8 {
    padding-right: 3.75rem !important;
  }
  .pe-sm-9 {
    padding-right: 4rem !important;
  }
  .pe-sm-10 {
    padding-right: 4.25rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 3.25rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 3.5rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 3.75rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 4rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 4.25rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .ps-sm-6 {
    padding-left: 3.25rem !important;
  }
  .ps-sm-7 {
    padding-left: 3.5rem !important;
  }
  .ps-sm-8 {
    padding-left: 3.75rem !important;
  }
  .ps-sm-9 {
    padding-left: 4rem !important;
  }
  .ps-sm-10 {
    padding-left: 4.25rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .gap-sm-6 {
    gap: 3.25rem !important;
  }
  .gap-sm-7 {
    gap: 3.5rem !important;
  }
  .gap-sm-8 {
    gap: 3.75rem !important;
  }
  .gap-sm-9 {
    gap: 4rem !important;
  }
  .gap-sm-10 {
    gap: 4.25rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .row-gap-sm-6 {
    row-gap: 3.25rem !important;
  }
  .row-gap-sm-7 {
    row-gap: 3.5rem !important;
  }
  .row-gap-sm-8 {
    row-gap: 3.75rem !important;
  }
  .row-gap-sm-9 {
    row-gap: 4rem !important;
  }
  .row-gap-sm-10 {
    row-gap: 4.25rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  .column-gap-sm-6 {
    column-gap: 3.25rem !important;
  }
  .column-gap-sm-7 {
    column-gap: 3.5rem !important;
  }
  .column-gap-sm-8 {
    column-gap: 3.75rem !important;
  }
  .column-gap-sm-9 {
    column-gap: 4rem !important;
  }
  .column-gap-sm-10 {
    column-gap: 4.25rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-6 {
    margin: 3.25rem !important;
  }
  .m-md-7 {
    margin: 3.5rem !important;
  }
  .m-md-8 {
    margin: 3.75rem !important;
  }
  .m-md-9 {
    margin: 4rem !important;
  }
  .m-md-10 {
    margin: 4.25rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-6 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }
  .mx-md-7 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-md-8 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-md-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-md-10 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-6 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }
  .my-md-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-md-8 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-md-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-md-10 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-6 {
    margin-top: 3.25rem !important;
  }
  .mt-md-7 {
    margin-top: 3.5rem !important;
  }
  .mt-md-8 {
    margin-top: 3.75rem !important;
  }
  .mt-md-9 {
    margin-top: 4rem !important;
  }
  .mt-md-10 {
    margin-top: 4.25rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-6 {
    margin-right: 3.25rem !important;
  }
  .me-md-7 {
    margin-right: 3.5rem !important;
  }
  .me-md-8 {
    margin-right: 3.75rem !important;
  }
  .me-md-9 {
    margin-right: 4rem !important;
  }
  .me-md-10 {
    margin-right: 4.25rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-6 {
    margin-bottom: 3.25rem !important;
  }
  .mb-md-7 {
    margin-bottom: 3.5rem !important;
  }
  .mb-md-8 {
    margin-bottom: 3.75rem !important;
  }
  .mb-md-9 {
    margin-bottom: 4rem !important;
  }
  .mb-md-10 {
    margin-bottom: 4.25rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-6 {
    margin-left: 3.25rem !important;
  }
  .ms-md-7 {
    margin-left: 3.5rem !important;
  }
  .ms-md-8 {
    margin-left: 3.75rem !important;
  }
  .ms-md-9 {
    margin-left: 4rem !important;
  }
  .ms-md-10 {
    margin-left: 4.25rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .m-md-n6 {
    margin: -3.25rem !important;
  }
  .m-md-n7 {
    margin: -3.5rem !important;
  }
  .m-md-n8 {
    margin: -3.75rem !important;
  }
  .m-md-n9 {
    margin: -4rem !important;
  }
  .m-md-n10 {
    margin: -4.25rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-md-n6 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }
  .mx-md-n7 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-md-n8 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }
  .mx-md-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-md-n10 {
    margin-right: -4.25rem !important;
    margin-left: -4.25rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-md-n6 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }
  .my-md-n7 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-md-n8 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }
  .my-md-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-md-n10 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
  .mt-md-n6 {
    margin-top: -3.25rem !important;
  }
  .mt-md-n7 {
    margin-top: -3.5rem !important;
  }
  .mt-md-n8 {
    margin-top: -3.75rem !important;
  }
  .mt-md-n9 {
    margin-top: -4rem !important;
  }
  .mt-md-n10 {
    margin-top: -4.25rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -1rem !important;
  }
  .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .me-md-n5 {
    margin-right: -3rem !important;
  }
  .me-md-n6 {
    margin-right: -3.25rem !important;
  }
  .me-md-n7 {
    margin-right: -3.5rem !important;
  }
  .me-md-n8 {
    margin-right: -3.75rem !important;
  }
  .me-md-n9 {
    margin-right: -4rem !important;
  }
  .me-md-n10 {
    margin-right: -4.25rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -3.25rem !important;
  }
  .mb-md-n7 {
    margin-bottom: -3.5rem !important;
  }
  .mb-md-n8 {
    margin-bottom: -3.75rem !important;
  }
  .mb-md-n9 {
    margin-bottom: -4rem !important;
  }
  .mb-md-n10 {
    margin-bottom: -4.25rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n5 {
    margin-left: -3rem !important;
  }
  .ms-md-n6 {
    margin-left: -3.25rem !important;
  }
  .ms-md-n7 {
    margin-left: -3.5rem !important;
  }
  .ms-md-n8 {
    margin-left: -3.75rem !important;
  }
  .ms-md-n9 {
    margin-left: -4rem !important;
  }
  .ms-md-n10 {
    margin-left: -4.25rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .p-md-6 {
    padding: 3.25rem !important;
  }
  .p-md-7 {
    padding: 3.5rem !important;
  }
  .p-md-8 {
    padding: 3.75rem !important;
  }
  .p-md-9 {
    padding: 4rem !important;
  }
  .p-md-10 {
    padding: 4.25rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-6 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }
  .px-md-7 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-md-8 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-md-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-md-10 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-6 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }
  .py-md-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-md-8 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-md-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-md-10 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pt-md-6 {
    padding-top: 3.25rem !important;
  }
  .pt-md-7 {
    padding-top: 3.5rem !important;
  }
  .pt-md-8 {
    padding-top: 3.75rem !important;
  }
  .pt-md-9 {
    padding-top: 4rem !important;
  }
  .pt-md-10 {
    padding-top: 4.25rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pe-md-6 {
    padding-right: 3.25rem !important;
  }
  .pe-md-7 {
    padding-right: 3.5rem !important;
  }
  .pe-md-8 {
    padding-right: 3.75rem !important;
  }
  .pe-md-9 {
    padding-right: 4rem !important;
  }
  .pe-md-10 {
    padding-right: 4.25rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .pb-md-6 {
    padding-bottom: 3.25rem !important;
  }
  .pb-md-7 {
    padding-bottom: 3.5rem !important;
  }
  .pb-md-8 {
    padding-bottom: 3.75rem !important;
  }
  .pb-md-9 {
    padding-bottom: 4rem !important;
  }
  .pb-md-10 {
    padding-bottom: 4.25rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .ps-md-6 {
    padding-left: 3.25rem !important;
  }
  .ps-md-7 {
    padding-left: 3.5rem !important;
  }
  .ps-md-8 {
    padding-left: 3.75rem !important;
  }
  .ps-md-9 {
    padding-left: 4rem !important;
  }
  .ps-md-10 {
    padding-left: 4.25rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .gap-md-6 {
    gap: 3.25rem !important;
  }
  .gap-md-7 {
    gap: 3.5rem !important;
  }
  .gap-md-8 {
    gap: 3.75rem !important;
  }
  .gap-md-9 {
    gap: 4rem !important;
  }
  .gap-md-10 {
    gap: 4.25rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .row-gap-md-6 {
    row-gap: 3.25rem !important;
  }
  .row-gap-md-7 {
    row-gap: 3.5rem !important;
  }
  .row-gap-md-8 {
    row-gap: 3.75rem !important;
  }
  .row-gap-md-9 {
    row-gap: 4rem !important;
  }
  .row-gap-md-10 {
    row-gap: 4.25rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  .column-gap-md-6 {
    column-gap: 3.25rem !important;
  }
  .column-gap-md-7 {
    column-gap: 3.5rem !important;
  }
  .column-gap-md-8 {
    column-gap: 3.75rem !important;
  }
  .column-gap-md-9 {
    column-gap: 4rem !important;
  }
  .column-gap-md-10 {
    column-gap: 4.25rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-6 {
    margin: 3.25rem !important;
  }
  .m-lg-7 {
    margin: 3.5rem !important;
  }
  .m-lg-8 {
    margin: 3.75rem !important;
  }
  .m-lg-9 {
    margin: 4rem !important;
  }
  .m-lg-10 {
    margin: 4.25rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-6 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }
  .mx-lg-7 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-lg-8 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-lg-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-lg-10 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-6 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }
  .my-lg-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-lg-8 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-lg-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-lg-10 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-6 {
    margin-top: 3.25rem !important;
  }
  .mt-lg-7 {
    margin-top: 3.5rem !important;
  }
  .mt-lg-8 {
    margin-top: 3.75rem !important;
  }
  .mt-lg-9 {
    margin-top: 4rem !important;
  }
  .mt-lg-10 {
    margin-top: 4.25rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-6 {
    margin-right: 3.25rem !important;
  }
  .me-lg-7 {
    margin-right: 3.5rem !important;
  }
  .me-lg-8 {
    margin-right: 3.75rem !important;
  }
  .me-lg-9 {
    margin-right: 4rem !important;
  }
  .me-lg-10 {
    margin-right: 4.25rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 3.25rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 3.5rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 3.75rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 4rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 4.25rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-6 {
    margin-left: 3.25rem !important;
  }
  .ms-lg-7 {
    margin-left: 3.5rem !important;
  }
  .ms-lg-8 {
    margin-left: 3.75rem !important;
  }
  .ms-lg-9 {
    margin-left: 4rem !important;
  }
  .ms-lg-10 {
    margin-left: 4.25rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .m-lg-n6 {
    margin: -3.25rem !important;
  }
  .m-lg-n7 {
    margin: -3.5rem !important;
  }
  .m-lg-n8 {
    margin: -3.75rem !important;
  }
  .m-lg-n9 {
    margin: -4rem !important;
  }
  .m-lg-n10 {
    margin: -4.25rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-lg-n6 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }
  .mx-lg-n7 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-lg-n8 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }
  .mx-lg-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-lg-n10 {
    margin-right: -4.25rem !important;
    margin-left: -4.25rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-lg-n6 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }
  .my-lg-n7 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-lg-n8 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }
  .my-lg-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-lg-n10 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -3rem !important;
  }
  .mt-lg-n6 {
    margin-top: -3.25rem !important;
  }
  .mt-lg-n7 {
    margin-top: -3.5rem !important;
  }
  .mt-lg-n8 {
    margin-top: -3.75rem !important;
  }
  .mt-lg-n9 {
    margin-top: -4rem !important;
  }
  .mt-lg-n10 {
    margin-top: -4.25rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n5 {
    margin-right: -3rem !important;
  }
  .me-lg-n6 {
    margin-right: -3.25rem !important;
  }
  .me-lg-n7 {
    margin-right: -3.5rem !important;
  }
  .me-lg-n8 {
    margin-right: -3.75rem !important;
  }
  .me-lg-n9 {
    margin-right: -4rem !important;
  }
  .me-lg-n10 {
    margin-right: -4.25rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -3.25rem !important;
  }
  .mb-lg-n7 {
    margin-bottom: -3.5rem !important;
  }
  .mb-lg-n8 {
    margin-bottom: -3.75rem !important;
  }
  .mb-lg-n9 {
    margin-bottom: -4rem !important;
  }
  .mb-lg-n10 {
    margin-bottom: -4.25rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n5 {
    margin-left: -3rem !important;
  }
  .ms-lg-n6 {
    margin-left: -3.25rem !important;
  }
  .ms-lg-n7 {
    margin-left: -3.5rem !important;
  }
  .ms-lg-n8 {
    margin-left: -3.75rem !important;
  }
  .ms-lg-n9 {
    margin-left: -4rem !important;
  }
  .ms-lg-n10 {
    margin-left: -4.25rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .p-lg-6 {
    padding: 3.25rem !important;
  }
  .p-lg-7 {
    padding: 3.5rem !important;
  }
  .p-lg-8 {
    padding: 3.75rem !important;
  }
  .p-lg-9 {
    padding: 4rem !important;
  }
  .p-lg-10 {
    padding: 4.25rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-6 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }
  .px-lg-7 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-lg-8 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-lg-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-lg-10 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-6 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }
  .py-lg-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-lg-8 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-lg-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-lg-10 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pt-lg-6 {
    padding-top: 3.25rem !important;
  }
  .pt-lg-7 {
    padding-top: 3.5rem !important;
  }
  .pt-lg-8 {
    padding-top: 3.75rem !important;
  }
  .pt-lg-9 {
    padding-top: 4rem !important;
  }
  .pt-lg-10 {
    padding-top: 4.25rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pe-lg-6 {
    padding-right: 3.25rem !important;
  }
  .pe-lg-7 {
    padding-right: 3.5rem !important;
  }
  .pe-lg-8 {
    padding-right: 3.75rem !important;
  }
  .pe-lg-9 {
    padding-right: 4rem !important;
  }
  .pe-lg-10 {
    padding-right: 4.25rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 3.25rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 3.5rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 3.75rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 4rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 4.25rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .ps-lg-6 {
    padding-left: 3.25rem !important;
  }
  .ps-lg-7 {
    padding-left: 3.5rem !important;
  }
  .ps-lg-8 {
    padding-left: 3.75rem !important;
  }
  .ps-lg-9 {
    padding-left: 4rem !important;
  }
  .ps-lg-10 {
    padding-left: 4.25rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .gap-lg-6 {
    gap: 3.25rem !important;
  }
  .gap-lg-7 {
    gap: 3.5rem !important;
  }
  .gap-lg-8 {
    gap: 3.75rem !important;
  }
  .gap-lg-9 {
    gap: 4rem !important;
  }
  .gap-lg-10 {
    gap: 4.25rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .row-gap-lg-6 {
    row-gap: 3.25rem !important;
  }
  .row-gap-lg-7 {
    row-gap: 3.5rem !important;
  }
  .row-gap-lg-8 {
    row-gap: 3.75rem !important;
  }
  .row-gap-lg-9 {
    row-gap: 4rem !important;
  }
  .row-gap-lg-10 {
    row-gap: 4.25rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  .column-gap-lg-6 {
    column-gap: 3.25rem !important;
  }
  .column-gap-lg-7 {
    column-gap: 3.5rem !important;
  }
  .column-gap-lg-8 {
    column-gap: 3.75rem !important;
  }
  .column-gap-lg-9 {
    column-gap: 4rem !important;
  }
  .column-gap-lg-10 {
    column-gap: 4.25rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-6 {
    margin: 3.25rem !important;
  }
  .m-xl-7 {
    margin: 3.5rem !important;
  }
  .m-xl-8 {
    margin: 3.75rem !important;
  }
  .m-xl-9 {
    margin: 4rem !important;
  }
  .m-xl-10 {
    margin: 4.25rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-6 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }
  .mx-xl-7 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-xl-8 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-xl-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xl-10 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-6 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }
  .my-xl-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-xl-8 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-xl-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xl-10 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-6 {
    margin-top: 3.25rem !important;
  }
  .mt-xl-7 {
    margin-top: 3.5rem !important;
  }
  .mt-xl-8 {
    margin-top: 3.75rem !important;
  }
  .mt-xl-9 {
    margin-top: 4rem !important;
  }
  .mt-xl-10 {
    margin-top: 4.25rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-6 {
    margin-right: 3.25rem !important;
  }
  .me-xl-7 {
    margin-right: 3.5rem !important;
  }
  .me-xl-8 {
    margin-right: 3.75rem !important;
  }
  .me-xl-9 {
    margin-right: 4rem !important;
  }
  .me-xl-10 {
    margin-right: 4.25rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 3.25rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 3.75rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 4rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 4.25rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-6 {
    margin-left: 3.25rem !important;
  }
  .ms-xl-7 {
    margin-left: 3.5rem !important;
  }
  .ms-xl-8 {
    margin-left: 3.75rem !important;
  }
  .ms-xl-9 {
    margin-left: 4rem !important;
  }
  .ms-xl-10 {
    margin-left: 4.25rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .m-xl-n6 {
    margin: -3.25rem !important;
  }
  .m-xl-n7 {
    margin: -3.5rem !important;
  }
  .m-xl-n8 {
    margin: -3.75rem !important;
  }
  .m-xl-n9 {
    margin: -4rem !important;
  }
  .m-xl-n10 {
    margin: -4.25rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xl-n6 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }
  .mx-xl-n7 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-xl-n8 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }
  .mx-xl-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-xl-n10 {
    margin-right: -4.25rem !important;
    margin-left: -4.25rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xl-n6 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }
  .my-xl-n7 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-xl-n8 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }
  .my-xl-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-xl-n10 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -3rem !important;
  }
  .mt-xl-n6 {
    margin-top: -3.25rem !important;
  }
  .mt-xl-n7 {
    margin-top: -3.5rem !important;
  }
  .mt-xl-n8 {
    margin-top: -3.75rem !important;
  }
  .mt-xl-n9 {
    margin-top: -4rem !important;
  }
  .mt-xl-n10 {
    margin-top: -4.25rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n5 {
    margin-right: -3rem !important;
  }
  .me-xl-n6 {
    margin-right: -3.25rem !important;
  }
  .me-xl-n7 {
    margin-right: -3.5rem !important;
  }
  .me-xl-n8 {
    margin-right: -3.75rem !important;
  }
  .me-xl-n9 {
    margin-right: -4rem !important;
  }
  .me-xl-n10 {
    margin-right: -4.25rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -3.25rem !important;
  }
  .mb-xl-n7 {
    margin-bottom: -3.5rem !important;
  }
  .mb-xl-n8 {
    margin-bottom: -3.75rem !important;
  }
  .mb-xl-n9 {
    margin-bottom: -4rem !important;
  }
  .mb-xl-n10 {
    margin-bottom: -4.25rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n5 {
    margin-left: -3rem !important;
  }
  .ms-xl-n6 {
    margin-left: -3.25rem !important;
  }
  .ms-xl-n7 {
    margin-left: -3.5rem !important;
  }
  .ms-xl-n8 {
    margin-left: -3.75rem !important;
  }
  .ms-xl-n9 {
    margin-left: -4rem !important;
  }
  .ms-xl-n10 {
    margin-left: -4.25rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .p-xl-6 {
    padding: 3.25rem !important;
  }
  .p-xl-7 {
    padding: 3.5rem !important;
  }
  .p-xl-8 {
    padding: 3.75rem !important;
  }
  .p-xl-9 {
    padding: 4rem !important;
  }
  .p-xl-10 {
    padding: 4.25rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-6 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }
  .px-xl-7 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-xl-8 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-xl-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xl-10 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-6 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }
  .py-xl-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-xl-8 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-xl-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xl-10 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pt-xl-6 {
    padding-top: 3.25rem !important;
  }
  .pt-xl-7 {
    padding-top: 3.5rem !important;
  }
  .pt-xl-8 {
    padding-top: 3.75rem !important;
  }
  .pt-xl-9 {
    padding-top: 4rem !important;
  }
  .pt-xl-10 {
    padding-top: 4.25rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pe-xl-6 {
    padding-right: 3.25rem !important;
  }
  .pe-xl-7 {
    padding-right: 3.5rem !important;
  }
  .pe-xl-8 {
    padding-right: 3.75rem !important;
  }
  .pe-xl-9 {
    padding-right: 4rem !important;
  }
  .pe-xl-10 {
    padding-right: 4.25rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 3.25rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 3.75rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 4rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 4.25rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .ps-xl-6 {
    padding-left: 3.25rem !important;
  }
  .ps-xl-7 {
    padding-left: 3.5rem !important;
  }
  .ps-xl-8 {
    padding-left: 3.75rem !important;
  }
  .ps-xl-9 {
    padding-left: 4rem !important;
  }
  .ps-xl-10 {
    padding-left: 4.25rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .gap-xl-6 {
    gap: 3.25rem !important;
  }
  .gap-xl-7 {
    gap: 3.5rem !important;
  }
  .gap-xl-8 {
    gap: 3.75rem !important;
  }
  .gap-xl-9 {
    gap: 4rem !important;
  }
  .gap-xl-10 {
    gap: 4.25rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .row-gap-xl-6 {
    row-gap: 3.25rem !important;
  }
  .row-gap-xl-7 {
    row-gap: 3.5rem !important;
  }
  .row-gap-xl-8 {
    row-gap: 3.75rem !important;
  }
  .row-gap-xl-9 {
    row-gap: 4rem !important;
  }
  .row-gap-xl-10 {
    row-gap: 4.25rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  .column-gap-xl-6 {
    column-gap: 3.25rem !important;
  }
  .column-gap-xl-7 {
    column-gap: 3.5rem !important;
  }
  .column-gap-xl-8 {
    column-gap: 3.75rem !important;
  }
  .column-gap-xl-9 {
    column-gap: 4rem !important;
  }
  .column-gap-xl-10 {
    column-gap: 4.25rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1600px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-6 {
    margin: 3.25rem !important;
  }
  .m-xxl-7 {
    margin: 3.5rem !important;
  }
  .m-xxl-8 {
    margin: 3.75rem !important;
  }
  .m-xxl-9 {
    margin: 4rem !important;
  }
  .m-xxl-10 {
    margin: 4.25rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-6 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }
  .mx-xxl-7 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-xxl-8 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-xxl-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xxl-10 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-6 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }
  .my-xxl-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-xxl-8 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-xxl-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xxl-10 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-6 {
    margin-top: 3.25rem !important;
  }
  .mt-xxl-7 {
    margin-top: 3.5rem !important;
  }
  .mt-xxl-8 {
    margin-top: 3.75rem !important;
  }
  .mt-xxl-9 {
    margin-top: 4rem !important;
  }
  .mt-xxl-10 {
    margin-top: 4.25rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-6 {
    margin-right: 3.25rem !important;
  }
  .me-xxl-7 {
    margin-right: 3.5rem !important;
  }
  .me-xxl-8 {
    margin-right: 3.75rem !important;
  }
  .me-xxl-9 {
    margin-right: 4rem !important;
  }
  .me-xxl-10 {
    margin-right: 4.25rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 3.25rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 3.75rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 4rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 4.25rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-6 {
    margin-left: 3.25rem !important;
  }
  .ms-xxl-7 {
    margin-left: 3.5rem !important;
  }
  .ms-xxl-8 {
    margin-left: 3.75rem !important;
  }
  .ms-xxl-9 {
    margin-left: 4rem !important;
  }
  .ms-xxl-10 {
    margin-left: 4.25rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .m-xxl-n6 {
    margin: -3.25rem !important;
  }
  .m-xxl-n7 {
    margin: -3.5rem !important;
  }
  .m-xxl-n8 {
    margin: -3.75rem !important;
  }
  .m-xxl-n9 {
    margin: -4rem !important;
  }
  .m-xxl-n10 {
    margin: -4.25rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xxl-n6 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }
  .mx-xxl-n7 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-xxl-n8 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }
  .mx-xxl-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-xxl-n10 {
    margin-right: -4.25rem !important;
    margin-left: -4.25rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xxl-n6 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }
  .my-xxl-n7 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-xxl-n8 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }
  .my-xxl-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-xxl-n10 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }
  .mt-xxl-n6 {
    margin-top: -3.25rem !important;
  }
  .mt-xxl-n7 {
    margin-top: -3.5rem !important;
  }
  .mt-xxl-n8 {
    margin-top: -3.75rem !important;
  }
  .mt-xxl-n9 {
    margin-top: -4rem !important;
  }
  .mt-xxl-n10 {
    margin-top: -4.25rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n5 {
    margin-right: -3rem !important;
  }
  .me-xxl-n6 {
    margin-right: -3.25rem !important;
  }
  .me-xxl-n7 {
    margin-right: -3.5rem !important;
  }
  .me-xxl-n8 {
    margin-right: -3.75rem !important;
  }
  .me-xxl-n9 {
    margin-right: -4rem !important;
  }
  .me-xxl-n10 {
    margin-right: -4.25rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-xxl-n6 {
    margin-bottom: -3.25rem !important;
  }
  .mb-xxl-n7 {
    margin-bottom: -3.5rem !important;
  }
  .mb-xxl-n8 {
    margin-bottom: -3.75rem !important;
  }
  .mb-xxl-n9 {
    margin-bottom: -4rem !important;
  }
  .mb-xxl-n10 {
    margin-bottom: -4.25rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }
  .ms-xxl-n6 {
    margin-left: -3.25rem !important;
  }
  .ms-xxl-n7 {
    margin-left: -3.5rem !important;
  }
  .ms-xxl-n8 {
    margin-left: -3.75rem !important;
  }
  .ms-xxl-n9 {
    margin-left: -4rem !important;
  }
  .ms-xxl-n10 {
    margin-left: -4.25rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .p-xxl-6 {
    padding: 3.25rem !important;
  }
  .p-xxl-7 {
    padding: 3.5rem !important;
  }
  .p-xxl-8 {
    padding: 3.75rem !important;
  }
  .p-xxl-9 {
    padding: 4rem !important;
  }
  .p-xxl-10 {
    padding: 4.25rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxl-6 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }
  .px-xxl-7 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-xxl-8 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-xxl-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xxl-10 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-6 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }
  .py-xxl-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-xxl-8 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-xxl-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xxl-10 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pt-xxl-6 {
    padding-top: 3.25rem !important;
  }
  .pt-xxl-7 {
    padding-top: 3.5rem !important;
  }
  .pt-xxl-8 {
    padding-top: 3.75rem !important;
  }
  .pt-xxl-9 {
    padding-top: 4rem !important;
  }
  .pt-xxl-10 {
    padding-top: 4.25rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pe-xxl-6 {
    padding-right: 3.25rem !important;
  }
  .pe-xxl-7 {
    padding-right: 3.5rem !important;
  }
  .pe-xxl-8 {
    padding-right: 3.75rem !important;
  }
  .pe-xxl-9 {
    padding-right: 4rem !important;
  }
  .pe-xxl-10 {
    padding-right: 4.25rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 3.25rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 3.75rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 4rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 4.25rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .ps-xxl-6 {
    padding-left: 3.25rem !important;
  }
  .ps-xxl-7 {
    padding-left: 3.5rem !important;
  }
  .ps-xxl-8 {
    padding-left: 3.75rem !important;
  }
  .ps-xxl-9 {
    padding-left: 4rem !important;
  }
  .ps-xxl-10 {
    padding-left: 4.25rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .gap-xxl-6 {
    gap: 3.25rem !important;
  }
  .gap-xxl-7 {
    gap: 3.5rem !important;
  }
  .gap-xxl-8 {
    gap: 3.75rem !important;
  }
  .gap-xxl-9 {
    gap: 4rem !important;
  }
  .gap-xxl-10 {
    gap: 4.25rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .row-gap-xxl-6 {
    row-gap: 3.25rem !important;
  }
  .row-gap-xxl-7 {
    row-gap: 3.5rem !important;
  }
  .row-gap-xxl-8 {
    row-gap: 3.75rem !important;
  }
  .row-gap-xxl-9 {
    row-gap: 4rem !important;
  }
  .row-gap-xxl-10 {
    row-gap: 4.25rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }
  .column-gap-xxl-6 {
    column-gap: 3.25rem !important;
  }
  .column-gap-xxl-7 {
    column-gap: 3.5rem !important;
  }
  .column-gap-xxl-8 {
    column-gap: 3.75rem !important;
  }
  .column-gap-xxl-9 {
    column-gap: 4rem !important;
  }
  .column-gap-xxl-10 {
    column-gap: 4.25rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 1.424rem !important;
  }
  .fs-2 {
    font-size: 1.266rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
:root {
  --bs-dark-rgb: 50, 50, 50;
}

.alert-primary, .kip-whiteboard__pdf-buttons .kip-items .kip-item .kip-item-inner.kip-attempts-0:hover, .kip-question-navigator .kip-items .kip-item .kip-item-inner.kip-attempts-0:hover,
.alert-primary:focus {
  color: #00549f;
  background-color: #e3f5fc;
  border-width: 1px;
  border-color: rgb(159, 209.7169811321, 255);
}
.alert-primary:hover, .kip-whiteboard__pdf-buttons .kip-items .kip-item .kip-item-inner.kip-attempts-0:hover, .kip-question-navigator .kip-items .kip-item .kip-item-inner.kip-attempts-0:hover,
.alert-primary:focus:hover {
  border-color: #00549f;
}

.alert-secondary, .kip-whiteboard__pdf-buttons .kip-items .kip-item .kip-item-inner.kip-visited.kip-attempts-0, .kip-question-navigator .kip-items .kip-item .kip-item-inner.kip-visited.kip-attempts-0,
.alert-secondary:focus {
  color: #505d68;
  background-color: #f6f6f6;
  border-width: 1px;
  border-color: rgb(214.8695652174, 219.8858695652, 224.1304347826);
}
.alert-secondary:hover, .kip-whiteboard__pdf-buttons .kip-items .kip-item .kip-item-inner.kip-visited.kip-attempts-0:hover, .kip-question-navigator .kip-items .kip-item .kip-item-inner.kip-visited.kip-attempts-0:hover,
.alert-secondary:focus:hover {
  border-color: #505d68;
}

.alert-success, .kip-whiteboard__pdf-buttons .kip-items .kip-item .kip-item-inner.kip-correct.kip-attempts-1, .kip-question-navigator .kip-items .kip-item .kip-item-inner.kip-correct.kip-attempts-1, .kip-question .kip-layout .kip-matching-layout .kip-container .kip-column .kip-option.kip-correct,
.alert-success:focus {
  color: #177060;
  background-color: #d3f8df;
  border-width: 1px;
  border-color: rgb(155.4444444444, 234.5555555556, 220.3333333333);
}
.alert-success:hover, .kip-whiteboard__pdf-buttons .kip-items .kip-item .kip-item-inner.kip-correct.kip-attempts-1:hover, .kip-question-navigator .kip-items .kip-item .kip-item-inner.kip-correct.kip-attempts-1:hover, .kip-question .kip-layout .kip-matching-layout .kip-container .kip-column .kip-option.kip-correct:hover,
.alert-success:focus:hover {
  border-color: #177060;
}

.alert-info,
.alert-info:focus {
  color: #2f7683;
  background-color: #e3fbf2;
  border-width: 1px;
  border-color: rgb(198.3314606742, 229.0449438202, 234.6685393258);
}
.alert-info:hover,
.alert-info:focus:hover {
  border-color: #2f7683;
}

.alert-warning, .kip-whiteboard__pdf-buttons .kip-items .kip-item .kip-item-inner.kip-correct.kip-attempts-2, .kip-question-navigator .kip-items .kip-item .kip-item-inner.kip-correct.kip-attempts-2,
.alert-warning:focus {
  color: #9e4700;
  background-color: #fbecc9;
  border-width: 1px;
  border-color: rgb(255, 201.5886075949, 158);
}
.alert-warning:hover, .kip-whiteboard__pdf-buttons .kip-items .kip-item .kip-item-inner.kip-correct.kip-attempts-2:hover, .kip-question-navigator .kip-items .kip-item .kip-item-inner.kip-correct.kip-attempts-2:hover,
.alert-warning:focus:hover {
  border-color: #9e4700;
}

.alert-danger, .kip-question .kip-layout .kip-matching-layout .kip-container .kip-column .kip-option.kip-incorrect,
.alert-danger:focus {
  color: #a03535;
  background-color: #fae4d8;
  border-width: 1px;
  border-color: rgb(244.5492957746, 223.4507042254, 223.4507042254);
}
.alert-danger:hover, .kip-question .kip-layout .kip-matching-layout .kip-container .kip-column .kip-option.kip-incorrect:hover,
.alert-danger:focus:hover {
  border-color: #a03535;
}

.alert--dark.alert-primary, .kip-whiteboard__pdf-buttons .kip-items .kip-item .alert--dark.kip-item-inner.kip-attempts-0:hover, .kip-question-navigator .kip-items .kip-item .alert--dark.kip-item-inner.kip-attempts-0:hover {
  color: #00549f;
  background-color: #cce7ff;
  border-width: 1px;
  border-color: rgb(159, 209.7169811321, 255);
}
.alert--dark.alert-primary:hover, .kip-whiteboard__pdf-buttons .kip-items .kip-item .alert--dark.kip-item-inner.kip-attempts-0:hover, .kip-question-navigator .kip-items .kip-item .alert--dark.kip-item-inner.kip-attempts-0:hover {
  border-color: #00549f;
}

.alert--dark.alert-secondary, .kip-whiteboard__pdf-buttons .kip-items .kip-item .alert--dark.kip-item-inner.kip-visited.kip-attempts-0, .kip-question-navigator .kip-items .kip-item .alert--dark.kip-item-inner.kip-visited.kip-attempts-0 {
  color: #505d68;
  background-color: #f1f1f1;
  border-width: 1px;
  border-color: rgb(214.8695652174, 219.8858695652, 224.1304347826);
}
.alert--dark.alert-secondary:hover, .kip-whiteboard__pdf-buttons .kip-items .kip-item .alert--dark.kip-item-inner.kip-visited.kip-attempts-0:hover, .kip-question-navigator .kip-items .kip-item .alert--dark.kip-item-inner.kip-visited.kip-attempts-0:hover {
  border-color: #505d68;
}

.alert--dark.alert-success, .kip-whiteboard__pdf-buttons .kip-items .kip-item .alert--dark.kip-item-inner.kip-correct.kip-attempts-1, .kip-question-navigator .kip-items .kip-item .alert--dark.kip-item-inner.kip-correct.kip-attempts-1, .kip-question .kip-layout .kip-matching-layout .kip-container .kip-column .alert--dark.kip-option.kip-correct {
  color: #177060;
  background-color: #a9f2c8;
  border-width: 1px;
  border-color: rgb(155.4444444444, 234.5555555556, 220.3333333333);
}
.alert--dark.alert-success:hover, .kip-whiteboard__pdf-buttons .kip-items .kip-item .alert--dark.kip-item-inner.kip-correct.kip-attempts-1:hover, .kip-question-navigator .kip-items .kip-item .alert--dark.kip-item-inner.kip-correct.kip-attempts-1:hover, .kip-question .kip-layout .kip-matching-layout .kip-container .kip-column .alert--dark.kip-option.kip-correct:hover {
  border-color: #177060;
}

.alert--dark.alert-warning, .kip-whiteboard__pdf-buttons .kip-items .kip-item .alert--dark.kip-item-inner.kip-correct.kip-attempts-2, .kip-question-navigator .kip-items .kip-item .alert--dark.kip-item-inner.kip-correct.kip-attempts-2 {
  color: #9e4700;
  background-color: #f7d594;
  border-width: 1px;
  border-color: rgb(255, 201.5886075949, 158);
}
.alert--dark.alert-warning:hover, .kip-whiteboard__pdf-buttons .kip-items .kip-item .alert--dark.kip-item-inner.kip-correct.kip-attempts-2:hover, .kip-question-navigator .kip-items .kip-item .alert--dark.kip-item-inner.kip-correct.kip-attempts-2:hover {
  border-color: #9e4700;
}

.alert--dark.alert-danger, .kip-question .kip-layout .kip-matching-layout .kip-container .kip-column .alert--dark.kip-option.kip-incorrect {
  color: #a03535;
  background-color: #f5c4b3;
  border-width: 1px;
  border-color: rgb(244.5492957746, 223.4507042254, 223.4507042254);
}
.alert--dark.alert-danger:hover, .kip-question .kip-layout .kip-matching-layout .kip-container .kip-column .alert--dark.kip-option.kip-incorrect:hover {
  border-color: #a03535;
}

/* open-sans-300 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"), local("OpenSans-Light"), url("~/../global-assets/fonts/open-sans/open-sans-v17-latin-300.woff2") format("woff2"), url("~/../global-assets/fonts/open-sans/open-sans-v17-latin-300.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-300italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: local("Open Sans Light Italic"), local("OpenSans-LightItalic"), url("~/../global-assets/fonts/open-sans/open-sans-v17-latin-300italic.woff2") format("woff2"), url("~/../global-assets/fonts/open-sans/open-sans-v17-latin-300italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: local("Open Sans Italic"), local("OpenSans-Italic"), url("~/../global-assets/fonts/open-sans/open-sans-v17-latin-italic.woff2") format("woff2"), url("~/../global-assets/fonts/open-sans/open-sans-v17-latin-italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url("~/../global-assets/fonts/open-sans/open-sans-v17-latin-regular.woff2") format("woff2"), url("~/../global-assets/fonts/open-sans/open-sans-v17-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-600 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"), url("~/../global-assets/fonts/open-sans/open-sans-v17-latin-600.woff2") format("woff2"), url("~/../global-assets/fonts/open-sans/open-sans-v17-latin-600.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-600italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: local("Open Sans SemiBold Italic"), local("OpenSans-SemiBoldItalic"), url("~/../global-assets/fonts/open-sans/open-sans-v17-latin-600italic.woff2") format("woff2"), url("~/../global-assets/fonts/open-sans/open-sans-v17-latin-600italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* varela-round - latin */
@font-face {
  font-family: "Varela Round";
  font-style: normal;
  src: local("Varela Round"), local("VarelaRound"), url("~/../global-assets/fonts/varela-round-latin.woff2") format("woff2");
}
.text-overline {
  color: #00549f;
  font-size: 0.79rem;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 0.889rem;
  text-transform: uppercase;
}

.text-overline--inherit {
  color: inherit;
  font-size: 0.79rem;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 0.889rem;
  text-transform: uppercase;
}

.text-overline--secondary {
  color: #505d68;
  font-size: 0.79rem;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 0.889rem;
  text-transform: uppercase;
}

.small-2 {
  font-size: 0.875rem !important;
  line-height: 1.2;
}

.small-3 {
  font-size: 0.6875rem;
}

.small-4 {
  font-size: 0.5rem;
}

.small-5 {
  font-size: 0.79rem;
}

.small-6 {
  font-size: 0.845rem !important;
}

.text-tabular-nums {
  font-variant-numeric: tabular-nums !important;
}

.text-clamp {
  display: flex;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-sans-serif {
  font-family: sans-serif;
}

.text-btn {
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1.25px;
}

.font-size-normal {
  font-size: 1em !important;
}

.font-italic {
  font-style: italic !important;
}

.ls-normal {
  letter-spacing: normal !important;
}

.ls-wide {
  letter-spacing: 0.05em !important;
}

.text-link {
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 0.054em;
}

.text-link-primary {
  cursor: pointer;
  color: #00549f;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: 0.054em;
  transition: color 0.15s;
}
.text-link-primary:hover {
  color: rgb(0, 16.641509434, 31.5);
}

.font-spacing-wide {
  letter-spacing: 1.5px !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-gold {
  color: gold !important;
}

.rounded-bottom-right {
  border-bottom-right-radius: 4px !important;
}

.rounded-bottom-left {
  border-bottom-left-radius: 4px !important;
}

.rounded-bottom-left--xl {
  border-bottom-left-radius: 20px !important;
}

.rounded-right--xl {
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.rounded-top-left--xl {
  border-top-left-radius: 20px !important;
}

.rounded-top-left {
  border-top-left-radius: 0.35em !important;
}

.rounded-100 {
  border-radius: 100% !important;
}

.rounded-top-0 {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-lg {
  border-radius: 20px !important;
  overflow: hidden;
}

.rounded-top-lg {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  overflow: hidden;
}

.rounded-right-lg {
  border-bottom-right-radius: 20px !important;
  border-top-right-radius: 20px !important;
  overflow: hidden;
}

.rounded-left-lg {
  border-bottom-left-radius: 20px !important;
  border-top-left-radius: 20px !important;
  overflow: hidden;
}

.rounded-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-right-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom-lg {
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  overflow: hidden;
}

.rounded-top-left-lg {
  border-top-left-radius: 20px !important;
  overflow: hidden;
}

.rounded-bottom-right-lg {
  border-bottom-right-radius: 20px !important;
  overflow: hidden;
}

.rounded-bottom-left-0 {
  border-bottom-left-radius: 0 !important;
}

.shadow-lg {
  box-shadow: 2px 4px 8px rgba(212, 212, 212, 0.25) !important;
}
.theme-aurora .shadow-lg {
  /* stylelint-disable color-function-notation */
  box-shadow: 2px 4px 4px rgba(0, 84, 159, 0.2) !important;
  /* stylelint-enable color-function-notation */
}
.theme-sunset .shadow-lg {
  /* stylelint-disable color-function-notation */
  box-shadow: 2px 4px 4px rgba(178, 69, 37, 0.2) !important;
  /* stylelint-enable color-function-notation */
}
.theme-electric-storm .shadow-lg {
  /* stylelint-disable color-function-notation */
  box-shadow: 2px 4px 4px rgba(98, 12, 144, 0.2) !important;
  /* stylelint-enable color-function-notation */
}
.theme-lab-green .shadow-lg {
  /* stylelint-disable color-function-notation */
  box-shadow: 2px 4px 4px rgba(15, 95, 75, 0.2) !important;
  /* stylelint-enable color-function-notation */
}

.shadow-btn {
  box-shadow: 0 -2px 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16) !important;
}

.shadow-tight {
  box-shadow: 0 4px 4px rgba(212, 212, 212, 0.5) !important;
}

.shadow-0 {
  box-shadow: none !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.pointer-events-auto {
  pointer-events: auto !important;
}

.cursor-grab {
  cursor: grab !important;
}
.cursor-grab:active {
  cursor: grabbing !important;
}

.stroke-white {
  stroke: #fff;
  stroke-width: 20;
}

.stroke-dark {
  stroke: #424242;
  stroke-width: 20;
}

.stroke-none {
  stroke-width: 0;
}

.flex-even {
  flex: 1;
}

.w-33 {
  width: 33% !important;
}

.w-66 {
  width: 66% !important;
}

.min-w-0 {
  min-width: 0 !important;
}

.h-1 {
  height: 1px !important;
}

.h-btn {
  height: 44px !important;
  display: flex;
  align-items: center;
}

.mh-50vh {
  max-height: 50vh !important;
}

.position-unset {
  position: unset !important;
}

.top-0 {
  position: absolute;
  top: 0 !important;
}

.bottom-0 {
  position: absolute;
  bottom: 0 !important;
}

.left-0 {
  position: absolute;
  left: 0 !important;
}

.right-0 {
  position: absolute;
  right: 0 !important;
}

.z-index-20 {
  position: relative;
  z-index: 20;
}

.border-dashed {
  border-style: dashed !important;
}

.border-primary {
  transition: border-color 0.15s;
  border-color: #00549f !important;
}
.theme-aurora .border-primary {
  border-color: #b3dbff !important;
}
.theme-sunset .border-primary {
  border-color: #fdd4c8 !important;
}
.theme-electric-storm .border-primary {
  border-color: #e1baf6 !important;
}
.theme-lab-green .border-primary {
  border-color: #b4f7e6 !important;
}

.border-success {
  transition: border-color 0.15s;
  border-color: #a9f2c8 !important;
}

.border-warning {
  transition: border-color 0.15s;
  border-color: #f7d594 !important;
}

.border-danger {
  transition: border-color 0.15s;
  border-color: #f5c4b3 !important;
}

.border-secondary {
  transition: border-color 0.15s;
  border-color: #f1f1f1 !important;
}

.overflow-y {
  overflow: auto !important;
  overflow-x: hidden !important;
}

.overflow-x {
  overflow: auto !important;
  overflow-y: hidden !important;
}

.btn-height {
  height: 44px;
}

@keyframes animate-pulse-background {
  from {
    background-color: #fff;
  }
  to {
    background-color: #ecf5ff;
  }
}
.theme-aurora .text-primary {
  color: #00549f !important;
}
.theme-sunset .text-primary {
  color: #b24525 !important;
}
.theme-electric-storm .text-primary {
  color: #620c90 !important;
}
.theme-lab-green .text-primary {
  color: #0f5f4b !important;
}

.text-primary-400 {
  color: #99cfff !important;
}
.theme-aurora .text-primary-400 {
  color: #99cfff !important;
}
.theme-sunset .text-primary-400 {
  color: #fcc5b5 !important;
}
.theme-electric-storm .text-primary-400 {
  color: #d6a2f2 !important;
}
.theme-lab-green .text-primary-400 {
  color: #9af4dd !important;
}

.text-light {
  color: #dfdfdf !important;
}

.text-gold {
  color: gold !important;
}

.truncate-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fs-07 {
  font-size: 0.7rem;
}

.bg-primary-gradient {
  background: linear-gradient(315deg, rgba(154, 234, 230, 0.2) 0%, rgba(100, 175, 241, 0.2) 43.75%, rgba(146, 115, 230, 0.2) 100%), #fff;
  background-size: 100% 100%;
}
.theme-aurora .bg-primary-gradient {
  background: linear-gradient(315deg, rgba(154, 234, 230, 0.2) 0%, rgba(100, 175, 241, 0.2) 43.75%, rgba(146, 115, 230, 0.2) 100%), #fff;
}
.theme-sunset .bg-primary-gradient {
  background: linear-gradient(135deg, rgba(170, 83, 184, 0.2) 2.88%, rgba(194, 85, 53, 0.2) 100%), #fff;
}
.theme-electric-storm .bg-primary-gradient {
  background: linear-gradient(135deg, rgba(58, 66, 225, 0.2) 2.88%, rgba(98, 12, 144, 0.2) 98.14%), #fff;
}
.theme-lab-green .bg-primary-gradient {
  background: linear-gradient(82.84deg, rgba(74, 207, 140, 0.2) 0%, rgba(117, 237, 166, 0.2) 100%), #fff;
}

.bg-gradient-solid {
  background: linear-gradient(358deg, #19837e 0%, #00549f 43.75%, #280684 100%);
  background-size: 100% 100%;
}
.theme-aurora .bg-gradient-solid {
  background: linear-gradient(358deg, #19837e 0%, #00549f 43.75%, #280684 100%);
}
.theme-sunset .bg-gradient-solid {
  background: linear-gradient(178deg, #aa53b8 2.88%, #c25535 100%);
}
.theme-electric-storm .bg-gradient-solid {
  background: linear-gradient(178deg, #6f3ae1 2.88%, #620c90 98.14%);
}
.theme-lab-green .bg-gradient-solid {
  background: linear-gradient(21deg, #0f5f4b 0%, #088667 74.48%, #00bb8d 99.48%);
}

.bg-gradient-50 {
  background: linear-gradient(315deg, rgba(154, 234, 230, 0.5) 0%, rgba(100, 175, 241, 0.5) 43.75%, rgba(146, 115, 230, 0.5) 100%), #fff;
  background-size: 100% 100%;
}
.theme-aurora .bg-gradient-50 {
  background: linear-gradient(315deg, rgba(154, 234, 230, 0.5) 0%, rgba(100, 175, 241, 0.5) 43.75%, rgba(146, 115, 230, 0.5) 100%), #fff;
}
.theme-sunset .bg-gradient-50 {
  background: linear-gradient(135deg, rgba(170, 83, 184, 0.5) 2.88%, rgba(194, 85, 53, 0.5) 100%), #fff;
}
.theme-electric-storm .bg-gradient-50 {
  background: linear-gradient(135deg, rgba(58, 66, 225, 0.5) 2.88%, rgba(98, 12, 144, 0.5) 98.14%), #fff;
}
.theme-lab-green .bg-gradient-50 {
  background: linear-gradient(82.84deg, rgba(74, 207, 140, 0.5) 0%, rgba(117, 237, 166, 0.5) 100%), #fff;
}

.bg-pulse {
  background-color: #a03535;
  animation: animate-pulse-background 1.5s infinite;
  animation-direction: alternate;
}

.bg-none {
  background: none !important;
}

.bg-green-200 {
  background: #a9f2c8 !important;
}

.bg-orange-50 {
  background-color: #fffcf5 !important;
}

.bg-red-100 {
  background-color: #fae4d8 !important;
}

.bg-red-200 {
  background: #f5c4b3 !important;
}

@media (min-width: 768px) {
  .rounded-md-lg {
    border-radius: 20px !important;
  }
  .bg-md-primary-100 {
    background-color: #cce7ff;
  }
  .bg-md-aurora-primary-50 {
    background-color: #f2f9ff;
  }
  .bg-md-aurora-primary-100 {
    background-color: #e6f3ff;
  }
  .bg-md-aurora-primary-200 {
    background-color: #cce7ff;
  }
}
@media (max-width: 767.98px) {
  .bg-sm-kip-white {
    background-color: #fff;
  }
}
.bg-primary-100 {
  background-color: #cce7ff !important;
}
.theme-aurora .bg-primary-100 {
  background-color: #e6f3ff !important;
}
.theme-sunset .bg-primary-100 {
  background-color: #fff1ed !important;
}
.theme-electric-storm .bg-primary-100 {
  background-color: #f5e8fc !important;
}
.theme-lab-green .bg-primary-100 {
  background-color: #e6fdf7 !important;
}

.bg-primary-50 {
  background-color: #f2f9ff !important;
}
.theme-aurora .bg-primary-50 {
  background-color: #f2f9ff !important;
}
.theme-sunset .bg-primary-50 {
  background-color: #fef7f5 !important;
}
.theme-electric-storm .bg-primary-50 {
  background-color: #faf3fd !important;
}
.theme-lab-green .bg-primary-50 {
  background-color: #f2fdfb !important;
}

.bg-aurora-primary-50 {
  background-color: #f2f9ff !important;
}

.bg-aurora-primary-100 {
  background-color: #e6f3ff !important;
}

.bg-aurora-primary-200 {
  background-color: #cce7ff !important;
}

.overflow-initial {
  overflow: initial !important;
}

.fade-in-out {
  animation: fadeInOut ease-in-out 2s;
  animation-fill-mode: alternate;
  animation-iteration-count: 3;
}

.fade-in {
  animation: fadeIn ease-in 0.25s;
  animation-fill-mode: forwards;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.kip-color-picker-body {
  width: 252px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.kip-color-picker-body .kip-color-title {
  margin: 0 auto 16px 7px;
  font-style: normal;
  font-weight: 400;
  font-size: 14.22px;
  line-height: 17px;
  letter-spacing: 0.25px;
  color: #505d68;
}
.kip-color-picker-body .color-btn-body {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 7px;
  margin-bottom: 15px;
}
.kip-color-picker-body .color-btn-body .btn-color {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  border: unset;
}
.kip-color-picker-body .color-btn-body .active-color {
  outline: 3px solid;
}
.kip-color-picker-body .color-btn-body .btn-gradient-color {
  background: conic-gradient(from 180deg at 50% 50%, #f845d1 -47.86deg, #03e2ab 12.18deg, #9917df 70.48deg, #0087ff 131.43deg, #f86e45 190.32deg, #f8db45 249.7deg, #f845d1 312.14deg, #03e2ab 372.18deg);
}
.kip-color-picker-body .rgba-text,
.kip-color-picker-body .type-policy,
.kip-color-picker-body .button-area,
.kip-color-picker-body .hex-text {
  display: none !important;
}
.kip-color-picker-body .color-picker {
  max-width: 250px;
  border: unset !important;
  background: transparent !important;
}
.kip-color-picker-body .saturation-lightness {
  overflow: hidden;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.kip-color-picker-body .saturation-lightness .cursor {
  height: 28px !important;
  width: 28px !important;
}
.kip-color-picker-body.simple-mode .saturation-lightness {
  display: none;
}
.kip-color-picker-body.simple-mode .color-btn-body {
  padding: 0 14px;
}
.kip-color-picker-body.simple-mode .hue-alpha {
  border-radius: 4px;
  width: 252px;
}
.kip-color-picker-body.simple-mode .alpha {
  border-radius: 4px;
}
.kip-color-picker-body.simple-mode .hue-alpha .right .hue,
.kip-color-picker-body.simple-mode .hue-alpha .left {
  display: none !important;
}

.kip-question .kip-layout {
  width: 100%;
  font-size: 2rem;
  /* stylelint-disable no-invalid-position-at-import-rule */
  /* stylelint-enable no-invalid-position-at-import-rule */
}
.kip-question .kip-layout img {
  max-height: 50vh;
  max-width: 50%;
}
.kip-question .kip-layout img.image-width-10 {
  width: 10%;
  max-width: 93.5px;
  max-height: inherit;
}
.kip-question .kip-layout img.image-height-10 {
  height: 10vh;
  max-width: inherit;
  max-height: inherit;
}
.kip-question .kip-layout img.image-width-20 {
  width: 20%;
  max-width: 187px;
  max-height: inherit;
}
.kip-question .kip-layout img.image-height-20 {
  height: 20vh;
  max-width: inherit;
  max-height: inherit;
}
.kip-question .kip-layout img.image-width-30 {
  width: 30%;
  max-width: 280.5px;
  max-height: inherit;
}
.kip-question .kip-layout img.image-height-30 {
  height: 30vh;
  max-width: inherit;
  max-height: inherit;
}
.kip-question .kip-layout img.image-width-40 {
  width: 40%;
  max-width: 374px;
  max-height: inherit;
}
.kip-question .kip-layout img.image-height-40 {
  height: 40vh;
  max-width: inherit;
  max-height: inherit;
}
.kip-question .kip-layout img.image-width-50 {
  width: 50%;
  max-width: 467.5px;
  max-height: inherit;
}
.kip-question .kip-layout img.image-height-50 {
  height: 50vh;
  max-width: inherit;
  max-height: inherit;
}
.kip-question .kip-layout img.image-width-60 {
  width: 60%;
  max-width: 561px;
  max-height: inherit;
}
.kip-question .kip-layout img.image-height-60 {
  height: 60vh;
  max-width: inherit;
  max-height: inherit;
}
.kip-question .kip-layout img.image-width-70 {
  width: 70%;
  max-width: 654.5px;
  max-height: inherit;
}
.kip-question .kip-layout img.image-height-70 {
  height: 70vh;
  max-width: inherit;
  max-height: inherit;
}
.kip-question .kip-layout img.image-width-80 {
  width: 80%;
  max-width: 748px;
  max-height: inherit;
}
.kip-question .kip-layout img.image-height-80 {
  height: 80vh;
  max-width: inherit;
  max-height: inherit;
}
.kip-question .kip-layout img.image-width-90 {
  width: 90%;
  max-width: 841.5px;
  max-height: inherit;
}
.kip-question .kip-layout img.image-height-90 {
  height: 90vh;
  max-width: inherit;
  max-height: inherit;
}
.kip-question .kip-layout img.image-width-100 {
  width: 100%;
  max-width: 935px;
  max-height: inherit;
}
.kip-question .kip-layout img.image-height-100 {
  height: 100vh;
  max-width: inherit;
  max-height: inherit;
}
@media (width >= 760px) {
  .kip-question .kip-layout img.md-image-width-10 {
    width: 10%;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.md-image-height-10 {
    height: 10vh;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.md-image-width-20 {
    width: 20%;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.md-image-height-20 {
    height: 20vh;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.md-image-width-30 {
    width: 30%;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.md-image-height-30 {
    height: 30vh;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.md-image-width-40 {
    width: 40%;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.md-image-height-40 {
    height: 40vh;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.md-image-width-50 {
    width: 50%;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.md-image-height-50 {
    height: 50vh;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.md-image-width-60 {
    width: 60%;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.md-image-height-60 {
    height: 60vh;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.md-image-width-70 {
    width: 70%;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.md-image-height-70 {
    height: 70vh;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.md-image-width-80 {
    width: 80%;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.md-image-height-80 {
    height: 80vh;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.md-image-width-90 {
    width: 90%;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.md-image-height-90 {
    height: 90vh;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.md-image-width-100 {
    width: 100%;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.md-image-height-100 {
    height: 100vh;
    max-width: inherit;
    max-height: inherit;
  }
}
@media (width >= 950px) {
  .kip-question .kip-layout img.lg-image-width-10 {
    width: 10%;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.lg-image-height-10 {
    height: 10vh;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.lg-image-width-20 {
    width: 20%;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.lg-image-height-20 {
    height: 20vh;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.lg-image-width-30 {
    width: 30%;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.lg-image-height-30 {
    height: 30vh;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.lg-image-width-40 {
    width: 40%;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.lg-image-height-40 {
    height: 40vh;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.lg-image-width-50 {
    width: 50%;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.lg-image-height-50 {
    height: 50vh;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.lg-image-width-60 {
    width: 60%;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.lg-image-height-60 {
    height: 60vh;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.lg-image-width-70 {
    width: 70%;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.lg-image-height-70 {
    height: 70vh;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.lg-image-width-80 {
    width: 80%;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.lg-image-height-80 {
    height: 80vh;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.lg-image-width-90 {
    width: 90%;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.lg-image-height-90 {
    height: 90vh;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.lg-image-width-100 {
    width: 100%;
    max-width: inherit;
    max-height: inherit;
  }
  .kip-question .kip-layout img.lg-image-height-100 {
    height: 100vh;
    max-width: inherit;
    max-height: inherit;
  }
}
.kip-question .kip-layout table img {
  max-width: 90%;
  max-height: inherit;
}
.kip-question .kip-layout .kip-option-text img {
  max-width: 100%;
  max-height: inherit;
}
.kip-question .kip-layout .kip-question-text {
  user-select: none;
}
.kip-question .kip-layout .kip-input {
  font-size: 2rem;
  text-align: center;
  padding: 0;
  box-shadow: inset 0 0.5px 4px rgba(96, 97, 112, 0.32);
}
.kip-question .kip-layout .kip-input.form-control[readonly], .kip-question .kip-layout .kip-input[readonly].kip-stripe {
  background-color: #fff !important;
}
.kip-question .kip-layout .kip-option-image {
  max-width: 100%;
}
.kip-question .kip-layout .kip-option-sound {
  cursor: pointer;
  color: #424242;
  margin-left: 0.5rem;
}
.kip-question .kip-layout .kip-option-sound:hover {
  color: #00549f;
}
.theme-aurora .kip-question .kip-layout .kip-option-sound {
  color: #00549f;
  padding: 1.25em 1em 1.25em 0.75em;
  border-radius: 20px;
  margin-left: 0;
  margin-right: -1em;
}
.theme-aurora .kip-question .kip-layout .kip-option-sound:hover {
  color: #00549f;
}
.theme-sunset .kip-question .kip-layout .kip-option-sound {
  color: #b24525;
  padding: 1.25em 1em 1.25em 0.75em;
  border-radius: 20px;
  margin-left: 0;
  margin-right: -1em;
}
.theme-sunset .kip-question .kip-layout .kip-option-sound:hover {
  color: #b24525;
}
.theme-electric-storm .kip-question .kip-layout .kip-option-sound {
  color: #620c90;
  padding: 1.25em 1em 1.25em 0.75em;
  border-radius: 20px;
  margin-left: 0;
  margin-right: -1em;
}
.theme-electric-storm .kip-question .kip-layout .kip-option-sound:hover {
  color: #620c90;
}
.theme-lab-green .kip-question .kip-layout .kip-option-sound {
  color: #0f5f4b;
  padding: 1.25em 1em 1.25em 0.75em;
  border-radius: 20px;
  margin-left: 0;
  margin-right: -1em;
}
.theme-lab-green .kip-question .kip-layout .kip-option-sound:hover {
  color: #0f5f4b;
}
.kip-question .kip-layout .katex input {
  font-size: inherit;
  width: initial;
  line-height: initial;
}
.kip-question .kip-layout .kip-bring-to-front, .kip-question .kip-layout .kip-fill-in-the-blank-layout .kip-container .kip-target-container answer {
  position: relative;
  z-index: 20 !important;
}
.kip-question .kip-layout .katex .vlist-r {
  display: unset;
}
.kip-question .kip-layout .kip-generic-layout .ng-select.ng-select-single .ng-select-container {
  min-height: 36px;
  height: initial;
}
.kip-question .kip-layout .kip-generic-layout .kip-input {
  display: inline-block;
  text-align: left;
  height: auto;
  width: 5rem;
  border: 1px solid #b1bbc4;
}
.kip-question .kip-layout .kip-generic-layout .kip-input.form-control[readonly], .kip-question .kip-layout .kip-generic-layout .kip-input[readonly].kip-stripe {
  background-color: #fff !important;
}
.kip-question .kip-layout .kip-generic-layout .kip-input:focus {
  border: 1px solid #3889d4;
  box-shadow: 0 0 0 0.2rem rgba(0, 84, 159, 0.25);
}
.kip-question .kip-layout .kip-generic-layout .katex .kip-input {
  transform: scale(0.97);
}
.kip-question .kip-layout .kip-generic-layout .kip-input-1:not([style]) {
  min-width: 3.9em;
  width: calc(1 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-generic-layout .kip-input-2:not([style]) {
  min-width: 3.9em;
  width: calc(2 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-generic-layout .kip-input-3:not([style]) {
  min-width: 3.9em;
  width: calc(3 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-generic-layout .kip-input-4:not([style]) {
  min-width: 3.9em;
  width: calc(4 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-generic-layout .kip-input-5:not([style]) {
  min-width: 3.9em;
  width: calc(5 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-generic-layout .kip-input-6:not([style]) {
  min-width: 3.9em;
  width: calc(6 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-generic-layout .kip-input-7:not([style]) {
  min-width: 3.9em;
  width: calc(7 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-generic-layout .kip-input-8:not([style]) {
  min-width: 3.9em;
  width: calc(8 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-generic-layout .kip-input-9:not([style]) {
  min-width: 3.9em;
  width: calc(9 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-generic-layout .kip-input-10:not([style]) {
  min-width: 3.9em;
  width: calc(10 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-generic-layout .kip-input-11:not([style]) {
  min-width: 3.9em;
  width: calc(11 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-generic-layout .kip-input-12:not([style]) {
  min-width: 3.9em;
  width: calc(12 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-generic-layout .kip-input-13:not([style]) {
  min-width: 3.9em;
  width: calc(13 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-generic-layout .kip-input-14:not([style]) {
  min-width: 3.9em;
  width: calc(14 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-generic-layout .kip-input-15:not([style]) {
  min-width: 3.9em;
  width: calc(15 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-generic-layout .kip-input-16:not([style]) {
  min-width: 3.9em;
  width: calc(16 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-generic-layout .kip-input-17:not([style]) {
  min-width: 3.9em;
  width: calc(17 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-generic-layout .kip-input-18:not([style]) {
  min-width: 3.9em;
  width: calc(18 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-generic-layout .kip-input-19:not([style]) {
  min-width: 3.9em;
  width: calc(19 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-generic-layout .kip-input-20:not([style]) {
  min-width: 3.9em;
  width: calc(20 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-generic-layout .kip-text-area {
  resize: none;
  vertical-align: top;
}
.kip-question .kip-layout .kip-generic-layout table {
  margin: auto;
}
.kip-question .kip-layout .kip-multiple-choice-layout .custom-control.custom-radio {
  white-space: nowrap;
}
.kip-question .kip-layout .kip-multiple-choice-layout .kip-option-text {
  white-space: nowrap;
}
.kip-question .kip-layout .kip-multiple-choice-layout .kip-option-text p {
  margin-bottom: 0;
}
.kip-question .kip-layout .kip-multiple-choice-layout .kip-option-text img {
  min-width: 15vh;
}
.kip-question .kip-layout .kip-multiple-choice-layout .kip-option .kip-label::before, .kip-question .kip-layout .kip-multiple-choice-layout .kip-option .kip-label::after {
  margin-top: 0.75rem;
  height: 1.2rem;
  width: 1.2rem;
}
.kip-question .kip-layout .kip-multiple-choice-layout .kip-option .valid-choice {
  color: #155724 !important;
  background-color: #d4edda;
}
.kip-question .kip-layout .kip-multiple-choice-layout .kip-option .invalid-choice {
  color: #a03535 !important;
  background-color: #fae4d8;
}
@media (max-width: 575.98px) {
  .kip-question .kip-layout .kip-multiple-choice-layout .kip-option .kip-label::before, .kip-question .kip-layout .kip-multiple-choice-layout .kip-option .kip-label::after {
    margin-top: 0.25rem;
  }
}
.kip-question .kip-layout .kip-sorting-horizontal .kip-option {
  display: inline-block;
  width: initial;
  height: 100%;
  cursor: grab;
}
.kip-question .kip-layout .kip-sorting-horizontal .kip-option p {
  margin-block: 0;
}
.kip-question .kip-layout .kip-sorting-layout .kip-column {
  flex: 1;
}
.kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option,
.kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option-selected {
  cursor: grab;
  font-size: 18px;
  letter-spacing: 0.05em;
  text-shadow: none;
  padding: 1.1rem;
  border-radius: 8px;
  white-space: nowrap;
  text-align: center;
  min-width: 4rem;
  box-shadow: 0 -2px 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
}
.kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option:not([class*=alert-]),
.kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option-selected:not([class*=alert-]) {
  transition: background-color 0.15s;
  color: #00549f;
  background-color: #fff;
}
.theme-aurora .kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option:not([class*=alert-]),
.theme-aurora .kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option-selected:not([class*=alert-]) {
  color: #00549f;
}
.theme-sunset .kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option:not([class*=alert-]),
.theme-sunset .kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option-selected:not([class*=alert-]) {
  color: #b24525;
}
.theme-electric-storm .kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option:not([class*=alert-]),
.theme-electric-storm .kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option-selected:not([class*=alert-]) {
  color: #620c90;
}
.theme-lab-green .kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option:not([class*=alert-]),
.theme-lab-green .kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option-selected:not([class*=alert-]) {
  color: #0f5f4b;
}
.kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option:not([class*=alert-]):hover, .kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option:not([class*=alert-]):focus,
.kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option-selected:not([class*=alert-]):hover,
.kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option-selected:not([class*=alert-]):focus {
  background-color: #e6f3ff;
}
.theme-aurora .kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option:not([class*=alert-]):hover, .theme-aurora .kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option:not([class*=alert-]):focus,
.theme-aurora .kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option-selected:not([class*=alert-]):hover,
.theme-aurora .kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option-selected:not([class*=alert-]):focus {
  background-color: #e6f3ff;
}
.theme-sunset .kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option:not([class*=alert-]):hover, .theme-sunset .kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option:not([class*=alert-]):focus,
.theme-sunset .kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option-selected:not([class*=alert-]):hover,
.theme-sunset .kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option-selected:not([class*=alert-]):focus {
  background-color: #fff1ed;
}
.theme-electric-storm .kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option:not([class*=alert-]):hover, .theme-electric-storm .kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option:not([class*=alert-]):focus,
.theme-electric-storm .kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option-selected:not([class*=alert-]):hover,
.theme-electric-storm .kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option-selected:not([class*=alert-]):focus {
  background-color: #f5e8fc;
}
.theme-lab-green .kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option:not([class*=alert-]):hover, .theme-lab-green .kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option:not([class*=alert-]):focus,
.theme-lab-green .kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option-selected:not([class*=alert-]):hover,
.theme-lab-green .kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option-selected:not([class*=alert-]):focus {
  background-color: #e6fdf7;
}
.kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option.cdk-drag-placeholder,
.kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option-selected.cdk-drag-placeholder {
  visibility: hidden;
}
.kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option p,
.kip-question .kip-layout .kip-sorting-layout .kip-column .kip-option-selected p {
  margin-block: 0;
}
.kip-question .kip-layout .kip-sorting-layout .kip-column.kip-target {
  transition: background-color 0.15s;
  background-color: #e6f3ff;
  border-radius: 20px;
  padding: 0.5rem;
  padding-bottom: 0.25rem;
  border: 4px solid transparent;
}
.theme-aurora .kip-question .kip-layout .kip-sorting-layout .kip-column.kip-target {
  background-color: #e6f3ff;
}
.theme-sunset .kip-question .kip-layout .kip-sorting-layout .kip-column.kip-target {
  background-color: #fff1ed;
}
.theme-electric-storm .kip-question .kip-layout .kip-sorting-layout .kip-column.kip-target {
  background-color: #f5e8fc;
}
.theme-lab-green .kip-question .kip-layout .kip-sorting-layout .kip-column.kip-target {
  background-color: #e6fdf7;
}
.kip-question .kip-layout .kip-sorting-layout .kip-column.kip-target:has([class*=alert-]) {
  border-color: #dfdfdf;
  background: none;
}
.theme-aurora .kip-question .kip-layout .kip-sorting-layout .kip-column.kip-target:has([class*=alert-]) {
  background: none;
  border-color: #dfdfdf;
}
.theme-sunset .kip-question .kip-layout .kip-sorting-layout .kip-column.kip-target:has([class*=alert-]) {
  background: none;
  border-color: #dfdfdf;
}
.theme-electric-storm .kip-question .kip-layout .kip-sorting-layout .kip-column.kip-target:has([class*=alert-]) {
  background: none;
  border-color: #dfdfdf;
}
.theme-lab-green .kip-question .kip-layout .kip-sorting-layout .kip-column.kip-target:has([class*=alert-]) {
  background: none;
  border-color: #dfdfdf;
}
.kip-question .kip-layout .kip-sorting-layout .kip-column.kip-target .kip-option-selected {
  transition: background-color 0.15s, border-color 0.15s;
  text-align: center;
  border-radius: 8px;
  padding: 1.1rem;
  border: 1px solid transparent;
}
.kip-question .kip-layout .kip-sorting-layout .kip-column.kip-target .kip-option-selected[class*=alert-] {
  box-shadow: none;
}
.kip-question .kip-layout .kip-sorting-layout .kip-column.kip-target .kip-option-selected:not([class*=alert-]) {
  background-color: #fff;
  transition: border-color 0.15s;
}
.kip-question .kip-layout .kip-sorting-layout .kip-column.kip-target .kip-option-selected:not([class*=alert-]):hover {
  border: 1px solid #00549f;
  background-color: #fff;
}
.theme-aurora .kip-question .kip-layout .kip-sorting-layout .kip-column.kip-target .kip-option-selected:not([class*=alert-]):hover {
  border: 1px solid #00549f;
}
.theme-sunset .kip-question .kip-layout .kip-sorting-layout .kip-column.kip-target .kip-option-selected:not([class*=alert-]):hover {
  border: 1px solid #b24525;
}
.theme-electric-storm .kip-question .kip-layout .kip-sorting-layout .kip-column.kip-target .kip-option-selected:not([class*=alert-]):hover {
  border: 1px solid #620c90;
}
.theme-lab-green .kip-question .kip-layout .kip-sorting-layout .kip-column.kip-target .kip-option-selected:not([class*=alert-]):hover {
  border: 1px solid #0f5f4b;
}
.kip-question .kip-layout .kip-sorting-layout.kip-selection .kip-column {
  flex: auto;
  min-width: 43%;
}
.kip-question .kip-layout .kip-sorting-layout.kip-selection .kip-column.kip-spacer {
  min-width: 12%;
}
.kip-question .kip-layout .kip-sorting-layout.kip-selection .kip-column.kip-spacer .kip-icon {
  color: #00549f;
  margin: auto;
}
.theme-aurora .kip-question .kip-layout .kip-sorting-layout.kip-selection .kip-column.kip-spacer .kip-icon {
  color: #00549f;
}
.theme-sunset .kip-question .kip-layout .kip-sorting-layout.kip-selection .kip-column.kip-spacer .kip-icon {
  color: #b24525;
}
.theme-electric-storm .kip-question .kip-layout .kip-sorting-layout.kip-selection .kip-column.kip-spacer .kip-icon {
  color: #620c90;
}
.theme-lab-green .kip-question .kip-layout .kip-sorting-layout.kip-selection .kip-column.kip-spacer .kip-icon {
  color: #0f5f4b;
}
.kip-question .kip-layout .kip-matching-layout .kip-container {
  position: relative;
}
.kip-question .kip-layout .kip-matching-layout .kip-container .kip-connectors {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.kip-question .kip-layout .kip-matching-layout .kip-container .kip-connectors .kip-connector {
  stroke: #00549f;
  stroke-width: 2px;
}
.kip-question .kip-layout .kip-matching-layout .kip-container .kip-column {
  list-style: none;
  min-width: 40%;
  z-index: 2;
  position: inherit;
}
.kip-question .kip-layout .kip-matching-layout .kip-container .kip-column .kip-option {
  cursor: default;
  background-color: #e8e8e8;
  border: 1px solid #bebebe;
  font-size: 1.2rem;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}
.kip-question .kip-layout .kip-matching-layout .kip-container .kip-column .kip-option.kip-active {
  color: #fff;
  background-color: #00549f;
  border: 1px solid #00549f;
}
.kip-question .kip-layout .kip-matching-layout .kip-container .kip-column.kip-spacer {
  min-width: 20%;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.kip-question .kip-layout .kip-digital-clock-layout .kip-columns {
  background-color: black;
}
.kip-question .kip-layout .kip-digital-clock-layout .kip-columns .kip-button {
  color: #fff;
}
.kip-question .kip-layout .kip-digital-clock-layout .kip-columns .kip-digits {
  cursor: default;
  color: rgb(124, 252, 0);
  font-family: Digital-Regular, sans-serif;
  font-size: 4.5rem;
  user-select: none;
}
.kip-question .kip-layout .kip-digital-clock-layout .kip-columns .kip-digits-valid {
  background-color: unset !important;
}
.kip-question .kip-layout .kip-digital-clock-layout .kip-columns .kip-digits-invalid {
  background-color: unset !important;
}
.kip-question .kip-layout .kip-digital-clock-layout .kip-columns .kip-blink {
  animation: blink 1s infinite;
}
.kip-question .kip-layout .kip-analogue-clock-layout .kip-outer {
  stroke: #000;
  stroke-width: 2;
  fill: transparent;
}
.kip-question .kip-layout .kip-analogue-clock-layout .kip-center {
  fill: #000;
}
.kip-question .kip-layout .kip-analogue-clock-layout .kip-marker {
  stroke: #000;
  stroke-width: 1;
}
.kip-question .kip-layout .kip-analogue-clock-layout .kip-marker.kip-major {
  stroke-width: 2;
}
.kip-question .kip-layout .kip-analogue-clock-layout .kip-number {
  font-size: 1.1rem;
  stroke: #000;
  stroke-width: 1;
  text-anchor: middle;
}
.kip-question .kip-layout .kip-analogue-clock-layout .kip-hour-hand {
  stroke: #000;
  stroke-width: 8;
}
.kip-question .kip-layout .kip-analogue-clock-layout .kip-hour-hand-valid {
  stroke: #177060;
  stroke-width: 8;
}
.kip-question .kip-layout .kip-analogue-clock-layout .kip-hour-hand-invalid {
  stroke: #a03535;
  stroke-width: 8;
}
.kip-question .kip-layout .kip-analogue-clock-layout .kip-minute-hand {
  stroke: #000;
  stroke-width: 4;
}
.kip-question .kip-layout .kip-analogue-clock-layout .kip-minute-hand-valid {
  stroke: #177060;
  stroke-width: 4;
}
.kip-question .kip-layout .kip-analogue-clock-layout .kip-minute-hand-invalid {
  stroke: #a03535;
  stroke-width: 4;
}
.kip-question .kip-layout .kip-analogue-clock-layout .kip-handle {
  stroke: transparent;
  fill: transparent;
}
@media (hover: none) {
  .kip-question .kip-layout .kip-analogue-clock-layout .kip-handle:hover {
    fill: rgba(37, 123, 185, 0.2);
  }
}
.kip-question .kip-layout .kip-drill-layout {
  margin: auto;
  width: fit-content;
}
.kip-question .kip-layout .kip-drill-layout .drill-total-line-bottom {
  border-top: solid;
  border-top-width: 2px;
  border-top-color: black;
}
.kip-question .kip-layout .kip-drill-layout .drill-total-line-top {
  border-bottom: solid;
  border-bottom-width: 2px;
  border-bottom-color: black;
}
.kip-question .kip-layout .kip-drill-layout .drill-left {
  border-left: solid;
  border-left-width: 2px;
  border-left-color: black;
}
.kip-question .kip-layout .kip-drill-layout .drill-answer {
  width: 20px;
}
.kip-question .kip-layout .kip-spelling-layout .kip-letters {
  height: 2.5rem;
}
.kip-question .kip-layout .kip-spelling-layout .kip-letters .kip-input {
  width: 5rem;
}
.kip-question .kip-layout .kip-spelling-layout .kip-letters .kip-input.form-control[readonly], .kip-question .kip-layout .kip-spelling-layout .kip-letters .kip-input[readonly].kip-stripe {
  background-color: #fff !important;
}
.kip-question .kip-layout .kip-spelling-layout .kip-letters .kip-input-1:not([style]) {
  min-width: 3.9em;
  width: calc(1 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-spelling-layout .kip-letters .kip-input-2:not([style]) {
  min-width: 3.9em;
  width: calc(2 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-spelling-layout .kip-letters .kip-input-3:not([style]) {
  min-width: 3.9em;
  width: calc(3 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-spelling-layout .kip-letters .kip-input-4:not([style]) {
  min-width: 3.9em;
  width: calc(4 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-spelling-layout .kip-letters .kip-input-5:not([style]) {
  min-width: 3.9em;
  width: calc(5 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-spelling-layout .kip-letters .kip-input-6:not([style]) {
  min-width: 3.9em;
  width: calc(6 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-spelling-layout .kip-letters .kip-input-7:not([style]) {
  min-width: 3.9em;
  width: calc(7 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-spelling-layout .kip-letters .kip-input-8:not([style]) {
  min-width: 3.9em;
  width: calc(8 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-spelling-layout .kip-letters .kip-input-9:not([style]) {
  min-width: 3.9em;
  width: calc(9 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-spelling-layout .kip-letters .kip-input-10:not([style]) {
  min-width: 3.9em;
  width: calc(10 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-spelling-layout .kip-letters .kip-input-11:not([style]) {
  min-width: 3.9em;
  width: calc(11 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-spelling-layout .kip-letters .kip-input-12:not([style]) {
  min-width: 3.9em;
  width: calc(12 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-spelling-layout .kip-letters .kip-input-13:not([style]) {
  min-width: 3.9em;
  width: calc(13 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-spelling-layout .kip-letters .kip-input-14:not([style]) {
  min-width: 3.9em;
  width: calc(14 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-spelling-layout .kip-letters .kip-input-15:not([style]) {
  min-width: 3.9em;
  width: calc(15 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-spelling-layout .kip-letters .kip-input-16:not([style]) {
  min-width: 3.9em;
  width: calc(16 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-spelling-layout .kip-letters .kip-input-17:not([style]) {
  min-width: 3.9em;
  width: calc(17 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-spelling-layout .kip-letters .kip-input-18:not([style]) {
  min-width: 3.9em;
  width: calc(18 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-spelling-layout .kip-letters .kip-input-19:not([style]) {
  min-width: 3.9em;
  width: calc(19 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-spelling-layout .kip-letters .kip-input-20:not([style]) {
  min-width: 3.9em;
  width: calc(20 * 0.65em);
  text-align: center;
}
.kip-question .kip-layout .kip-word-picker-layout {
  width: fit-content;
  margin: auto;
}
.kip-question .kip-layout .kip-word-picker-layout .kip-word {
  cursor: pointer;
  margin-right: 2px;
  margin-left: 2px;
  display: inline-block;
  transition: 0.15s background-color;
}
.kip-question .kip-layout .kip-word-picker-layout .kip-word:hover {
  color: #00549f;
  background-color: #e6f3ff;
  padding: 0 0.25rem;
}
.theme-aurora .kip-question .kip-layout .kip-word-picker-layout .kip-word:hover {
  color: #00549f;
  background-color: #e6f3ff;
}
.theme-sunset .kip-question .kip-layout .kip-word-picker-layout .kip-word:hover {
  color: #b24525;
  background-color: #fff1ed;
}
.theme-electric-storm .kip-question .kip-layout .kip-word-picker-layout .kip-word:hover {
  color: #620c90;
  background-color: #f5e8fc;
}
.theme-lab-green .kip-question .kip-layout .kip-word-picker-layout .kip-word:hover {
  color: #0f5f4b;
  background-color: #e6fdf7;
}
.kip-question .kip-layout .kip-word-picker-layout .kip-word.kip-selected {
  color: #fff;
  background-color: #00549f;
}
.theme-aurora .kip-question .kip-layout .kip-word-picker-layout .kip-word.kip-selected {
  background-color: #00549f;
}
.theme-sunset .kip-question .kip-layout .kip-word-picker-layout .kip-word.kip-selected {
  background-color: #b24525;
}
.theme-electric-storm .kip-question .kip-layout .kip-word-picker-layout .kip-word.kip-selected {
  background-color: #620c90;
}
.theme-lab-green .kip-question .kip-layout .kip-word-picker-layout .kip-word.kip-selected {
  background-color: #0f5f4b;
}
.kip-question .kip-layout .kip-word-picker-layout .kip-word.kip-selected:hover {
  color: #fff;
}
.kip-question .kip-layout .kip-word-picker-layout .kip-word.kip-word-correct {
  color: #198754;
  background: #d3f8df;
  margin-bottom: 1px;
}
.kip-question .kip-layout .kip-word-picker-layout .kip-word.kip-word-incorrect {
  margin-bottom: 1px;
  color: #dc3545;
  background: #fae4d8;
}
.kip-question .kip-layout .kip-word-picker-layout.kip-readonly .kip-word {
  cursor: default;
}
.kip-question .kip-layout .kip-word-picker-layout.kip-readonly .kip-word:hover {
  color: #000;
}
.kip-question .kip-layout .kip-fill-in-the-blank-layout .kip-container {
  position: relative;
  user-select: none !important;
}
.kip-question .kip-layout .kip-fill-in-the-blank-layout .kip-container .kip-source-container {
  background-color: #e6f3ff;
  border-radius: 0.5em;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-self: center;
  flex-wrap: wrap;
}
.theme-aurora .kip-question .kip-layout .kip-fill-in-the-blank-layout .kip-container .kip-source-container {
  background-color: #e6f3ff;
}
.theme-sunset .kip-question .kip-layout .kip-fill-in-the-blank-layout .kip-container .kip-source-container {
  background-color: #fff1ed;
}
.theme-electric-storm .kip-question .kip-layout .kip-fill-in-the-blank-layout .kip-container .kip-source-container {
  background-color: #f5e8fc;
}
.theme-lab-green .kip-question .kip-layout .kip-fill-in-the-blank-layout .kip-container .kip-source-container {
  background-color: #e6fdf7;
}
.kip-question .kip-layout .kip-fill-in-the-blank-layout .kip-container .kip-source-container .kip-source {
  margin: 0 0.1em;
  color: #00549f;
  cursor: pointer;
}
.theme-aurora .kip-question .kip-layout .kip-fill-in-the-blank-layout .kip-container .kip-source-container .kip-source {
  color: #00549f;
}
.theme-sunset .kip-question .kip-layout .kip-fill-in-the-blank-layout .kip-container .kip-source-container .kip-source {
  color: #b24525;
}
.theme-electric-storm .kip-question .kip-layout .kip-fill-in-the-blank-layout .kip-container .kip-source-container .kip-source {
  color: #620c90;
}
.theme-lab-green .kip-question .kip-layout .kip-fill-in-the-blank-layout .kip-container .kip-source-container .kip-source {
  color: #0f5f4b;
}
.kip-question .kip-layout .kip-fill-in-the-blank-layout .kip-container .kip-source-container .kip-source.kip-item-selected {
  background-color: #198754;
}
.kip-question .kip-layout .kip-fill-in-the-blank-layout .kip-container .kip-source-container .kip-source:hover {
  color: #198754;
}
.kip-question .kip-layout .kip-fill-in-the-blank-layout .kip-container .kip-source-container .kip-source-item {
  color: #fd7e14;
}
.kip-question .kip-layout .kip-fill-in-the-blank-layout .kip-container .kip-target-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.kip-question .kip-layout .kip-fill-in-the-blank-layout .kip-container .kip-target-container answer {
  display: inline-block;
}
.kip-question .kip-layout .kip-fill-in-the-blank-layout .kip-container .kip-target-container answer .kip-target {
  white-space: nowrap !important;
  padding: 0 0.25rem;
  border-bottom: 2px solid #00549f;
}
.theme-aurora .kip-question .kip-layout .kip-fill-in-the-blank-layout .kip-container .kip-target-container answer .kip-target {
  border-bottom: 2px solid #00549f;
}
.theme-sunset .kip-question .kip-layout .kip-fill-in-the-blank-layout .kip-container .kip-target-container answer .kip-target {
  border-bottom: 2px solid #b24525;
}
.theme-electric-storm .kip-question .kip-layout .kip-fill-in-the-blank-layout .kip-container .kip-target-container answer .kip-target {
  border-bottom: 2px solid #620c90;
}
.theme-lab-green .kip-question .kip-layout .kip-fill-in-the-blank-layout .kip-container .kip-target-container answer .kip-target {
  border-bottom: 2px solid #0f5f4b;
}
.kip-question .kip-layout .kip-fill-in-the-blank-layout .kip-container .kip-target-container answer .kip-target-item {
  margin-bottom: 1px;
  margin-top: 1px;
}
.kip-question .kip-layout .kip-fill-in-the-blank-layout .kip-container .kip-target-container answer .kip-placeholder {
  background-color: #e8e8e8;
  margin-top: 1px;
  margin-bottom: 1px;
  width: 50px;
}
.kip-question .kip-layout .kip-fill-in-the-blank-layout .kip-container .kip-target-container answer .kip-placeholder-tutor {
  margin-top: 1px;
  margin-bottom: 1px;
  width: auto;
  padding: 0 0.25rem;
  border-radius: 4px;
}
.kip-question .kip-layout .kip-fill-in-the-blank-layout .kip-container .kip-mirror-item {
  position: absolute;
  background-color: #e6f3ff;
  opacity: 0.8;
  border-radius: 0.5em;
  padding: 0 0.25rem;
}
.theme-aurora .kip-question .kip-layout .kip-fill-in-the-blank-layout .kip-container .kip-mirror-item {
  background-color: #e6f3ff;
}
.theme-sunset .kip-question .kip-layout .kip-fill-in-the-blank-layout .kip-container .kip-mirror-item {
  background-color: #fff1ed;
}
.theme-electric-storm .kip-question .kip-layout .kip-fill-in-the-blank-layout .kip-container .kip-mirror-item {
  background-color: #f5e8fc;
}
.theme-lab-green .kip-question .kip-layout .kip-fill-in-the-blank-layout .kip-container .kip-mirror-item {
  background-color: #e6fdf7;
}
.kip-question .kip-layout .kip-fill-in-the-blank-layout .kip-container .kip-spacer {
  height: 50px;
}
.kip-question .kip-buttons {
  z-index: 20;
}
.kip-question .kip-buttons .kip-submit {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 1.25px;
  border-radius: 8px;
  height: 3.5em;
  width: 6em;
  border: 0;
  background: #00549f;
}
.theme-aurora .kip-question .kip-buttons .kip-submit {
  background: #00549f;
}
.theme-sunset .kip-question .kip-buttons .kip-submit {
  background: #b24525;
}
.theme-electric-storm .kip-question .kip-buttons .kip-submit {
  background: #620c90;
}
.theme-lab-green .kip-question .kip-buttons .kip-submit {
  background: #0f5f4b;
}
.kip-question .kip-buttons .kip-submit:hover {
  color: #fff;
  background: rgb(0, 57.0566037736, 108);
}
.theme-aurora .kip-question .kip-buttons .kip-submit:hover {
  background: rgb(0, 57.0566037736, 108);
}
.theme-sunset .kip-question .kip-buttons .kip-submit:hover {
  background: rgb(135.776744186, 52.6325581395, 28.223255814);
}
.theme-electric-storm .kip-question .kip-buttons .kip-submit:hover {
  background: rgb(65.9615384615, 8.0769230769, 96.9230769231);
}
.theme-lab-green .kip-question .kip-buttons .kip-submit:hover {
  background: rgb(8.0454545455, 50.9545454545, 40.2272727273);
}
@media (max-width: 575.98px) {
  .kip-question .kip-layout {
    font-size: 1.4rem;
  }
  .kip-question .kip-layout .kip-input {
    font-size: 1.4rem;
  }
}

.region-uk .image-au {
  display: none;
}
.region-uk .image-us {
  display: none;
}
.region-uk .image-nz {
  display: none;
}
.region-uk .image-sa {
  display: none;
}

.region-au .image-uk {
  display: none;
}
.region-au .image-us {
  display: none;
}
.region-au .image-nz {
  display: none;
}
.region-au .image-sa {
  display: none;
}

.region-sa .image-au {
  display: none;
}
.region-sa .image-us {
  display: none;
}
.region-sa .image-nz {
  display: none;
}
.region-sa .image-uk {
  display: none;
}

.region-nz .image-au {
  display: none;
}
.region-nz .image-us {
  display: none;
}
.region-nz .image-uk {
  display: none;
}
.region-nz .image-sa {
  display: none;
}

.region-us .image-au {
  display: none;
}
.region-us .image-nz {
  display: none;
}
.region-us .image-uk {
  display: none;
}
.region-us .image-sa {
  display: none;
}

.kip-question .kip-layout .kip-multiple-choice-layout .kip-label {
  backface-visibility: hidden;
  color: #00549f;
  background-color: #fff;
  width: auto;
  height: 100%;
  font-size: 18px;
  letter-spacing: 0.05em;
  text-shadow: none;
  padding: 1em;
  cursor: pointer;
  transition: background-color 0.15s;
  border-radius: 8px;
  outline: 1px solid #ddd;
}
.theme-aurora .kip-question .kip-layout .kip-multiple-choice-layout .kip-label {
  color: #00549f;
}
.theme-sunset .kip-question .kip-layout .kip-multiple-choice-layout .kip-label {
  color: #b24525;
}
.theme-electric-storm .kip-question .kip-layout .kip-multiple-choice-layout .kip-label {
  color: #620c90;
}
.theme-lab-green .kip-question .kip-layout .kip-multiple-choice-layout .kip-label {
  color: #0f5f4b;
}
.kip-question .kip-layout .kip-multiple-choice-layout .kip-label:hover {
  background-color: #e6f3ff;
}
.theme-aurora .kip-question .kip-layout .kip-multiple-choice-layout .kip-label:hover {
  background-color: #e6f3ff;
}
.theme-sunset .kip-question .kip-layout .kip-multiple-choice-layout .kip-label:hover {
  background-color: #fff1ed;
}
.theme-electric-storm .kip-question .kip-layout .kip-multiple-choice-layout .kip-label:hover {
  background-color: #f5e8fc;
}
.theme-lab-green .kip-question .kip-layout .kip-multiple-choice-layout .kip-label:hover {
  background-color: #e6fdf7;
}
.kip-question .kip-layout .kip-multiple-choice-layout input:checked + .kip-label:not(.valid-choice):not(.invalid-choice) {
  color: #00549f;
  background-color: #cce7ff;
  outline: 1px solid #00549f;
}
.theme-aurora .kip-question .kip-layout .kip-multiple-choice-layout input:checked + .kip-label:not(.valid-choice):not(.invalid-choice) {
  color: #00549f;
  background-color: #cce7ff;
  outline: 1px solid #00549f;
  border: 0;
}
.theme-sunset .kip-question .kip-layout .kip-multiple-choice-layout input:checked + .kip-label:not(.valid-choice):not(.invalid-choice) {
  color: #b24525;
  background-color: #fde2da;
  outline: 1px solid #b24525;
  border: 0;
}
.theme-electric-storm .kip-question .kip-layout .kip-multiple-choice-layout input:checked + .kip-label:not(.valid-choice):not(.invalid-choice) {
  color: #620c90;
  background-color: #ebd1f9;
  outline: 1px solid #620c90;
  border: 0;
}
.theme-lab-green .kip-question .kip-layout .kip-multiple-choice-layout input:checked + .kip-label:not(.valid-choice):not(.invalid-choice) {
  color: #0f5f4b;
  background-color: #cdf9ee;
  outline: 1px solid #0f5f4b;
  border: 0;
}
.kip-question .kip-layout .kip-multiple-choice-layout input:checked + .kip-label.valid-choice {
  outline: 3px solid #177060;
}
.kip-question .kip-layout .kip-multiple-choice-layout input:checked + .kip-label.invalid-choice {
  outline: 3px solid #a03535;
}
.kip-question .kip-layout .kip-multiple-choice-layout .valid-choice {
  outline: 0;
  color: #155724;
  background-color: #d4edda;
  pointer-events: none;
}
.kip-question .kip-layout .kip-multiple-choice-layout .valid-choice .kip-option-sound {
  color: #155724;
}
.kip-question .kip-layout .kip-multiple-choice-layout .invalid-choice {
  outline: 0;
  color: #a03535;
  background-color: #fae4d8;
  pointer-events: none;
}
.kip-question .kip-layout .kip-multiple-choice-layout .invalid-choice .kip-option-sound {
  color: #a03535;
}
.kip-question .kip-layout .kip-multiple-choice-layout .custom-control.custom-radio {
  white-space: nowrap;
}
.kip-question .kip-layout .kip-multiple-choice-layout .custom-control {
  height: 100%;
  width: 100%;
}
.kip-question .kip-layout .kip-multiple-choice-layout .custom-radio,
.kip-question .kip-layout .kip-multiple-choice-layout .custom-checkbox {
  padding-left: unset;
}
.kip-question .kip-layout .kip-multiple-choice-layout .custom-radio .custom-control-label::before, .kip-question .kip-layout .kip-multiple-choice-layout .custom-radio .custom-control-label::after,
.kip-question .kip-layout .kip-multiple-choice-layout .custom-radio .form-check-input::before,
.kip-question .kip-layout .kip-multiple-choice-layout .custom-radio .form-check-input::after,
.kip-question .kip-layout .kip-multiple-choice-layout .custom-checkbox .custom-control-label::before,
.kip-question .kip-layout .kip-multiple-choice-layout .custom-checkbox .custom-control-label::after,
.kip-question .kip-layout .kip-multiple-choice-layout .custom-checkbox .form-check-input::before,
.kip-question .kip-layout .kip-multiple-choice-layout .custom-checkbox .form-check-input::after {
  display: none;
}
.kip-question .kip-layout .kip-multiple-choice-layout .kip-option {
  padding: 0 0.25rem;
  margin-bottom: 0.5em;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.kip-question .kip-layout .kip-multiple-choice-layout .kip-input {
  opacity: 0;
  position: fixed;
  width: 0;
}
.kip-question .kip-layout .kip-multiple-choice-layout .row {
  align-items: flex-start;
  justify-content: center;
}
.kip-question .kip-layout .kip-multiple-choice-layout div[class*=col-] {
  width: auto;
  flex: 0;
}

.kip-success-tutor-answer {
  display: inline-block;
  color: #177060;
  background-color: #d3f8df;
  border-radius: 4px;
  padding: 0 0.25rem;
  font-weight: bold;
  margin-right: 0.25em;
}

.kip-generic-input {
  border-width: 1px;
  border-color: black;
  border-style: solid;
  background-color: floralwhite;
  padding: 2px;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

.kip-generic-choice {
  border-width: 1px;
  border-color: black;
  border-style: solid;
  background-color: yellow;
  padding: 2px;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

.kip-generic-image {
  border-width: 1px;
  border-color: black;
  border-style: solid;
  background-color: silver;
  width: 20px;
  display: inline-block;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
}

.kip-generic-clock {
  border-width: 1px;
  border-color: black;
  border-style: solid;
  background-color: gray;
  width: 30px;
  display: inline-block;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 10px;
}

.kip-tutor-toggle svg {
  height: 30px;
  width: 30px;
}

.kip-option.cdk-drag-preview,
.kip-option-selected.cdk-drag-preview {
  color: #424242;
  letter-spacing: 0.05em;
  font-family: Rubik, sans-serif;
}
.kip-option.cdk-drag-preview:not(:has(img)),
.kip-option-selected.cdk-drag-preview:not(:has(img)) {
  background-color: #fff;
  box-shadow: 0 -2px 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
}
.kip-option.cdk-drag-preview .image-height-10,
.kip-option-selected.cdk-drag-preview .image-height-10 {
  height: 10vh;
}

.kip-question-navigator {
  font-family: Rubik, sans-serif;
  font-size: 16px;
}
.kip-question-navigator .kip-scroller {
  cursor: pointer;
  background: #fff;
  line-height: 56px;
  min-width: 2rem;
  display: none;
  height: 56px;
  width: 56px;
  min-height: 56px;
  box-shadow: 0 0 0 rgba(40, 41, 61, 0.04), 2px 3px 4px rgba(96, 97, 112, 0.16);
}
@media (hover: hover) {
  .kip-question-navigator .kip-scroller:hover {
    background: #cce7ff;
  }
}
.kip-question-navigator .kip-items {
  list-style: none;
  overflow: hidden;
  position: relative;
  padding: 0;
  box-shadow: 0 0 0 rgba(40, 41, 61, 0.04), 2px 3px 4px rgba(96, 97, 112, 0.16);
}
.kip-question-navigator .kip-items--rounded {
  border-bottom-right-radius: 0.35em;
}
.kip-question-navigator .kip-items .kip-anchor {
  display: block;
}
.kip-question-navigator .kip-items .kip-item .kip-item-inner {
  cursor: pointer;
  border: 0 solid;
  line-height: 28px;
  min-width: 2rem;
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.kip-question-navigator .kip-items .kip-item .kip-item-inner[class*=kip-attempts-] {
  font-weight: 600;
  background-color: #fae4d8;
  color: #a03535;
  border: 0;
}
.kip-question-navigator .kip-items .kip-item .kip-item-inner.kip-attempts-ai {
  color: #00549f;
  background-color: rgb(153, 186.6, 216.6);
}
.kip-question-navigator .kip-items .kip-item .kip-item-inner.kip-attempts-0 {
  color: #424242;
  border-color: #e8e8e8;
  background-color: #fff;
  font-weight: 400;
}
.kip-question-navigator .kip-items .kip-item .kip-item-inner.kip-attempts-0:hover {
  border-color: #00549f;
}
.kip-question-navigator .kip-items .kip-item .kip-item-inner.kip-attempts-1 {
  border-color: #dcb453;
  color: #b24525;
  background-color: #fbecc9;
}
.kip-question-navigator .kip-items .kip-item.kip-active .kip-item-inner.kip-attempts-0 {
  border-color: #00549f;
  background-color: rgb(210, 233.7735849057, 255);
  color: #00549f;
}
.theme-aurora .kip-question-navigator .kip-items .kip-item.kip-active .kip-item-inner.kip-attempts-0 {
  transition: background-position 0.1s;
  background: linear-gradient(315deg, #280684 0%, #00549f 25%, #19837e 55%, #00549f 75%, #280684 100%);
  background-size: 200% auto;
  color: #fff;
}
@media (hover: hover) {
  .theme-aurora .kip-question-navigator .kip-items .kip-item.kip-active .kip-item-inner.kip-attempts-0:hover {
    color: #fff !important;
    background: linear-gradient(315deg, #280684 0%, #00549f 25%, #19837e 55%, #00549f 75%, #280684 100%);
    background-position: 100%;
  }
}
.theme-sunset .kip-question-navigator .kip-items .kip-item.kip-active .kip-item-inner.kip-attempts-0 {
  transition: background-position 0.1s;
  background: linear-gradient(315deg, #aa53b8 0%, #c25535 51%, #aa53b8 100%);
  background-size: 200% auto;
  color: #fff;
}
@media (hover: hover) {
  .theme-sunset .kip-question-navigator .kip-items .kip-item.kip-active .kip-item-inner.kip-attempts-0:hover {
    color: #fff !important;
    background: linear-gradient(315deg, #aa53b8 0%, #c25535 51%, #aa53b8 100%);
    background-position: 100%;
  }
}
.theme-electric-storm .kip-question-navigator .kip-items .kip-item.kip-active .kip-item-inner.kip-attempts-0 {
  transition: background-position 0.1s;
  background: linear-gradient(315deg, #6f3ae1 0%, #620c90 51%, #6f3ae1 100%);
  background-size: 200% auto;
  color: #fff;
}
@media (hover: hover) {
  .theme-electric-storm .kip-question-navigator .kip-items .kip-item.kip-active .kip-item-inner.kip-attempts-0:hover {
    color: #fff !important;
    background: linear-gradient(315deg, #6f3ae1 0%, #620c90 51%, #6f3ae1 100%);
    background-position: 100%;
  }
}
.theme-lab-green .kip-question-navigator .kip-items .kip-item.kip-active .kip-item-inner.kip-attempts-0 {
  transition: background-position 0.1s;
  background: linear-gradient(265deg, #0f5f4b 0%, #088667 37.5%, #00bb8d 51%, #088667 62.5%, #0f5f4b 100%);
  background-size: 200% auto;
  color: #fff;
}
@media (hover: hover) {
  .theme-lab-green .kip-question-navigator .kip-items .kip-item.kip-active .kip-item-inner.kip-attempts-0:hover {
    color: #fff !important;
    background: linear-gradient(265deg, #0f5f4b 0%, #088667 37.5%, #00bb8d 51%, #088667 62.5%, #0f5f4b 100%);
    background-position: 100%;
  }
}
.kip-question-navigator.kip-scrollable .kip-scroller {
  display: block;
}
.kip-question-navigator.kip-scrollable .kip-items {
  margin: 0;
}

.kip-question-preview .kip-icon {
  color: #dc3545;
}

.kip-awards__btn {
  width: 3em;
  height: 3em;
  border: 3px solid;
  border-color: transparent;
  transition: border-color 0.15s;
  border-radius: 0.5em;
}
.kip-awards__btn:hover {
  border-color: #00549f;
  cursor: pointer;
}
.kip-awards__btn--active {
  border-color: #00549f;
}
.kip-awards__display {
  width: 5.625em;
  height: 5.625em;
}

.kip-badge {
  border-radius: 4px;
  padding-left: 0.3333333333rem;
  padding-right: 0.3333333333rem;
  text-transform: uppercase;
}
.kip-badge--primary {
  color: #00549f;
  font-size: 0.79rem;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 0.889rem;
  text-transform: uppercase;
  background-color: #ecf5ff;
}
.theme-aurora .kip-badge--primary {
  background: #f2f9ff;
  color: #00549f;
}
.theme-sunset .kip-badge--primary {
  background: #fef7f5;
  color: #b24525;
}
.theme-electric-storm .kip-badge--primary {
  background: #faf3fd;
  color: #620c90;
}
.theme-lab-green .kip-badge--primary {
  background: #f2fdfb;
  color: #0f5f4b;
}
.kip-badge--outline {
  color: #00549f;
  font-size: 0.79rem;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 0.889rem;
  text-transform: uppercase;
  color: #707070;
  border: 1px solid #797979;
}
.kip-badge--round {
  border-radius: 1em;
}
.kip-badge--small {
  font-size: 0.6875rem;
  line-height: normal;
  padding: 0 0.25rem;
}

.btn-letter-spacing {
  letter-spacing: 0.025rem;
  text-transform: capitalize;
}

.kip-footer__button {
  display: block;
  width: 100%;
  --bs-btn-color: #fff;
  --bs-btn-bg: #00549f;
  --bs-btn-border-color: #00549f;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(0, 71.4, 135.15);
  --bs-btn-hover-border-color: rgb(0, 67.2, 127.2);
  --bs-btn-focus-shadow-rgb: 38, 110, 173;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(0, 67.2, 127.2);
  --bs-btn-active-border-color: rgb(0, 63, 119.25);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #00549f;
  --bs-btn-disabled-border-color: #00549f;
  letter-spacing: 0.025rem;
  text-transform: capitalize;
}
.kip-footer__button__action {
  background-color: #fff;
  border-color: var(--bs-border-color-translucent);
}
.kip-footer__button__action:hover {
  background-color: #00549f;
}

[class*=btn-outline-] {
  border-color: #c8c8c8;
}

.kip-paginator__button {
  --bs-btn-color: #505d68;
  --bs-btn-border-color: #505d68;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #00549f;
  --bs-btn-hover-border-color: rgb(0, 70.5283018868, 133.5);
  --bs-btn-focus-shadow-rgb: 80, 93, 104;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #00549f;
  --bs-btn-active-border-color: rgb(0, 70.5283018868, 133.5);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #505d68;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #505d68;
  --bs-gradient: none;
  border-color: #c8c8c8;
  width: 2.875rem;
}
.kip-paginator__button--width-only {
  width: 2.875rem;
}

.btn-circle {
  height: 39px;
  width: 39px;
  border-radius: 100%;
}

.btn-link--small {
  font-size: 0.79rem;
  font-weight: 600;
}
.btn-link--form {
  padding: 0;
  font-weight: 600;
  font-size: 0.79rem;
  line-height: 16px;
  letter-spacing: 0.125em;
}
.btn-link--grey-hover {
  color: #505d68;
}
.btn-link--grey-hover:hover {
  color: #424242;
  background-color: #f1f1f1;
}
.btn-link--grey-hover + .kip-dropdown__menu--top-right {
  margin-left: 0.85rem;
}

.kip-view-mode-button {
  align-items: center;
  display: flex;
  text-align: center;
}
.kip-view-mode-button--is-selected {
  background: #f5faff;
  border: 1px solid #3889d4;
  color: #00549f;
  opacity: 0.7;
}
.kip-view-mode-button--is-selected:focus {
  border: 1px solid #3889d4;
  box-shadow: 0 0 0 0.2rem rgba(0, 84, 159, 0.25);
}

.btn, .kip-footer__button, .kip-paginator__button {
  --bs-btn-border-width: 0;
}
.btn[class*=btn-outline-], [class*=btn-outline-].kip-footer__button, [class*=btn-outline-].kip-paginator__button {
  --bs-btn-border-width: 1px;
}
.btn--icon {
  --bs-btn-hover-bg: #f1f1f1;
  --bs-btn-hover-color: #505d68;
  background: transparent;
  border-radius: 50%;
  color: #505d68;
}
.btn--icon:hover {
  background-color: #f1f1f1;
  cursor: pointer;
  user-select: none;
}

.kip-btn {
  --bs-btn-disabled-border-color: #e7e7e7;
  --bs-btn-hover-color: #00549f;
  --bs-btn-hover-bg: #ecf5ff;
  --bs-btn-hover-border-color: #00549f;
  --bs-btn-active-color: #00549f;
  color: #00549f;
  background-color: #fff;
  border: 1px solid #e7e7e7;
}
.theme-aurora .kip-btn {
  color: #00549f;
}
.theme-sunset .kip-btn {
  color: #b24525;
}
.theme-electric-storm .kip-btn {
  color: #620c90;
}
.theme-lab-green .kip-btn {
  color: #0f5f4b;
}
.kip-btn:active:not([class*=alert-]), .kip-btn.active:not([class*=alert-]) {
  border-color: #3889d4;
  background-color: #e3f5fc;
}
.theme-aurora .kip-btn:active:not([class*=alert-]), .theme-aurora .kip-btn.active:not([class*=alert-]) {
  background: #e6f3ff;
}
.theme-sunset .kip-btn:active:not([class*=alert-]), .theme-sunset .kip-btn.active:not([class*=alert-]) {
  background: #fff1ed;
}
.theme-electric-storm .kip-btn:active:not([class*=alert-]), .theme-electric-storm .kip-btn.active:not([class*=alert-]) {
  background: #f5e8fc;
}
.theme-lab-green .kip-btn:active:not([class*=alert-]), .theme-lab-green .kip-btn.active:not([class*=alert-]) {
  background: #e6fdf7;
}
.kip-btn:active:not([class*=alert-]):focus, .kip-btn.active:not([class*=alert-]):focus {
  border: 1px solid #3889d4;
  box-shadow: 0 0 0 0.2rem rgba(0, 84, 159, 0.25);
}
.kip-btn.selected {
  background: #ecf5ff;
}
.theme-aurora .kip-btn.selected {
  background: #cce7ff;
}
.theme-sunset .kip-btn.selected {
  background: #fde2da;
}
.theme-electric-storm .kip-btn.selected {
  background: #ebd1f9;
}
.theme-lab-green .kip-btn.selected {
  background: #cdf9ee;
}
.kip-btn:disabled, .kip-btn.disabled {
  color: #505d68;
  pointer-events: none;
}
@media (hover: hover) {
  .kip-btn:hover {
    color: #00549f;
    background: #ecf5ff;
    border-color: #00549f;
  }
  .theme-aurora .kip-btn:hover {
    color: #00549f;
    background: #e6f3ff;
    border-color: #00549f;
  }
  .theme-sunset .kip-btn:hover {
    color: #b24525;
    background: #fff1ed;
    border-color: #b24525;
  }
  .theme-electric-storm .kip-btn:hover {
    color: #620c90;
    background: #f5e8fc;
    border-color: #620c90;
  }
  .theme-lab-green .kip-btn:hover {
    color: #0f5f4b;
    background: #e6fdf7;
    border-color: #0f5f4b;
  }
}
.kip-btn.btn-sm, .btn-group-sm > .kip-btn.btn, .btn-group-sm > .kip-btn.kip-footer__button, .btn-group-sm > .kip-btn.kip-paginator__button {
  font-size: 0.79rem;
  font-weight: bold;
}
.kip-btn.btn-success {
  background-color: #d3f8df;
  color: #177060;
}
.kip-btn.btn-danger {
  background-color: #fae4d8;
  color: #a03535;
}
.kip-btn.btn-outline-secondary {
  color: #505d68;
}
.kip-btn.btn-outline-secondary:hover {
  color: #505d68;
  background-color: #f6f6f6;
  border-color: #505d68;
}
.kip-btn.btn-outline-muted {
  color: #797979;
}
.kip-btn.btn-outline-muted:hover {
  color: #505d68;
  background-color: #f6f6f6;
  border-color: #797979;
}
.kip-btn.btn-outline-danger {
  color: #a03535;
}
.kip-btn.btn-outline-danger:hover {
  border-color: #a03535;
  background-color: #fae4d8;
}

.kip-dashboard-toggle {
  margin-right: 13px;
  margin-left: 11px;
}
.kip-dashboard-toggle svg {
  height: 24px;
  width: 24px;
}

.kip-tutor-solution-btn {
  border-color: transparent;
}
.kip-tutor-solution-btn:active {
  border-color: #3889d4;
}
.kip-tutor-solution-btn:active .kip-success-tutor-answer {
  background-color: #fff;
}

.kip-picker__day {
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #e7e7e7;
  border-radius: 50%;
  height: 2.369rem;
  width: 2.369rem;
}
@media (min-width: 576px) {
  .kip-picker__day {
    height: 3.157rem;
    width: 3.157rem;
  }
}
.kip-picker__day:hover:not(.disabled) {
  color: white;
  background-color: #00549f;
  border: 0;
  cursor: pointer;
}
.kip-picker__day--selected:not(.disabled) {
  color: white;
  background-color: #00549f;
  border: 0;
  cursor: pointer;
}
.kip-picker__day.disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.kip-picker__day--selected.disabled {
  background-color: #505d68;
  color: white;
}

.dropdown-toggle-split {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.dropdown-toggle:not(.dropdown-toggle-split),
.dropup .dropdown-toggle:not(.dropdown-toggle-split) {
  cursor: pointer;
}
.dropdown-toggle:not(.dropdown-toggle-split)::after,
.dropup .dropdown-toggle:not(.dropdown-toggle-split)::after {
  display: none;
}

.show-caret::after {
  display: inline-block;
}

.dropdown-menu[data-bs-popper] {
  left: auto;
}

.kip-dropdown {
  user-select: none;
}
.kip-dropdown__menu {
  min-width: fit-content !important;
  position: absolute !important;
  filter: drop-shadow(0 2px 8px rgba(40, 41, 61, 0.04)) drop-shadow(0 16px 24px rgba(96, 97, 112, 0.16));
  font-size: 0.89rem;
  margin-top: 0.5rem;
  margin-right: -0.5rem;
  padding: 0;
  left: auto;
  right: auto;
  max-width: 21rem;
  border: 0;
  border-radius: 10px;
  z-index: 110;
}
.kip-dropdown__menu kip-color-picker {
  display: inline-block;
  margin: 0.25rem 0;
}
@media (min-width: 1200px) {
  .kip-dropdown__menu {
    font-size: 1rem;
  }
}
.kip-dropdown__menu::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  box-sizing: border-box;
  border: 10px solid #000;
  border-color: transparent transparent #fff #fff;
  transform-origin: 0 0;
  box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.1);
}
.kip-dropdown__menu--no-arrow::after {
  display: none;
}
.kip-dropdown__menu--top-left {
  margin-left: -1rem;
}
.kip-dropdown__menu--top-left::after {
  transform: rotate(135deg);
  top: 0;
  left: 3rem;
}
.kip-dropdown__menu--top-left-sm::after {
  left: 1.35rem !important;
  border-width: 6.5px;
  top: 1px;
}
.kip-dropdown__menu--top-left[data-popper-placement=bottom-end]::after {
  display: none;
}
.kip-dropdown__menu--top-right {
  right: 0;
  margin-left: 1.175rem;
}
.kip-dropdown__menu--top-right::after {
  transform: rotate(135deg);
  top: 0;
  right: -0.5rem;
}
@media (min-width: 576px) {
  .kip-dropdown__menu--top-right::after {
    right: -0.55rem;
  }
}
.kip-dropdown__menu--bottom-left::after {
  transform: rotate(-45deg);
  bottom: -1.25rem;
  left: 1.25rem;
}
.kip-dropdown__menu--bottom-right::after {
  transform: rotate(-45deg);
  bottom: -1.25rem;
  right: 1.5rem;
}
.kip-dropdown__menu--right-top {
  right: 0;
  margin-left: 1.175rem;
}
.kip-dropdown__menu--right-top::after {
  transform: rotate(45deg);
  top: 0.25em;
  left: 0;
}
@media (min-width: 576px) {
  .kip-dropdown__menu--right-top::after {
    right: -0.55rem;
  }
}
.kip-dropdown__menu--margin-left-175 {
  margin-left: -1.75rem !important;
}
.kip-dropdown__entry {
  --bs-btn-hover-border-color: #e7e7e7;
  --bs-btn-border-radius: 0;
  --bs-btn-hover-color: #00549f;
  --bs-btn-hover-bg: #cce7ff;
  --bs-btn-active-border-color: #e7e7e7;
  display: flex;
  font-weight: normal;
  font-size: 0.875rem;
  padding: 0.5rem 0.8rem 0.5rem 0.5rem;
  border-bottom: 1px solid #e7e7e7;
  width: 100%;
  align-items: center;
  white-space: nowrap;
  transition: background-color 0.15s;
  cursor: pointer;
}
.kip-dropdown__entry--group {
  border-bottom: 0;
}
.theme-aurora .kip-dropdown__entry {
  font-size: 1rem;
}
.theme-sunset .kip-dropdown__entry {
  font-size: 1rem;
}
.theme-electric-storm .kip-dropdown__entry {
  font-size: 1rem;
}
.theme-lab-green .kip-dropdown__entry {
  font-size: 1rem;
}
.kip-dropdown__entry:hover {
  color: #00549f;
  background: #cce7ff;
}
.theme-aurora .kip-dropdown__entry:hover {
  color: #00549f;
  background-color: #e6f3ff;
}
.theme-sunset .kip-dropdown__entry:hover {
  color: #b24525;
  background-color: #fff1ed;
}
.theme-electric-storm .kip-dropdown__entry:hover {
  color: #620c90;
  background-color: #f5e8fc;
}
.theme-lab-green .kip-dropdown__entry:hover {
  color: #0f5f4b;
  background-color: #e6fdf7;
}
.kip-dropdown__entry:active {
  background-color: #e3f5fc;
  color: #00549f;
}
.theme-aurora .kip-dropdown__entry:active {
  background-color: #00549f;
  color: #fff;
}
.theme-sunset .kip-dropdown__entry:active {
  background-color: #b24525;
  color: #fff;
}
.theme-electric-storm .kip-dropdown__entry:active {
  background-color: #620c90;
  color: #fff;
}
.theme-lab-green .kip-dropdown__entry:active {
  background-color: #0f5f4b;
  color: #fff;
}
.kip-dropdown__entry:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.kip-dropdown__entry:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  border-bottom: 0;
}
.kip-dropdown__entry input {
  margin: 0 !important;
}
.kip-dropdown__toggle {
  min-width: 20em;
  width: 100%;
  font-size: 0.875rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e7e7e7;
  padding: 0.5rem;
}
.kip-dropdown__toggle:hover {
  background: #e3f5fc;
}
.kip-dropdown__overflow {
  max-height: 50vh;
  overflow-y: auto;
}
.kip-dropdown__overflow + .kip-dropdown__toggle {
  border-top: 1px solid #e7e7e7;
}
.kip-dropdown__open--activity {
  font-size: 0.875rem;
  display: flex;
  justify-content: space-between;
}
.kip-dropdown__open--activity.show {
  background: #e3f5fc;
}
.kip-dropdown__open--activity.show::before {
  position: absolute;
  width: 0;
  height: 0;
  box-sizing: border-box;
  border: 9px solid #000;
  border-color: transparent transparent #fff #fff;
  transform-origin: 0 0;
  box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.1);
  transform: rotate(135deg);
  bottom: -1.75em;
  left: 3rem;
  z-index: 120;
}
.kip-dropdown__theme-swatch {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  position: relative;
  transition: all 0.5s;
  margin-right: 1px;
}
.kip-dropdown__theme-swatch:last-of-type {
  margin-right: 0;
}
.kip-dropdown__theme-swatch::after {
  content: "";
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  top: 3px;
  left: 3px;
  border-radius: 50%;
}
.kip-dropdown__theme-swatch:hover, .kip-dropdown__theme-swatch.selected {
  cursor: pointer;
  position: relative;
}
.kip-dropdown__theme-swatch:hover::after, .kip-dropdown__theme-swatch.selected::after {
  content: "";
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  top: 3px;
  left: 3px;
  border-radius: 50%;
}
.kip-dropdown__theme-swatch--aurora::after {
  background: linear-gradient(358deg, #19837e 0%, #00549f 43.75%, #280684 100%);
}
.kip-dropdown__theme-swatch--aurora:hover, .kip-dropdown__theme-swatch--aurora.selected {
  background: linear-gradient(315deg, rgba(154, 234, 230, 0.5) 0%, rgba(100, 175, 241, 0.5) 43.75%, rgba(146, 115, 230, 0.5) 100%), #fff;
}
.kip-dropdown__theme-swatch--aurora:hover::after, .kip-dropdown__theme-swatch--aurora.selected::after {
  background: linear-gradient(358deg, #19837e 0%, #00549f 43.75%, #280684 100%);
}
.kip-dropdown__theme-swatch--sunset::after {
  background: linear-gradient(178deg, #aa53b8 2.88%, #c25535 100%);
}
.kip-dropdown__theme-swatch--sunset:hover, .kip-dropdown__theme-swatch--sunset.selected {
  background: linear-gradient(135deg, rgba(170, 83, 184, 0.5) 2.88%, rgba(194, 85, 53, 0.5) 100%), #fff;
}
.kip-dropdown__theme-swatch--sunset:hover::after, .kip-dropdown__theme-swatch--sunset.selected::after {
  background: linear-gradient(178deg, #aa53b8 2.88%, #c25535 100%);
}
.kip-dropdown__theme-swatch--electric-storm::after {
  background: linear-gradient(178deg, #6f3ae1 2.88%, #620c90 98.14%);
}
.kip-dropdown__theme-swatch--electric-storm:hover, .kip-dropdown__theme-swatch--electric-storm.selected {
  background: linear-gradient(135deg, rgba(58, 66, 225, 0.5) 2.88%, rgba(98, 12, 144, 0.5) 98.14%), #fff;
}
.kip-dropdown__theme-swatch--electric-storm:hover::after, .kip-dropdown__theme-swatch--electric-storm.selected::after {
  background: linear-gradient(178deg, #6f3ae1 2.88%, #620c90 98.14%);
}
.kip-dropdown__theme-swatch--lab-green::after {
  background: linear-gradient(21deg, #0f5f4b 0%, #088667 74.48%, #00bb8d 99.48%);
}
.kip-dropdown__theme-swatch--lab-green:hover, .kip-dropdown__theme-swatch--lab-green.selected {
  background: linear-gradient(82.84deg, rgba(74, 207, 140, 0.5) 0%, rgba(117, 237, 166, 0.5) 100%), #fff;
}
.kip-dropdown__theme-swatch--lab-green:hover::after, .kip-dropdown__theme-swatch--lab-green.selected::after {
  background: linear-gradient(21deg, #0f5f4b 0%, #088667 74.48%, #00bb8d 99.48%);
}

.kip-card-enrolment {
  border: 1px solid #ccc;
  border-radius: 10px;
  transition: all 0.2s linear;
  padding: 1rem 1rem;
}
.kip-card-enrolment:hover {
  border: 1px solid #3889d4;
  box-shadow: 0 2px 4px rgba(56, 137, 212, 0.25);
  text-decoration: none;
}

.kip-card-enrolment-link {
  border: 1px solid #ccc;
  border-radius: 10px;
  transition: all 0.2s linear;
  align-items: center;
  background: #fefefe;
  border-style: dashed;
  color: #00549f;
  justify-content: center;
  min-height: 215px;
}
.kip-card-enrolment-link:hover {
  border: 1px solid #3889d4;
  box-shadow: 0 2px 4px rgba(56, 137, 212, 0.25);
}

.kip-card-link {
  background-color: #fff;
  color: #505d68;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.kip-card-link__text {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: 0.125em;
  text-transform: uppercase;
}

.kip-card {
  background-color: #fff;
  color: #505d68;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.kip-card--summary {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
  padding: 1rem 1rem;
}
.kip-card--color {
  color: #00549f;
}
.kip-card__body {
  display: flex;
  flex-direction: column;
}
.kip-card__header {
  border-bottom: 0;
  padding-bottom: 1.5rem;
  align-items: center;
  display: flex;
  flex-direction: row;
}
.kip-card__header .ng-fa-icon {
  height: 1.266rem;
  line-height: 100%;
  color: #00549f;
}

.kip-card-paged .kip-info__entry {
  margin-bottom: 0;
}
.kip-card-paged__entry:nth-of-type(n+2) {
  padding-top: 1rem;
  border-top: 1px solid #e7e7e7;
}
.kip-card-paged__pagination {
  font-size: 0.89rem;
}
.kip-card-paged__pagination :disabled {
  color: #cfcfcf;
}

.form-control-invalid {
  display: none;
}

.form-group-invalid .form-control-invalid {
  align-items: center;
  display: flex;
  color: #a03535;
  font-size: 0.89rem;
  padding-top: 2px;
}
.form-group-invalid .form-control, .form-group-invalid .kip-stripe {
  border-color: rgb(193.7887323944, 70.2112676056, 70.2112676056);
}
.form-group-invalid .form-control:focus, .form-group-invalid .kip-stripe:focus {
  box-shadow: 0 0 0 0.2rem rgba(228, 96, 109, 0.25);
}

.kip-form-group {
  color: #505d68;
  margin-bottom: 1rem;
}
.kip-form-group label {
  color: #505d68;
  font-size: 0.89rem;
  margin-bottom: 0.5rem;
}

legend {
  color: #505d68;
  font-size: 1.266rem;
  font-weight: 300;
}
legend.legend--small {
  font-size: 0.89rem;
  font-weight: inherit;
}

.kip-choice:not(.form-switch) {
  align-items: center;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  color: #505d68;
  display: flex;
  padding: 0.75rem;
  transition: all 0.15s ease-in;
  user-select: none;
  width: 100%;
  cursor: pointer;
}
.kip-choice:not(.form-switch) label {
  margin-bottom: 0;
}
.theme-aurora .kip-choice:not(.form-switch) {
  border: 1px solid transparent;
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
  padding: 0;
}
.theme-aurora .kip-choice:not(.form-switch) label {
  font-size: 1em;
  color: #505d68;
  cursor: pointer;
}
.theme-aurora .kip-choice:not(.form-switch) .custom-control-input ~ .custom-control-label::before {
  width: 1.5em;
  height: 1.5em;
  margin-top: -0.25em;
  margin-left: -0.5em;
  border-radius: 0.5em;
  border-color: #00549f;
}
.theme-aurora .kip-choice:not(.form-switch) .custom-control-input:checked ~ .custom-control-label::before {
  background: linear-gradient(358deg, #19837e 0%, #00549f 43.75%, #280684 100%);
  margin-left: -0.5em;
}
.theme-aurora .kip-choice:not(.form-switch) .custom-control-input:checked ~ .custom-control-label::after {
  width: 1.5em;
  height: 1.5em;
  margin-top: -0.25em;
  margin-left: -0.5em;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='11' viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 1.39395C14 1.6752 13.875 1.9252 13.6875 2.1127L5.6875 10.1127C5.5 10.3002 5.25 10.394 5 10.394C4.71875 10.394 4.46875 10.3002 4.28125 10.1127L0.28125 6.1127C0.09375 5.9252 0 5.6752 0 5.39395C0 4.83145 0.4375 4.39395 1 4.39395C1.25 4.39395 1.5 4.51895 1.6875 4.70645L5 7.9877L12.2812 0.706451C12.4688 0.518951 12.7188 0.393951 13 0.393951C13.5312 0.393951 14 0.831451 14 1.39395Z' fill='white'/%3E%3C/svg%3E%0A");
}
.theme-aurora .kip-choice:not(.form-switch):hover {
  border-color: #00549f;
}
.theme-aurora .kip-choice:not(.form-switch):focus-within {
  outline: #00549f auto 5px;
}
.theme-sunset .kip-choice:not(.form-switch) {
  border: 1px solid transparent;
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
  padding: 0;
}
.theme-sunset .kip-choice:not(.form-switch) label {
  font-size: 1em;
  color: #505d68;
  cursor: pointer;
}
.theme-sunset .kip-choice:not(.form-switch) .custom-control-input ~ .custom-control-label::before {
  width: 1.5em;
  height: 1.5em;
  margin-top: -0.25em;
  margin-left: -0.5em;
  border-radius: 0.5em;
  border-color: #b24525;
}
.theme-sunset .kip-choice:not(.form-switch) .custom-control-input:checked ~ .custom-control-label::before {
  background: linear-gradient(178deg, #aa53b8 2.88%, #c25535 100%);
  margin-left: -0.5em;
}
.theme-sunset .kip-choice:not(.form-switch) .custom-control-input:checked ~ .custom-control-label::after {
  width: 1.5em;
  height: 1.5em;
  margin-top: -0.25em;
  margin-left: -0.5em;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='11' viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 1.39395C14 1.6752 13.875 1.9252 13.6875 2.1127L5.6875 10.1127C5.5 10.3002 5.25 10.394 5 10.394C4.71875 10.394 4.46875 10.3002 4.28125 10.1127L0.28125 6.1127C0.09375 5.9252 0 5.6752 0 5.39395C0 4.83145 0.4375 4.39395 1 4.39395C1.25 4.39395 1.5 4.51895 1.6875 4.70645L5 7.9877L12.2812 0.706451C12.4688 0.518951 12.7188 0.393951 13 0.393951C13.5312 0.393951 14 0.831451 14 1.39395Z' fill='white'/%3E%3C/svg%3E%0A");
}
.theme-sunset .kip-choice:not(.form-switch):hover {
  border-color: #b24525;
}
.theme-sunset .kip-choice:not(.form-switch):focus-within {
  outline: #b24525 auto 5px;
}
.theme-electric-storm .kip-choice:not(.form-switch) {
  border: 1px solid transparent;
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
  padding: 0;
}
.theme-electric-storm .kip-choice:not(.form-switch) label {
  font-size: 1em;
  color: #505d68;
  cursor: pointer;
}
.theme-electric-storm .kip-choice:not(.form-switch) .custom-control-input ~ .custom-control-label::before {
  width: 1.5em;
  height: 1.5em;
  margin-top: -0.25em;
  margin-left: -0.5em;
  border-radius: 0.5em;
  border-color: #620c90;
}
.theme-electric-storm .kip-choice:not(.form-switch) .custom-control-input:checked ~ .custom-control-label::before {
  background: linear-gradient(178deg, #6f3ae1 2.88%, #620c90 98.14%);
  margin-left: -0.5em;
}
.theme-electric-storm .kip-choice:not(.form-switch) .custom-control-input:checked ~ .custom-control-label::after {
  width: 1.5em;
  height: 1.5em;
  margin-top: -0.25em;
  margin-left: -0.5em;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='11' viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 1.39395C14 1.6752 13.875 1.9252 13.6875 2.1127L5.6875 10.1127C5.5 10.3002 5.25 10.394 5 10.394C4.71875 10.394 4.46875 10.3002 4.28125 10.1127L0.28125 6.1127C0.09375 5.9252 0 5.6752 0 5.39395C0 4.83145 0.4375 4.39395 1 4.39395C1.25 4.39395 1.5 4.51895 1.6875 4.70645L5 7.9877L12.2812 0.706451C12.4688 0.518951 12.7188 0.393951 13 0.393951C13.5312 0.393951 14 0.831451 14 1.39395Z' fill='white'/%3E%3C/svg%3E%0A");
}
.theme-electric-storm .kip-choice:not(.form-switch):hover {
  border-color: #620c90;
}
.theme-electric-storm .kip-choice:not(.form-switch):focus-within {
  outline: #620c90 auto 5px;
}
.theme-lab-green .kip-choice:not(.form-switch) {
  border: 1px solid transparent;
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
  padding: 0;
}
.theme-lab-green .kip-choice:not(.form-switch) label {
  font-size: 1em;
  color: #505d68;
  cursor: pointer;
}
.theme-lab-green .kip-choice:not(.form-switch) .custom-control-input ~ .custom-control-label::before {
  width: 1.5em;
  height: 1.5em;
  margin-top: -0.25em;
  margin-left: -0.5em;
  border-radius: 0.5em;
  border-color: #0f5f4b;
}
.theme-lab-green .kip-choice:not(.form-switch) .custom-control-input:checked ~ .custom-control-label::before {
  background: linear-gradient(21deg, #0f5f4b 0%, #088667 74.48%, #00bb8d 99.48%);
  margin-left: -0.5em;
}
.theme-lab-green .kip-choice:not(.form-switch) .custom-control-input:checked ~ .custom-control-label::after {
  width: 1.5em;
  height: 1.5em;
  margin-top: -0.25em;
  margin-left: -0.5em;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='11' viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 1.39395C14 1.6752 13.875 1.9252 13.6875 2.1127L5.6875 10.1127C5.5 10.3002 5.25 10.394 5 10.394C4.71875 10.394 4.46875 10.3002 4.28125 10.1127L0.28125 6.1127C0.09375 5.9252 0 5.6752 0 5.39395C0 4.83145 0.4375 4.39395 1 4.39395C1.25 4.39395 1.5 4.51895 1.6875 4.70645L5 7.9877L12.2812 0.706451C12.4688 0.518951 12.7188 0.393951 13 0.393951C13.5312 0.393951 14 0.831451 14 1.39395Z' fill='white'/%3E%3C/svg%3E%0A");
}
.theme-lab-green .kip-choice:not(.form-switch):hover {
  border-color: #0f5f4b;
}
.theme-lab-green .kip-choice:not(.form-switch):focus-within {
  outline: #0f5f4b auto 5px;
}
.kip-choice:not(.form-switch):focus-within {
  outline: -webkit-focus-ring-color auto 5px;
}
.kip-choice:not(.form-switch):hover {
  border: 1px solid #3889d4;
  box-shadow: 0 0 8px rgba(56, 137, 212, 0.2);
  text-decoration: none;
}
.kip-choice:not(.form-switch) > * {
  width: 100%;
}
.kip-choice:not(.form-switch):not(:last-of-type) {
  margin-bottom: 0.5rem;
}

.form-check-label--append {
  padding-left: 0.25rem;
  width: 100%;
}

.form-check {
  min-height: unset;
  margin-bottom: 0;
}

.kip-button-group {
  display: flex;
  flex-wrap: wrap;
}
.kip-button-group :only-child {
  border-radius: 4px;
}
.kip-button-group :first-child {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.kip-button-group :last-child {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.kip-button-group__option, .kip-button-group__radio {
  --bs-btn-active-border-color: #3889d4;
  --bs-btn-active-bg: #ecf5ff;
  --bs-btn-active-color: #00549f;
  align-items: center;
  background: #fff;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  color: #505d68;
  display: flex;
  font-weight: 600;
  font-size: 0.89rem;
  min-height: 3rem;
  justify-content: center;
  letter-spacing: 0.1rem;
  line-height: 1rem;
  text-align: center;
}
.kip-button-group__option:hover, .kip-button-group__radio:hover {
  background: #f5faff;
  border: 1px solid #3889d4;
  color: #00549f;
  cursor: pointer;
}
.theme-aurora .kip-button-group__option, .theme-aurora .kip-button-group__radio {
  --bs-btn-box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
  --bs-btn-focus-box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
  --bs-btn-active-bg: #e6f3ff;
  --bs-btn-active-color: #00549f;
  font-weight: 500;
  border: 0;
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
  color: #00549f;
}
.theme-aurora .kip-button-group__option:hover, .theme-aurora .kip-button-group__radio:hover {
  border: 0;
  background: #f2f9ff;
}
.theme-sunset .kip-button-group__option, .theme-sunset .kip-button-group__radio {
  --bs-btn-box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
  --bs-btn-focus-box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
  --bs-btn-active-bg: #fff1ed;
  --bs-btn-active-color: #b24525;
  font-weight: 500;
  border: 0;
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
  color: #b24525;
}
.theme-sunset .kip-button-group__option:hover, .theme-sunset .kip-button-group__radio:hover {
  border: 0;
  background: #fef7f5;
}
.theme-electric-storm .kip-button-group__option, .theme-electric-storm .kip-button-group__radio {
  --bs-btn-box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
  --bs-btn-focus-box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
  --bs-btn-active-bg: #f5e8fc;
  --bs-btn-active-color: #620c90;
  font-weight: 500;
  border: 0;
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
  color: #620c90;
}
.theme-electric-storm .kip-button-group__option:hover, .theme-electric-storm .kip-button-group__radio:hover {
  border: 0;
  background: #faf3fd;
}
.theme-lab-green .kip-button-group__option, .theme-lab-green .kip-button-group__radio {
  --bs-btn-box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
  --bs-btn-focus-box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
  --bs-btn-active-bg: #e6fdf7;
  --bs-btn-active-color: #0f5f4b;
  font-weight: 500;
  border: 0;
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
  color: #0f5f4b;
}
.theme-lab-green .kip-button-group__option:hover, .theme-lab-green .kip-button-group__radio:hover {
  border: 0;
  background: #f2fdfb;
}
.kip-button-group__radio {
  color: #505d68;
  line-height: 1.5rem;
  min-height: auto;
  letter-spacing: unset;
  text-transform: uppercase;
  --bs-btn-bg: #cce7ff;
  --bs-btn-color: #00549f;
  --bs-btn-border-color: #00549f;
}
.theme-aurora .kip-button-group__radio {
  text-transform: unset;
  padding: 1em 0;
  letter-spacing: 1.25px;
  font-size: 1em;
}
.theme-aurora .kip-button-group__radio[for=theme_theme-aurora] {
  color: #00549f;
  background: #fff;
}
.theme-aurora .kip-button-group__radio[for=theme_theme-electric-storm] {
  color: #620c90;
  background: #fff;
}
.theme-aurora .kip-button-group__radio[for=theme_theme-lab-green] {
  color: #0f5f4b;
  background: #fff;
}
.theme-aurora .kip-button-group__radio[for=theme_theme-sunset] {
  color: #b24525;
  background: #fff;
}
.theme-sunset .kip-button-group__radio {
  text-transform: unset;
  padding: 1em 0;
  letter-spacing: 1.25px;
  font-size: 1em;
}
.theme-sunset .kip-button-group__radio[for=theme_theme-aurora] {
  color: #00549f;
  background: #fff;
}
.theme-sunset .kip-button-group__radio[for=theme_theme-electric-storm] {
  color: #620c90;
  background: #fff;
}
.theme-sunset .kip-button-group__radio[for=theme_theme-lab-green] {
  color: #0f5f4b;
  background: #fff;
}
.theme-sunset .kip-button-group__radio[for=theme_theme-sunset] {
  color: #b24525;
  background: #fff;
}
.theme-electric-storm .kip-button-group__radio {
  text-transform: unset;
  padding: 1em 0;
  letter-spacing: 1.25px;
  font-size: 1em;
}
.theme-electric-storm .kip-button-group__radio[for=theme_theme-aurora] {
  color: #00549f;
  background: #fff;
}
.theme-electric-storm .kip-button-group__radio[for=theme_theme-electric-storm] {
  color: #620c90;
  background: #fff;
}
.theme-electric-storm .kip-button-group__radio[for=theme_theme-lab-green] {
  color: #0f5f4b;
  background: #fff;
}
.theme-electric-storm .kip-button-group__radio[for=theme_theme-sunset] {
  color: #b24525;
  background: #fff;
}
.theme-lab-green .kip-button-group__radio {
  text-transform: unset;
  padding: 1em 0;
  letter-spacing: 1.25px;
  font-size: 1em;
}
.theme-lab-green .kip-button-group__radio[for=theme_theme-aurora] {
  color: #00549f;
  background: #fff;
}
.theme-lab-green .kip-button-group__radio[for=theme_theme-electric-storm] {
  color: #620c90;
  background: #fff;
}
.theme-lab-green .kip-button-group__radio[for=theme_theme-lab-green] {
  color: #0f5f4b;
  background: #fff;
}
.theme-lab-green .kip-button-group__radio[for=theme_theme-sunset] {
  color: #b24525;
  background: #fff;
}
.kip-button-group .disabled {
  pointer-events: none;
}
.kip-button-group .active,
.kip-button-group input:checked + label {
  background: #cce7ff;
  border: 1px solid #00549f;
  color: #00549f;
  box-shadow: 0 0 0 0.2rem #ccddec;
}
.kip-button-group .active[for=theme_theme-aurora],
.kip-button-group input:checked + label[for=theme_theme-aurora] {
  color: #fff;
  background: linear-gradient(358deg, #19837e 0%, #00549f 43.75%, #280684 100%);
}
.kip-button-group .active[for=theme_theme-electric-storm],
.kip-button-group input:checked + label[for=theme_theme-electric-storm] {
  color: #fff;
  background: linear-gradient(178deg, #6f3ae1 2.88%, #620c90 98.14%);
}
.kip-button-group .active[for=theme_theme-lab-green],
.kip-button-group input:checked + label[for=theme_theme-lab-green] {
  color: #fff;
  background: linear-gradient(21deg, #0f5f4b 0%, #088667 74.48%, #00bb8d 99.48%);
}
.kip-button-group .active[for=theme_theme-sunset],
.kip-button-group input:checked + label[for=theme_theme-sunset] {
  color: #fff;
  background: linear-gradient(178deg, #aa53b8 2.88%, #c25535 100%);
}
.theme-aurora .kip-button-group .active,
.theme-aurora .kip-button-group input:checked + label {
  border: 0;
}
.theme-sunset .kip-button-group .active,
.theme-sunset .kip-button-group input:checked + label {
  border: 0;
}
.theme-electric-storm .kip-button-group .active,
.theme-electric-storm .kip-button-group input:checked + label {
  border: 0;
}
.theme-lab-green .kip-button-group .active,
.theme-lab-green .kip-button-group input:checked + label {
  border: 0;
}

select.form-control, select.kip-stripe {
  height: auto;
  color: #424242;
  appearance: none;
  font-size: inherit;
  background-image: url("data:image/svg+xml,%3Csvg role='img' aria-hidden='true' focusable='false' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23424242' d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'%3E%3C/path%3E%3C/svg%3E");
  background-position: right 15px center;
  background-repeat: no-repeat;
  background-size: 1em 0.875em;
  padding-right: 2.5em;
}
select.form-control:focus, select.kip-stripe:focus {
  color: #424242;
}

.fc-theme-kip-bootstrap a:not([href]) {
  color: inherit;
  text-decoration: inherit;
}
.fc-theme-kip-bootstrap .fc-list, .fc-theme-kip-bootstrap .fc-scrollgrid, .fc-theme-kip-bootstrap td, .fc-theme-kip-bootstrap th {
  border: 1px solid var(--bs-gray-400);
}
.fc-theme-kip-bootstrap .fc-scrollgrid {
  border-right-width: 0;
  border-bottom-width: 0;
}

.fc-theme-kip-bootstrap-shaded {
  background-color: var(--bs-gray-200);
}

:root {
  --bs-dark-rgb: 50, 50, 50;
}

.image-uploader-body .image-uploader-drop-area {
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #dfdfdf;
  border-radius: 20px;
  cursor: pointer;
}
.image-uploader-body .image-uploader-drop-area .label-title {
  max-width: 330px;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 20.25px;
  line-height: 125%;
  letter-spacing: 0.25px;
  color: #00549f;
  cursor: pointer;
}
.image-uploader-body .available-file-info {
  margin: 16px 0 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 12.642px;
  line-height: 150%;
  letter-spacing: 0.4px;
  color: #505d68;
}
.image-uploader-body.tutor-uploader .image-uploader-drop-area {
  background-color: #e3f5fc;
  border-color: #00549f;
}

.image-uploader-drop-area-small {
  border: 2px dashed #ccc;
  border-radius: 10px;
  margin: 10px auto;
  padding: 10px;
  overflow-y: auto;
}

.image-uploader-drop-area.highlight,
.image-uploader-drop-area-small.highlight {
  border: 1px solid #3889d4;
  box-shadow: 0 0 0 0.2rem rgba(0, 84, 159, 0.25);
}

.image-uploader-form {
  margin-bottom: 10px;
}

.image-uploader-gallery {
  margin-top: 10px;
}

.image-uploader-problems {
  margin-top: 10px;
}

.image-uploader-gallery img {
  width: 150px;
  margin-bottom: 10px;
  margin-right: 10px;
  vertical-align: middle;
}

.image-uploader-file {
  display: none;
}

.image-uploader-progress {
  margin: 10px;
}

.image-selected {
  border-width: 3px;
  border-color: red;
  border-style: solid;
}

.kip-info, .kip-info--auto {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.kip-info__heading {
  color: #00549f;
  font-size: 0.79rem;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 0.889rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}
.kip-info__entry {
  font-style: normal;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.0025em;
  line-height: 1.125rem;
  color: #505d68;
  margin-bottom: 0.5rem;
}
.kip-info__subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 0.79rem;
  letter-spacing: 0.0025em;
  line-height: 0.875rem;
}
.kip-messages {
  background-color: #fff;
}
.kip-messages .kip-send {
  border-top: 1px solid #dfdfdf;
}
.kip-messages .kip-send .kip-input {
  border: 0;
  padding: 0;
}
.kip-messages .kip-send .kip-selector {
  width: 15px;
}
.kip-messages .kip-send .kip-toggle::after {
  display: none;
}
.kip-messages .kip-send .kip-icon {
  cursor: pointer;
  color: #00549f;
}
.kip-messages .kip-send .kip-icon.kip-recipients {
  color: #797979;
}
.kip-messages .kip-messages-inner {
  position: absolute;
  inset: 0;
  overflow-y: auto;
}
.kip-messages .kip-messages-inner .kip-message {
  border-bottom: 1px solid #dfdfdf;
  font-size: 0.9rem;
}
.kip-messages .kip-messages-inner .kip-message .kip-icon {
  border-right: 1px solid #dfdfdf;
  min-width: 32px;
}
.kip-messages .kip-messages-inner .kip-message.kip-chat {
  background-color: #fff4d3;
  font-weight: 600;
}

.kip-chat-box {
  position: relative;
}
.kip-chat-box__log {
  min-height: 30vh;
  height: 100%;
}
.kip-chat-box__log-overflow {
  overflow-y: auto;
  min-height: 0;
}
.kip-chat-box__shadow {
  height: 10px;
  width: 100%;
}
.kip-chat-box__shadow--top {
  position: sticky;
  top: 0;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(96, 97, 112, 0.16) 100%);
}
.kip-chat-box__shadow--bottom {
  position: absolute;
  top: 0;
  margin-top: -10px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(96, 97, 112, 0.16) 100%);
}

.kip-chat-bubble {
  padding: 0.75rem;
  padding-top: 0.5rem;
  background-color: #e3f5fc;
  border-radius: 1rem;
  margin: 1rem 2rem;
  line-height: 1.25em;
  word-break: break-word;
  max-width: 35rem;
  width: calc(100% - 2rem);
}
.kip-chat-bubble + .kip-chat-bubble {
  margin-top: 0;
}
.kip-chat-bubble--sent {
  margin-right: 0.5rem;
  border-bottom-right-radius: 0;
  background-color: #e3f5fc;
  margin-left: auto;
}
.theme-aurora .kip-chat-bubble--sent {
  color: #00549f;
  background-color: #cce7ff;
}
.theme-sunset .kip-chat-bubble--sent {
  color: #b24525;
  background-color: #fde2da;
}
.theme-electric-storm .kip-chat-bubble--sent {
  color: #620c90;
  background-color: #ebd1f9;
}
.theme-lab-green .kip-chat-bubble--sent {
  color: #0f5f4b;
  background-color: #cdf9ee;
}
.kip-chat-bubble--received {
  margin-left: 0.5rem;
  border-bottom-left-radius: 0;
  background-color: #f6f6f6;
  margin-right: auto;
}
.theme-aurora .kip-chat-bubble--received {
  color: #00549f;
  background-color: #f2f9ff;
}
.theme-sunset .kip-chat-bubble--received {
  color: #b24525;
  background-color: #fef7f5;
}
.theme-electric-storm .kip-chat-bubble--received {
  color: #620c90;
  background-color: #faf3fd;
}
.theme-lab-green .kip-chat-bubble--received {
  color: #0f5f4b;
  background-color: #f2fdfb;
}
.kip-chat-bubble--success {
  background-color: #d3f8df;
  color: #177060;
}
.theme-aurora .kip-chat-bubble--success {
  background-color: #d3f8df;
  color: #177060;
}
.theme-sunset .kip-chat-bubble--success {
  background-color: #d3f8df;
  color: #177060;
}
.theme-electric-storm .kip-chat-bubble--success {
  background-color: #d3f8df;
  color: #177060;
}
.theme-lab-green .kip-chat-bubble--success {
  background-color: #d3f8df;
  color: #177060;
}
.kip-chat-bubble--danger,
.kip-chat-bubble--danger .text-primary, .kip-chat-bubble:has(.text-danger),
.kip-chat-bubble:has(.text-danger) .text-primary {
  background-color: #fae4d8;
  color: #a03535 !important;
}
.kip-chat-bubble .emoji-mart-emoji {
  display: inline-flex;
  width: 33px;
  justify-content: center;
}

.kip-chat-notification {
  border-top: 1px solid var(--bs-border-color-translucent);
  border-bottom: 1px solid var(--bs-border-color-translucent);
  line-height: 1;
}
.kip-chat-notification:only-child, .kip-chat-notification:last-of-type {
  border-bottom: 0;
}
.kip-chat-notification + .kip-chat-notification {
  border-top: 0;
}
.kip-chat-notification + .kip-chat-notification:last-of-type {
  border-bottom: 0;
}
.kip-chat-notification--blue {
  color: #257bb9;
}

.kip-chat-input {
  width: 100%;
  color: #505d68;
}
.kip-chat-input:focus {
  outline: 0;
  box-shadow: inset 0 0 0 0.2rem rgba(0, 84, 159, 0.25);
}
.kip-chat-input__send-button {
  --bs-btn-hover-color: #fff;
  --bs-btn-active-color: #fff;
  height: 50%;
  border: 0;
  border-left: 1px solid var(--bs-border-color-translucent);
  border-radius: 0;
  color: #fff;
  background: linear-gradient(358deg, #19837e 0%, #00549f 43.75%, #280684 100%);
}
.kip-chat-input__send-button[disabled], .kip-chat-input__send-button.disabled {
  background: #fff;
  color: #00549f;
  opacity: 1;
}
.theme-aurora .kip-chat-input__send-button {
  background: linear-gradient(358deg, #19837e 0%, #00549f 43.75%, #280684 100%);
  color: #fff;
  border-radius: 0 0 20px;
}
.theme-aurora .kip-chat-input__send-button[disabled], .theme-aurora .kip-chat-input__send-button.disabled {
  background: #fff;
  color: #00549f;
  opacity: 1;
}
.theme-sunset .kip-chat-input__send-button {
  background: linear-gradient(178deg, #aa53b8 2.88%, #c25535 100%);
  color: #fff;
  border-radius: 0 0 20px;
}
.theme-sunset .kip-chat-input__send-button[disabled], .theme-sunset .kip-chat-input__send-button.disabled {
  background: #fff;
  color: #b24525;
  opacity: 1;
}
.theme-electric-storm .kip-chat-input__send-button {
  background: linear-gradient(178deg, #6f3ae1 2.88%, #620c90 98.14%);
  color: #fff;
  border-radius: 0 0 20px;
}
.theme-electric-storm .kip-chat-input__send-button[disabled], .theme-electric-storm .kip-chat-input__send-button.disabled {
  background: #fff;
  color: #620c90;
  opacity: 1;
}
.theme-lab-green .kip-chat-input__send-button {
  background: linear-gradient(21deg, #0f5f4b 0%, #088667 74.48%, #00bb8d 99.48%);
  color: #fff;
  border-radius: 0 0 20px;
}
.theme-lab-green .kip-chat-input__send-button[disabled], .theme-lab-green .kip-chat-input__send-button.disabled {
  background: #fff;
  color: #0f5f4b;
  opacity: 1;
}
.kip-chat-input__send-button:hover {
  background-color: #e3f5fc;
}
.kip-chat-input__emoji-toggle {
  height: 50%;
  --bs-btn-hover-bg: #e3f5fc;
}
.kip-chat-input__emoji-toggle:hover {
  background-color: #e3f5fc;
}
.theme-aurora .kip-chat-input__emoji-toggle:hover {
  --bs-btn-hover-bg: #e6f3ff;
  background-color: #e6f3ff;
}
.theme-sunset .kip-chat-input__emoji-toggle:hover {
  --bs-btn-hover-bg: #fff1ed;
  background-color: #fff1ed;
}
.theme-electric-storm .kip-chat-input__emoji-toggle:hover {
  --bs-btn-hover-bg: #f5e8fc;
  background-color: #f5e8fc;
}
.theme-lab-green .kip-chat-input__emoji-toggle:hover {
  --bs-btn-hover-bg: #e6fdf7;
  background-color: #e6fdf7;
}
.kip-chat-input__recipients {
  max-width: 55px;
}
.kip-chat-input__recipients span {
  text-overflow: clip;
  overflow: hidden;
  display: inline-block;
  width: 37px;
}
.kip-chat-input__recipients.dropup .dropdown-toggle::after {
  position: absolute;
  top: 11px;
  right: 3px;
}
.kip-chat-input__emoji-picker {
  position: absolute;
  bottom: 5em;
  left: 0;
  z-index: 1;
}

.kip-chat-ai-help {
  border: 0;
  border-bottom: 1px solid #e6e6e6;
  background-color: #fff;
}

.kip-chat-ai-help:hover {
  background-color: #e6f3ff;
}

.kip-monty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 2rem;
}
.kip-monty__asset {
  display: flex;
  height: 12rem;
  width: 100%;
  margin-bottom: 2rem;
}
@media (min-width: 992px) {
  .kip-monty__asset {
    height: 15em;
  }
}
.kip-monty__headline {
  font-size: 1.424rem;
  font-weight: 300;
  margin-bottom: 0.75rem;
}
.kip-monty__text {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.kip-note {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  transition: all 0.15s linear;
  border: 1px solid transparent;
}
.kip-note--timeline:hover {
  color: #00549f;
  background: #ecf5ff;
  cursor: pointer;
}
.kip-note--pinned {
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  padding: 1rem;
  height: 100%;
}
.kip-note--pinned:hover {
  border: 1px solid #3889D4;
  box-shadow: 0 2px 4px rgba(56, 137, 212, 0.25);
  cursor: pointer;
  transition: border 0.12s ease, box-shadow 0.12s ease;
}
.kip-note__icon {
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 50%;
}
.kip-note__icon--pinned {
  border: 1px solid #e7e7e7;
  height: 2.5rem;
  width: 2.5rem;
}
.kip-note__user {
  align-items: center;
  color: #505d68;
  display: flex;
  font-size: 0.878rem;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.005rem;
  text-align: right;
}
.kip-note__category {
  color: #505d68;
  font-size: 0.79rem;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 0.889rem;
  text-transform: uppercase;
}
.kip-note__bubble {
  position: relative;
}
.kip-note__bubble::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  box-sizing: border-box;
  border: 10px solid #000;
  border-color: transparent transparent #f6f6f6 #f6f6f6;
  transform-origin: 0 0;
  transform: rotate(135deg);
  top: 0;
  left: 3rem;
}

.kip-note-timeline {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}
.kip-note-timeline__description {
  color: #505d68;
  font-size: 13.56px;
  line-height: 18px;
  width: 100%;
  background-color: #f6f6f6;
  border-radius: 4px;
  padding: 1em;
  transition: background-color 0.15s;
  margin-bottom: 1em;
}
.kip-note-timeline__description:hover {
  cursor: pointer;
  background-color: #e3f5fc;
  border: 0;
}
.kip-note-timeline__description:not(:last-child) {
  margin-right: 1em;
}
.kip-note-timeline__description--activity {
  background: none;
  margin-bottom: 0;
}
.kip-note-timeline__description--activity:not(:last-child) {
  border-bottom: 1px solid #e7e7e7;
}
.kip-note-timeline__notes--system .kip-note-timeline__description {
  background-color: #fff;
}
.kip-note-timeline__notes--system .kip-note-timeline__description:hover {
  cursor: pointer;
  background-color: #e3f5fc;
  border: 0;
}
.kip-note-timeline__notes {
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  padding: 1em 1em 0;
  margin-bottom: 1em;
  display: flex;
  position: relative;
  background-color: #fff;
}
.kip-note-timeline__notes--system {
  border: 1px solid transparent;
  padding: 0;
  background-color: transparent;
}
.kip-note-timeline__entry {
  position: relative;
}
.kip-note-timeline__entry:not(:last-child)::before {
  background-color: #e8e8e8;
  bottom: -1rem;
  top: 1rem;
  content: "";
  margin-left: 10rem;
  position: absolute;
  width: 1px;
}
.kip-note-timeline__event {
  background-color: #fff;
  position: relative;
  flex: 1;
}
.kip-note-timeline__event-type {
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 16px;
  text-transform: uppercase;
}
.kip-note-timeline__date {
  min-width: 7.5rem;
}
.kip-note-timeline__accordion {
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.kip-note-timeline__accordion--hover {
  color: #797979;
  transition: color 0.15s;
}
.kip-note-timeline__accordion:hover .kip-note-timeline__accordion--hover {
  color: #00549f;
}
.kip-note-timeline__icon-wrapper {
  margin-left: 0.5em;
  margin-top: 0.5rem;
}
.kip-note-timeline__icon {
  align-items: center;
  background-color: #fff;
  display: inline-flex;
  height: 2rem;
  margin-top: -0.25em;
  justify-content: center;
  line-height: 100%;
  padding: 0;
  position: relative;
  width: 2rem;
}
.kip-note-timeline__icon--border {
  border-radius: 50%;
  border: 1px solid #e8e8e8;
}

.kip-skin-tone-picker svg {
  width: 30px;
}

.kip-table.table:not(first-child) {
  border-top: 0;
}
.kip-table thead {
  border-top: 0;
}
.kip-table thead th {
  border-bottom: 1px solid #dee2e6;
  color: #00549f;
  font-weight: 400;
  padding: 1rem 0.75rem;
}
.kip-table tbody {
  border-top: 0;
}
.kip-table tbody td {
  border-bottom: 0;
  border-top: 0;
  color: #505d68;
  font-weight: 600;
  padding: 1rem 0.75rem;
}
.kip-table__cell--choice {
  width: 1rem;
}

/* stylelint-disable custom-property-pattern */
.table-striped {
  --bs-table-striped-bg: #f9f9f9;
}

.table-no-stripes {
  --bs-table-striped-bg: none;
  ----bs-table-bg: none;
}

.table-primary {
  --bs-table-striped-bg: #e3f5fc;
  --bs-table-bg: #e3f5fc;
}

.table-info {
  --bs-table-striped-bg: #e3fbf2;
  --bs-table-bg: #e3fbf2;
}

.table-danger {
  --bs-table-striped-bg: #fcf1eb;
  --bs-table-bg: #fcf1eb;
}

.table-warning {
  --bs-table-striped-bg: #fbecc9;
  --bs-table-bg: #fbecc9;
}

.table-success {
  --bs-table-striped-bg: #d3f8df;
  --bs-table-bg: #d3f8df;
}

.table-danger,
.table-warning,
.table-success,
.table-light {
  --bs-table-border-color: inherit;
}

.table-white {
  --bs-table-striped-bg: #fff;
  --bs-table-bg: #fff;
}

.table-active {
  --bs-table-striped-bg: #ecf5ff;
  --bs-table-bg: #ecf5ff;
  --bs-table-active-bg: none;
}

.kip-session-list__session {
  border-radius: 0.625rem;
  padding: 1rem;
  transition: border 0.1s ease-in, box-shadow 0.1s ease-in;
}
.kip-session-list__session--selected {
  border-color: #3889d4;
}
.kip-session-list__session:focus-within {
  border: 1px solid #3889d4;
  box-shadow: 0 0 0 0.2rem rgba(0, 84, 159, 0.25);
}
.kip-session-list__session:hover {
  border: 1px solid #3889D4;
  box-shadow: 0 2px 4px rgba(56, 137, 212, 0.25);
  cursor: pointer;
  transition: border 0.12s ease, box-shadow 0.12s ease;
}
.kip-session-list__session-header {
  align-items: center;
  display: flex;
  height: 100%;
  margin-left: -1rem;
}
.kip-session-list__who-and-where {
  align-items: center;
  display: flex;
  justify-content: space-around;
}
@media (min-width: 992px) {
  .kip-session-list__who-and-where {
    align-items: flex-start;
    flex-direction: column;
  }
}
.kip-session-list__spot-list {
  margin-left: 0.5rem;
  margin-right: -0.3125rem;
}
.kip-session-list__spot-wrapper {
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
  margin-top: 0.1875rem;
  margin-bottom: 0.1875rem;
}
.kip-session-list__spot {
  height: 100%;
  width: 100%;
  text-align: center;
  background-color: rgba(246, 246, 246, 0.5);
  min-height: 1.5rem;
}
.kip-session-list__spot--empty {
  border: 1px dashed rgba(80, 93, 104, 0.25);
  border-style: dashed;
}
.kip-session-list__spot--selected {
  background-color: #ecf5ff;
  color: #00549f;
}

.kip-wizard {
  display: flex;
  list-style: none;
  justify-content: space-between;
}
.kip-wizard__button {
  align-items: center;
  background-color: transparent;
  border: 2px solid #cfcfcf;
  border-radius: 50%;
  display: flex;
  height: 1.5rem;
  justify-content: center;
  line-height: 1rem;
  padding: 0;
  width: 1.5rem;
}
.kip-wizard__button:hover {
  background-color: rgb(216.7, 216.7, 216.7);
  border-color: rgb(206.5, 206.5, 206.5);
  box-shadow: 0 4px 4px rgba(232, 232, 232, 0.1);
}
.kip-wizard__button--selected {
  background-color: #5AB0E2;
  border-color: #5AB0E2;
  color: #fff;
}
.kip-wizard__button--selected:hover {
  background-color: rgb(63.9742268041, 163.5391752577, 221.4257731959);
  border-color: rgb(46.6237113402, 155.2319587629, 218.3762886598);
  box-shadow: 0 4px 4px rgba(90, 176, 226, 0.1);
}
.kip-wizard__button--valid {
  background-color: #45AD64;
  border-color: #45AD64;
  color: #fff;
}
.kip-wizard__button--valid:hover {
  background-color: rgb(60.2752066116, 151.1247933884, 87.3553719008);
  border-color: rgb(54.458677686, 136.541322314, 78.9256198347);
  box-shadow: 0 4px 4px rgba(69, 173, 100, 0.1);
}
.kip-wizard__button--error {
  background-color: #A03535;
  border-color: #A03535;
  color: #fff;
}
.kip-wizard__button--error:hover {
  background-color: rgb(137.014084507, 45.385915493, 45.385915493);
  border-color: rgb(121.6901408451, 40.3098591549, 40.3098591549);
  box-shadow: 0 4px 4px rgba(160, 53, 53, 0.1);
}
.kip-wizard__button--warning {
  background-color: #97690D;
  border-color: #97690D;
  color: #fff;
}
.kip-wizard__button--warning:hover {
  background-color: rgb(122.8256097561, 85.4085365854, 10.5743902439);
  border-color: rgb(104.0426829268, 72.3475609756, 8.9573170732);
  box-shadow: 0 4px 4px rgba(151, 105, 13, 0.1);
}
.kip-wizard .kip-wizard__step-container {
  display: flex;
  width: 100%;
  align-items: center;
}
.kip-wizard .kip-wizard__step-container::before, .kip-wizard .kip-wizard__step-container::after {
  content: "";
  flex: 1;
  margin: auto 0;
  height: 0;
  border-top: 2px solid #cfcfcf;
}
.kip-wizard li {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.kip-wizard li:first-of-type .kip-wizard__step-container:before {
  border: 0;
}
.kip-wizard li:last-of-type .kip-wizard__step-container:after {
  border: 0;
}
.kip-wizard li label {
  cursor: pointer;
}

.kip-auth {
  height: 100vh;
}
.kip-auth__login {
  box-shadow: 0 10px 30px #dfdfdf;
}
.kip-auth__scene {
  background-image: url("~/assets/svg/kiplearn_login_gradient.svg");
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
}
.kip-auth__scene.tutorfly {
  background-image: url("~/assets/svg/kiplearn_login_gradient_tutorfly.svg");
}

.kip-changes__entry:not(:first-of-type) {
  margin-top: 4em;
}
.kip-changes h3, .kip-changes .h3,
.kip-changes h4,
.kip-changes .h4 {
  font-weight: bold;
}
.kip-changes h3, .kip-changes .h3 {
  font-size: 2em;
}
.kip-changes h4, .kip-changes .h4 {
  font-size: 1.25em;
  margin: 1.25em 0 0.75em;
}

.kip-whiteboard {
  position: relative;
  height: 100%;
  user-select: none;
}
.kip-whiteboard__pdf-buttons {
  position: absolute;
  z-index: 99;
  background: #fff;
  max-height: 100%;
  box-shadow: 0 0 0 rgba(40, 41, 61, 0.04), 2px 3px 4px rgba(96, 97, 112, 0.16);
  border-bottom-right-radius: 0.35em;
}
.kip-whiteboard__pdf-buttons .kip-scroller {
  cursor: pointer;
  background: #fff;
  line-height: 56px;
  min-width: 2rem;
  display: none;
  height: 56px;
  width: 56px;
  min-height: 56px;
}
.kip-whiteboard__pdf-buttons .kip-scroller:hover {
  background: #cce7ff;
}
.kip-whiteboard__pdf-buttons .kip-scroller:last-child {
  border-bottom-right-radius: 0.35em;
}
.kip-whiteboard__pdf-buttons .kip-items {
  list-style: none;
  overflow: hidden;
  position: relative;
  padding: 0;
}
.kip-whiteboard__pdf-buttons .kip-items--rounded {
  padding-bottom: 5px;
  border-bottom-right-radius: 0.35em;
}
.kip-whiteboard__pdf-buttons .kip-items--rounded li:nth-last-child(2) {
  border-bottom-right-radius: 0.35em;
}
.kip-whiteboard__pdf-buttons .kip-items .kip-anchor {
  display: block;
}
.kip-whiteboard__pdf-buttons .kip-items .kip-item :hover {
  background: #cce7ff;
}
.kip-whiteboard__pdf-buttons .kip-items .kip-item .kip-item-inner {
  cursor: pointer;
  border: 0 solid;
  line-height: 28px;
  min-width: 2rem;
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.kip-whiteboard__pdf-buttons .kip-items .kip-item .kip-item-inner[class*=kip-attempts-] {
  font-weight: 600;
  background-color: #fae4d8;
  color: #a03535;
  border: 0;
}
.kip-whiteboard__pdf-buttons .kip-items .kip-item .kip-item-inner.kip-attempts-ai {
  color: #00549f;
  background-color: rgb(153, 186.6, 216.6);
}
.kip-whiteboard__pdf-buttons .kip-items .kip-item .kip-item-inner.kip-attempts-0 {
  color: #424242;
  border-color: #e8e8e8;
  background-color: #fff;
  font-weight: 400;
}
.kip-whiteboard__pdf-buttons .kip-items .kip-item .kip-item-inner.kip-attempts-0:hover {
  border-color: #00549f;
}
.kip-whiteboard__pdf-buttons .kip-items .kip-item .kip-item-inner.kip-attempts-1 {
  border-color: #dcb453;
  color: #b24525;
  background-color: #fbecc9;
}
.kip-whiteboard__pdf-buttons.kip-scrollable .kip-scroller {
  display: block;
}
.kip-whiteboard__pdf-buttons.kip-scrollable .kip-items {
  margin: 0;
}
.kip-whiteboard__sound-player {
  position: absolute;
  bottom: 0;
  left: calc(50% - 215px);
  z-index: 21;
}
.kip-whiteboard .kip-canvas {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.kip-whiteboard .kip-canvas img {
  width: 100%;
  pointer-events: none;
}
.kip-whiteboard .kip-whiteboard-toolbar {
  position: sticky;
  top: 0;
  pointer-events: none;
}
.kip-whiteboard .kip-whiteboard-toolbar-container {
  width: 3.5em;
  overflow: hidden;
  animation: toolbar-pop-in 0.5s;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  pointer-events: all;
  border-top-left-radius: 8px;
  box-shadow: 0 -2px 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
  margin-bottom: -1em;
  padding-bottom: 1em;
}
.kip-whiteboard .kip-whiteboard-toolbar-container.closing {
  transition: all ease-in-out 0.5s;
  padding-top: 1000px;
  opacity: 0;
}
.kip-whiteboard .kip-whiteboard-toolbar-container .text-primary svg {
  fill: #00549f;
}
@keyframes toolbar-pop-in {
  from {
    max-height: 0;
    opacity: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    overflow: hidden;
    box-shadow: none;
  }
  to {
    max-height: 1000px;
    opacity: 1;
    margin-bottom: 0;
    padding-bottom: 0;
    overflow: hidden;
    box-shadow: none;
  }
}
.kip-whiteboard .kip-whiteboard-toolbar .kip-item {
  display: flex;
  align-items: center;
  height: 44px;
}
.kip-whiteboard .kip-whiteboard-toolbar .kip-item svg {
  height: 24px;
  width: 24px;
  fill: #00549f;
}
.kip-whiteboard .kip-whiteboard-toolbar .kip-item svg [stroke=black] {
  stroke: #00549f;
  stroke-width: 2px;
}
.theme-aurora .kip-whiteboard .kip-whiteboard-toolbar .kip-item svg {
  fill: #00549f;
}
.theme-aurora .kip-whiteboard .kip-whiteboard-toolbar .kip-item svg [stroke=black] {
  stroke: #00549f;
}
.theme-sunset .kip-whiteboard .kip-whiteboard-toolbar .kip-item svg {
  fill: #b24525;
}
.theme-sunset .kip-whiteboard .kip-whiteboard-toolbar .kip-item svg [stroke=black] {
  stroke: #b24525;
}
.theme-electric-storm .kip-whiteboard .kip-whiteboard-toolbar .kip-item svg {
  fill: #620c90;
}
.theme-electric-storm .kip-whiteboard .kip-whiteboard-toolbar .kip-item svg [stroke=black] {
  stroke: #620c90;
}
.theme-lab-green .kip-whiteboard .kip-whiteboard-toolbar .kip-item svg {
  fill: #0f5f4b;
}
.theme-lab-green .kip-whiteboard .kip-whiteboard-toolbar .kip-item svg [stroke=black] {
  stroke: #0f5f4b;
}
.kip-whiteboard .kip-whiteboard-toolbar .kip-tool-item {
  position: relative;
}
.kip-whiteboard .kip-whiteboard-toolbar .kip-tool-item .kip-options {
  background-color: #fff;
  position: absolute;
  left: -4.75em;
}
.kip-whiteboard .kip-whiteboard-toolbar .kip-tool-item .kip-options .kip-option {
  margin: 0 1px;
}
.kip-whiteboard .kip-whiteboard-toolbar .kip-tool-item .kip-options .kip-option:hover {
  background-color: #dfdfdf;
}
.kip-whiteboard .kip-whiteboard-toolbar .kip-tool-item .kip-options .kip-option.kip-selected {
  color: #fff;
  background-color: #00549f;
}

@media screen {
  body > div.window-printing-container {
    display: none;
  }
}
@media print {
  body {
    position: relative !important;
  }
  body.kip-whiteboard-printing {
    overflow: visible;
    background-color: #fff;
  }
  body.kip-whiteboard-printing > kip-root {
    display: none;
  }
  body > div.window-printing-container {
    display: block;
  }
  body > div.window-printing-container > img {
    position: absolute;
  }
}
.kip-whiteboard-printing__page-wrapper, .kip-whiteboard-printing__pdf {
  position: relative;
  width: 100%;
}
.kip-whiteboard-printing__canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.kip-whiteboard-printing__title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 11px;
  font-weight: 700;
  text-align: center;
}

.kip-whiteboard-container {
  position: absolute;
  inset: 0;
  z-index: 10;
  height: 100%;
  width: 100%;
}
.kip-whiteboard-container .kip-canvas {
  overflow: auto;
  overflow-x: hidden;
}
.kip-whiteboard-container .kip-whiteboard-toolbar {
  position: absolute;
  bottom: 1.25em;
}
.kip-whiteboard-container--scrolling-mode .canvas-container {
  pointer-events: none;
  cursor: grab;
}
.kip-whiteboard-container .kip-whiteboard-close {
  color: #a03535;
  background-color: #fff;
}
.kip-whiteboard-container .kip-whiteboard-close:active, .kip-whiteboard-container .kip-whiteboard-close.active {
  border-color: #00549f;
  z-index: 20;
  background: #fae4d8;
}
.kip-whiteboard-container .kip-whiteboard-close:hover {
  background: #f5c4b3;
  cursor: pointer;
}
.kip-whiteboard-container--manual .kip-canvas img {
  padding: 0 5%;
}

.kip-current-account {
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  display: flex;
  min-height: 12.5rem;
}
.kip-current-account--is-a-choice {
  flex-direction: column;
}
.kip-current-account--is-a-choice:hover {
  border: 1px solid #3889d4;
  box-shadow: 0 0 0 0.2rem rgba(0, 84, 159, 0.25);
}
.kip-current-account--is-selected {
  border: 1px solid #80a9cf;
}
.kip-current-account__name {
  color: #505d68;
  line-height: 30px;
  font-size: 1.383rem;
  font-weight: 300;
}
.kip-current-account__summary {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-around;
}
.kip-current-account__section {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
.kip-current-account__data-group {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
}
.kip-current-account__selector {
  align-items: center;
  display: flex;
  justify-content: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid #e7e7e7;
}
.kip-current-account__selector--is-selected {
  background-color: #f5faff;
  border-top: 1px solid #80a9cf;
}
.kip-current-account__select {
  font-weight: 600;
  font-size: 13.56px;
  line-height: 18px;
  letter-spacing: 0.1em;
  color: #00549f;
  opacity: 0.8;
  min-height: 48px;
}
.kip-current-account__list-container {
  list-style: none;
  padding: 0;
}
.kip-current-account hr.kip-divider {
  width: 62%;
  border-color: #e7e7e7;
  margin: 0;
}

ol.kip-breadcrumbs {
  align-items: center;
  display: flex;
  list-style: none;
  height: 1rem;
  margin: 0;
  padding-left: 0;
  line-height: auto;
}
ol.kip-breadcrumbs li {
  display: inline;
}
ol.kip-breadcrumbs li + li::before {
  color: var(--bs-secondary-color);
  content: "/";
  margin-right: 0.1618rem;
  white-space: nowrap;
}
ol.kip-breadcrumbs a,
ol.kip-breadcrumbs fa-icon {
  color: var(--bs-secondary-color);
  margin-right: 0.1618rem;
}

.kip-day-finder {
  align-items: center;
  background-color: white;
  display: flex;
  height: calc(2 * 3rem);
  max-height: calc(2 * 3rem);
  padding: 0.5rem;
}
.kip-day-finder__day-picker {
  align-items: center;
  display: flex;
  justify-content: center;
}
@media (min-width: 1200px) {
  .kip-day-finder__day-picker {
    width: 15rem;
  }
}
.kip-day-finder__day-picker:hover {
  color: #3187c5;
  cursor: pointer;
}
.kip-day-finder__today-icon {
  height: 2rem;
  user-select: none;
}
.kip-day-finder__today-icon:hover {
  cursor: pointer;
}
.kip-day-finder__browse {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
}
.kip-day-finder__label {
  font-size: 0.878rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 0.889rem;
  text-transform: uppercase;
}
.kip-day-finder__entry {
  align-items: center;
  display: flex;
  font-size: 1.266rem;
  font-weight: 500;
  height: 2.6rem;
  justify-content: center;
  width: 2.6rem;
}
.kip-day-finder__entry--circle {
  border-radius: 50%;
}
.kip-day-finder__entry--circle:hover {
  background-color: #f3f3f1;
  cursor: pointer;
}
.kip-day-finder__entry--is-today {
  color: #3187c5;
  border: 1px solid #3187c5;
}
.kip-day-finder__entry--is-today:hover {
  background-color: #3187c5;
  cursor: pointer;
  border: 1px solid #3187c5;
  color: white;
}
.kip-day-finder__entry--is-selected {
  background-color: #3187c5;
  cursor: pointer;
  color: white;
}
.kip-day-finder__entry--is-selected:hover {
  background-color: rgb(43.9207317073, 121.006097561, 176.5792682927);
  cursor: pointer;
}

.kip-history {
  color: #505d68;
}
.kip-history .kip-section-toggle {
  cursor: pointer;
}
.kip-history .kip-section-toggle:hover {
  background-color: #f8f8f8;
}

.history-list__time-spent {
  width: 30%;
}
@media (min-width: 992px) {
  .history-list__time-spent {
    width: 7em;
  }
}
.history-list__activity-attempted {
  width: 30%;
}
@media (min-width: 992px) {
  .history-list__activity-attempted {
    width: 7em;
  }
}
.history-list__percentage {
  width: 3.5em;
}
.history-list__result-indicator--success {
  border-left: 0.3em solid rgba(23, 112, 96, 0.5);
}
.history-list__result-indicator--warning {
  border-left: 0.3em solid rgba(158, 71, 0, 0.5);
}
.history-list__result-indicator--danger {
  border-left: 0.3em solid rgba(160, 53, 53, 0.5);
}
.history-list__text-result-indicator--success {
  color: #177060;
}
.history-list__text-result-indicator--warning {
  color: #9e4700;
}
.history-list__text-result-indicator--danger {
  color: #a03535;
}

.kip-tabs-container {
  padding: 0;
}
.kip-tabs-container .nav-tabs {
  background-color: #f1f1f1;
  border: 0;
}
.kip-tabs-container .nav-link.active {
  border: 0;
  color: #00549f;
}
.kip-tabs-container .nav-tabs .nav-item {
  margin: 0;
}
.kip-tabs-container .nav-tabs .nav-link:hover,
.kip-tabs-container .nav-tabs .nav-link:focus {
  border: 0;
}
.kip-tabs-container li.nav-item a {
  color: #00549f;
  font-size: 0.79rem;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 0.889rem;
  text-transform: uppercase;
  color: grey;
  border: 0;
  padding: 1rem 0.5rem;
  text-decoration: none;
}
.kip-tabs-container li.nav-item a:not(.active) {
  color: #505D68;
  font-size: 0.79rem;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 0.889rem;
  text-transform: uppercase;
  box-shadow: 2px 0 4px rgba(212, 212, 212, 0.25);
}
.kip-tabs-container li.nav-item a:not(.active):hover {
  background-color: rgba(80, 93, 104, 0.1);
  color: rgb(35.652173913, 41.4456521739, 46.347826087);
}

.kip-current-bundle {
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  display: flex;
  cursor: pointer;
  min-height: 12.5rem;
}
.kip-current-bundle:hover {
  border: 1px solid #3889d4;
  box-shadow: 0 2px 4px rgba(56, 137, 212, 0.25);
}
.kip-current-bundle--is-a-choice {
  flex-direction: column;
}
.kip-current-bundle--is-selected {
  border: 1px solid #3889d4;
}
.kip-current-bundle__name {
  color: #505d68;
  line-height: 30px;
  font-size: 1.383rem;
  font-weight: 300;
}
.kip-current-bundle__summary {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-around;
}
.kip-current-bundle__terms {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.kip-current-bundle__section {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
@media (max-width: 991.98px) {
  .kip-current-bundle__section.border-right {
    border-right: none !important;
  }
}
.kip-current-bundle__enrolment {
  font-size: 0.889em;
  color: #505d68;
  margin-bottom: 0.5rem;
}
.kip-current-bundle__enrolment:last-of-type {
  margin-bottom: 0;
}
.kip-current-bundle__data-group {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
}
.kip-current-bundle__key {
  font-style: normal;
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: #505d68;
}
.kip-current-bundle__value {
  color: rgba(0, 84, 159, 0.8);
  font-style: normal;
  font-weight: 300;
  font-size: 1.602rem;
}
@media (min-width: 1600px) {
  .kip-current-bundle__value {
    font-size: 1.802rem;
  }
}
.kip-current-bundle__selector {
  align-items: center;
  display: flex;
  justify-content: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid #e7e7e7;
}
.kip-current-bundle__selector--is-selected {
  background-color: #f5faff;
  border-top: 1px solid #80a9cf;
}
.kip-current-bundle__select {
  font-weight: 600;
  font-size: 13.56px;
  line-height: 18px;
  letter-spacing: 0.1em;
  color: #00549f;
  opacity: 0.8;
  min-height: 48px;
}
.kip-current-bundle__list-container {
  list-style: none;
  padding: 0;
}
.kip-current-bundle hr.kip-divider {
  width: 62%;
  border-color: #e7e7e7;
  margin: 0;
}

.kip-contact {
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 12.5rem;
  padding: 1rem;
  transition: all 0.15s linear;
}
.kip-contact:hover {
  border: 1px solid #3889d4;
  box-shadow: 0 2px 4px rgba(56, 137, 212, 0.25);
}
.kip-contact__btn {
  align-self: start;
  font-size: 0.79rem;
  font-weight: 600;
}
.kip-contact__text--add {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
}
.kip-contact--add {
  border: 1px solid #ccc;
  border-radius: 10px;
  transition: all 0.2s linear;
  align-items: center;
  background: #fefefe;
  border-style: dashed;
  color: #00549f;
  justify-content: center;
  width: 100%;
}
.kip-contact--add:hover {
  border: 1px solid #3889d4;
  box-shadow: 0 2px 4px rgba(56, 137, 212, 0.25);
}

.kip-search__results {
  min-width: 33vw;
}
.kip-search__input {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0.125rem 0.5rem;
  color: white;
}
.kip-search__input:focus {
  border: 1px solid rgb(255, 255, 255);
}
.kip-search__header {
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  font-size: 1.266rem;
  font-weight: 300;
}
.kip-search__clear {
  background-color: transparent;
  border-color: transparent;
  color: #fff;
}

.kip-payment-types {
  display: flex;
  width: 100%;
}
.kip-payment-types :first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.kip-payment-types :last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.kip-payment-types__option {
  align-items: center;
  background: #fff;
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  color: #505d68;
  display: flex;
  flex: 1;
  font-weight: 600;
  font-size: 0.89rem;
  height: 3rem;
  justify-content: center;
  letter-spacing: 0.1em;
  line-height: 1rem;
  text-align: center;
}
.kip-payment-types__option--selected {
  background: #f5faff;
  border: 1px solid #3889d4;
  border-radius: 4px 0 0 4px;
  box-sizing: border-box;
  color: #00549f;
}

.kip-stripe {
  height: 3rem;
  padding-top: 0.75rem;
}

.StripeElement {
  height: 100%;
}

.StripeElement--invalid {
  border-color: rgb(193.7887323944, 70.2112676056, 70.2112676056);
}
.StripeElement--invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(228, 96, 109, 0.25);
}

.xero-logo {
  height: 2.5rem;
}

.kip-document {
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
  transition: all 0.15s linear;
}
.kip-document:hover {
  border: 1px solid #3889d4;
  box-shadow: 0 2px 4px rgba(56, 137, 212, 0.25);
}
.kip-document__btn {
  align-self: start;
  font-size: 0.79rem;
  font-weight: 600;
}
.kip-document__text--add {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
}
.kip-document--add {
  border: 1px solid #ccc;
  border-radius: 10px;
  transition: all 0.2s linear;
  align-items: center;
  background: #fefefe;
  border-style: dashed;
  color: #00549f;
  justify-content: center;
  width: 100%;
}
.kip-document--add:hover {
  border: 1px solid #3889d4;
  box-shadow: 0 2px 4px rgba(56, 137, 212, 0.25);
}

.file-uploader-drop-area {
  border: 2px dashed #ccc;
  border-radius: 10px;
  margin: 20px auto;
  padding: 20px;
  height: 400px;
  overflow-y: auto;
}

.file-uploader-drop-area-small {
  border: 2px dashed #ccc;
  border-radius: 10px;
  margin: 10px auto;
  padding: 10px;
  overflow-y: auto;
}

.file-uploader-drop-area-small.highlight {
  border-color: purple;
}

.file-uploader-form {
  margin-bottom: 10px;
}

.file-uploader-gallery {
  margin-top: 10px;
}

.file-uploader-problems {
  margin-top: 10px;
}

.file-uploader-gallery img {
  width: 150px;
  margin-bottom: 10px;
  margin-right: 10px;
  vertical-align: middle;
}

.file-uploader-file {
  display: none;
}

.file-uploader-progress {
  margin: 10px;
}

body {
  color: #505d68;
  background-color: #f6f6f6;
}

.kip-container {
  background-color: #f9f9f9;
  height: calc(100% - 3rem);
}

.kip-container__scrollable {
  -webkit-overflow-scrolling: touch;
}

.page-divider {
  border: 1px solid #ccc;
}

.kip-landmark__header {
  color: #505d68;
  height: calc(2 * 3rem);
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.kip-content-scrollable__parent {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
  margin-right: 0;
  margin-left: 0;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.kip-content-scrollable__parent [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.kip-content-scrollable__body, .kip-content-scrollable__body--fill {
  width: 100%;
  overflow: auto;
}

.kip-content-scrollable__body--fill {
  flex: 1;
}

.kip-content-scrollable__footer {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
  border-top: 1px solid #e7e7e7 !important;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
  margin-top: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media (min-width: 768px) {
  .kip-content-scrollable__footer {
    display: none;
  }
}

kip-student-lesson-history .kip-explore-computer-container {
  min-height: 1000px;
}

.kip-sidebar {
  height: 100%;
  overflow: auto;
  background-color: #fff;
  border-right: 1px solid #e7e7e7;
  width: 100%;
}
@media (min-width: 768px) {
  .kip-sidebar {
    width: 15rem;
  }
}
.kip-sidebar ul {
  list-style: none;
  padding: 0;
}
.kip-sidebar__group a ~ * {
  display: none;
}
.kip-sidebar__group a.active ~ * {
  background-color: #f8f8f8;
  box-shadow: inset 0.236rem 0 0 0 #00549f;
  display: grid;
}
.kip-sidebar__group a.active {
  background-color: #f8f8f8;
  box-shadow: inset 0.236rem 0 0 0 #00549f;
  text-shadow: 0 0 0 #3c3c3c;
}
.kip-sidebar a {
  display: grid;
  grid-template-areas: "icon text";
  grid-template-columns: 1rem 1fr;
  grid-template-rows: 3rem;
  column-gap: 1.618rem;
  align-items: center;
  padding: 0 1rem;
  color: #3c3c3c;
  text-decoration: none;
}
.kip-sidebar a:hover {
  background-color: #f8f8f8;
  box-shadow: inset 0.236rem 0 0 0 #00549f;
  background-color: rgb(242.9, 242.9, 242.9);
}
.kip-sidebar a.active {
  background-color: #f8f8f8;
  box-shadow: inset 0.236rem 0 0 0 #00549f;
  font-weight: 600;
}
.kip-sidebar a.active:hover {
  background-color: rgb(242.9, 242.9, 242.9);
}
.kip-sidebar__icon {
  grid-area: icon;
}
.kip-sidebar__label {
  grid-area: text;
}

.kip-header {
  background-clip: border-box;
  background-color: var(--bs-body-bg);
  background-position: right;
  background-repeat: no-repeat;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
  display: flex;
  font-size: 0.89rem;
  flex: 1 1 auto;
  flex-direction: column;
  height: calc(2 * 3rem);
  justify-content: space-between;
  max-height: calc(2 * 3rem);
  min-width: 0;
  padding: 1rem 1rem;
  position: relative;
  word-wrap: break-word;
}
.kip-header--no-breadcrumbs {
  justify-content: center;
}
@media (min-width: 576px) {
  .kip-header {
    background-image: url("~/assets/svg/header.svg");
  }
}

.kip-navbar-logo {
  height: 1.6rem;
  user-select: none;
}

.navbar {
  min-height: 3rem;
}

.navbar .kip-dropdown__menu--top-right::after {
  right: -1rem;
}
@media (min-width: 576px) {
  .navbar .kip-dropdown__menu--top-right::after {
    right: -0.55rem;
  }
}

.kip-tab {
  margin: 1rem;
}
.kip-tab--is-edit {
  display: flex;
  margin: 1rem;
}
.kip-tab__header {
  font-weight: 300;
  margin-bottom: 1rem;
}
.kip-tab__article-list {
  display: block;
}
@media (min-width: 992px) {
  .kip-tab__article-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: calc(0.5rem * -1);
  }
}
.kip-tab__article {
  margin: 0 0 1rem;
  width: 100%;
}
@media (min-width: 992px) {
  .kip-tab__article {
    width: calc(50% - 0);
    width: 50%;
    padding: 0.5rem;
    margin: 0;
  }
}
@media (min-width: 1600px) {
  .kip-tab__article {
    width: calc(33.3333333333% - 0);
    padding: 0.5rem;
  }
}

.kip-summary {
  display: block;
}
@media (min-width: 992px) {
  .kip-summary {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.kip-summary__col {
  margin: 0 0 1rem;
  width: 100%;
}
@media (min-width: 992px) {
  .kip-summary__col--1\/2 {
    width: calc(50% - 0.5rem);
    margin: 0;
  }
}
@media (min-width: 1200px) {
  .kip-summary__col--1\/2-xl {
    width: calc(50% - 0.5rem);
    margin: 0;
  }
}
@media (min-width: 992px) {
  .kip-summary__col--1\/3 {
    width: calc(33.3333333333% - 0.5rem);
    margin: 0;
  }
}

.kip-pipeline__step {
  font-weight: 600;
  letter-spacing: 0.0025em;
  position: relative;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-bottom: 2em;
  background: rgba(0, 84, 159, 0.15);
  color: #00549f;
}
.kip-pipeline__step::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 3rem;
  height: 0;
  top: 3rem;
  border-top: 1.5rem solid transparent;
  border-bottom: 1.5rem solid transparent;
  border-left: 1.5rem solid rgba(0, 84, 159, 0.15);
  transform: rotate(90deg);
}
@media (min-width: 992px) {
  .kip-pipeline__step::before {
    transform: none;
    top: 0;
    width: 0;
    left: 100%;
  }
}
@media (min-width: 992px) {
  .kip-pipeline__step {
    margin-bottom: auto;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.kip-pipeline__step--enrolled {
  background: rgba(22, 119, 32, 0.15);
  color: #167720;
}
.kip-pipeline__step--enrolled::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 3rem;
  height: 0;
  top: 3rem;
  border-top: 1.5rem solid transparent;
  border-bottom: 1.5rem solid transparent;
  border-left: 1.5rem solid rgba(22, 119, 32, 0.15);
  transform: rotate(90deg);
}
@media (min-width: 992px) {
  .kip-pipeline__step--enrolled::before {
    transform: none;
    top: 0;
    width: 0;
    left: 100%;
  }
}
.kip-pipeline__step--closed {
  background: rgba(80, 93, 104, 0.05);
  color: #505d68;
}
.kip-pipeline__step--closed::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 3rem;
  height: 0;
  top: 3rem;
  border-top: 1.5rem solid transparent;
  border-bottom: 1.5rem solid transparent;
  border-left: 1.5rem solid rgba(80, 93, 104, 0.05);
  transform: rotate(90deg);
}
@media (min-width: 992px) {
  .kip-pipeline__step--closed::before {
    transform: none;
    top: 0;
    width: 0;
    left: 100%;
  }
}
.kip-pipeline__actions {
  display: inline-flex;
  flex: 1;
  flex-direction: row;
  font-weight: 600;
  min-height: 3rem;
}
@media (max-width: 767.98px) {
  .kip-pipeline__actions .btn, .kip-pipeline__actions .kip-footer__button, .kip-pipeline__actions .kip-paginator__button {
    width: 100%;
    border-radius: 0.25rem !important;
    height: 3rem;
  }
  .kip-pipeline__actions .btn:not(:last-of-type), .kip-pipeline__actions .kip-footer__button:not(:last-of-type), .kip-pipeline__actions .kip-paginator__button:not(:last-of-type) {
    margin-bottom: 1em;
  }
}
@media (min-width: 992px) {
  .kip-pipeline__actions {
    padding-left: 3rem;
  }
}
.kip-pipeline__actions > * {
  flex: 1;
  font-weight: 600;
  letter-spacing: 0.0025em;
}
@media (max-width: 575.98px) {
  .kip-pipeline__actions {
    flex-direction: column;
  }
}

.kip-event-timeline {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  position: relative;
}
.kip-event-timeline__description {
  color: #505d68;
  font-size: 13.56px;
  line-height: 18px;
}
.kip-event-timeline__entry {
  margin-bottom: 1rem;
  display: flex;
  position: relative;
}
.kip-event-timeline__entry:not(:last-child)::before {
  background-color: #cfcfcf;
  bottom: -1rem;
  content: "";
  margin-left: 1rem;
  position: absolute;
  top: 2rem;
  width: 1px;
}
.kip-event-timeline__event {
  background-color: #fff;
  position: relative;
  flex: 1;
  margin: 0.25em 0 0 2em;
}
.kip-event-timeline__event-type {
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 16px;
  text-transform: uppercase;
}
.kip-event-timeline__due-date {
  text-align: right;
}
.kip-event-timeline__icon {
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #cfcfcf;
  display: inline-flex;
  height: 2rem;
  justify-content: center;
  line-height: 100%;
  padding: 0;
  width: 2rem;
  z-index: 999;
}

.kip-bundle-wrapper {
  display: block;
}
@media (min-width: 992px) {
  .kip-bundle-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: calc(1rem * -1);
  }
}
.kip-bundle-wrapper__bundle {
  margin: 0 0 0.5rem;
  width: 100%;
}
.kip-bundle-wrapper__bundle:last-of-type {
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .kip-bundle-wrapper__bundle {
    width: calc(50% - 0);
    padding: 0.5rem 1rem;
    margin: 0;
  }
}
@media (min-width: 1200px) {
  .kip-bundle-wrapper__bundle {
    width: calc(33.3333333333% - 0);
    padding: 0.5rem 1rem;
    margin: 0;
  }
}
@media (min-width: 1600px) {
  .kip-bundle-wrapper__bundle {
    width: calc(25% - 0);
    padding: 0.5rem 1rem;
    margin: 0;
  }
}

.kip-account-wrapper {
  display: block;
}
@media (min-width: 992px) {
  .kip-account-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: calc(1rem * -1);
  }
}
.kip-account-wrapper__account {
  margin: 0 0 0.5rem;
  width: 100%;
}
.kip-account-wrapper__account:last-of-type {
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .kip-account-wrapper__account {
    width: calc(50% - 0);
    padding: 0.5rem 1rem;
    margin: 0;
  }
}
@media (min-width: 1200px) {
  .kip-account-wrapper__account {
    width: calc(33.3333333333% - 0);
    padding: 0.5rem 1rem;
    margin: 0;
  }
}
@media (min-width: 1600px) {
  .kip-account-wrapper__account {
    width: calc(25% - 0);
    padding: 0.5rem 1rem;
    margin: 0;
  }
}

.text-holiday {
  color: #bc613c;
}

.text-session {
  color: #459d9a;
}

.text-assessment {
  color: #0d65d8;
}

.kip-calendar-event {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #e7e7e7;
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
  color: #505d68;
  display: flex;
  flex-direction: column;
  transition: all 0.05s linear;
}
.kip-calendar-event--is-selectable {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15);
  position: relative;
}
.kip-calendar-event--is-selectable:hover {
  cursor: pointer;
  background-color: #f9f9f9;
  border: 1px solid rgb(93.1371681416, 183.8628318584, 180.7699115044);
}
.kip-calendar-event__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.kip-calendar-event__menu {
  color: #505d68;
  padding: 0;
}
.kip-calendar-event__body {
  display: flex;
  flex-direction: column;
}
.kip-calendar-event__footer {
  background: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.kip-calendar-event__title {
  font-size: 1.266rem;
}
.kip-calendar-event__label {
  font-size: 0.823rem;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 1rem;
  text-transform: uppercase;
}
.kip-calendar-event__label--holiday {
  color: #bc613c;
}
.kip-calendar-event__label--session {
  color: #459d9a;
}
.kip-calendar-event__label--assessment {
  color: #0d65d8;
}
.kip-calendar-event__attendee {
  color: #505d68;
  font-size: 0.878rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.kip-calendar-event__attendee--session-student {
  background-color: rgba(69, 157, 154, 0.25);
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
}
.kip-calendar-event__attendee--session-student::after {
  color: #459d9a;
}
.kip-calendar-event__attendee--session-student:hover {
  background-color: rgba(69, 157, 154, 0.35);
  cursor: pointer;
}
.kip-calendar-event__attendee--past {
  background-color: rgba(69, 157, 154, 0.1);
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
}
.kip-calendar-event__attendee--past:hover {
  background-color: rgba(69, 157, 154, 0.35);
}
.kip-calendar-event__attendee--forfeit {
  text-decoration: line-through;
}
.kip-calendar-event__attendee--forfeit::after {
  content: "F";
  display: block;
  float: right;
  font-weight: bold;
}
.kip-calendar-event__attendee--forfeit:hover {
  background-color: rgba(69, 157, 154, 0.1);
}
.kip-calendar-event__attendee--deferred {
  text-decoration: line-through;
  background-color: rgba(69, 157, 154, 0.1);
}
.kip-calendar-event__attendee--deferred::after {
  content: "D";
  display: block;
  float: right;
  font-weight: bold;
}
.kip-calendar-event__attendee--deferred:hover {
  background-color: rgba(69, 157, 154, 0.1);
}
.kip-calendar-event__attendee--rescheduled {
  text-decoration: line-through;
  background-color: rgba(69, 157, 154, 0.1);
}
.kip-calendar-event__attendee--rescheduled::after {
  content: "R";
  display: block;
  float: right;
  font-weight: bold;
}
.kip-calendar-event__attendee--rescheduled:hover {
  background-color: rgba(69, 157, 154, 0.1);
}
.kip-calendar-event__attendee--home-makeup {
  background-color: rgba(69, 157, 154, 0.1);
}
.kip-calendar-event__attendee--home-makeup::after {
  content: "H";
  display: block;
  float: right;
  font-weight: bold;
}
.kip-calendar-event__attendee--home-makeup:hover {
  background-color: rgba(69, 157, 154, 0.1);
  cursor: default;
}
.kip-calendar-event__session-student-count {
  align-items: center;
  background-color: #459d9a;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 0.75rem;
  font-weight: 600;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  position: relative;
  width: 1.25rem;
  white-space: nowrap;
}
.kip-calendar-event__add {
  align-items: center;
  background-color: #fefefe;
  border-radius: 4px;
  border: 1px dashed #ccc;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  width: 100%;
}
.kip-calendar-event__add:hover {
  border: 1px solid rgba(13, 101, 216, 0.35);
  text-decoration: none;
}
.kip-calendar-event__add--booked {
  align-items: flex-start;
  border: 1px solid #e7e7e7;
  padding: 0.75rem;
}
.kip-calendar-event__add--booked:hover {
  border: 1px solid #e7e7e7;
  text-decoration: none;
}

.kip-event-list-break {
  flex-basis: 100%;
  height: 0;
}

.kip-event-list {
  padding: 0.5rem;
  display: block;
}
@media (min-width: 992px) {
  .kip-event-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0.25rem;
  }
}
.kip-event-list__event {
  margin: 0 0 0.5rem;
  width: 100%;
}
@media (min-width: 992px) {
  .kip-event-list__event {
    width: calc(50% - 0);
    padding: 0.25rem;
    margin: 0;
  }
}
@media (min-width: 1600px) {
  .kip-event-list__event--session {
    width: calc(33.3333333333% - 0);
    padding: 0.25rem;
  }
}

.kip-calendar__heading {
  position: sticky;
  z-index: 1;
  top: 0;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  font-size: 0.823rem;
  font-weight: 600;
  height: 2rem;
  justify-content: center;
  letter-spacing: 0.02em;
  line-height: 1rem;
  padding: 0.5rem 0;
  text-transform: uppercase;
}

.kip-action-bar {
  align-items: center;
  background-color: rgba(45, 50, 56, 0.95);
  border-radius: 10px;
  bottom: 2rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15);
  display: flex;
  flex-wrap: nowrap;
  flex: 1 1 auto;
  justify-content: center;
  left: 50%;
  margin-top: -3rem;
  max-width: 33vw;
  min-height: 3rem;
  position: sticky;
  transform: translateX(-50%);
  transition: all 0.15s ease-in;
  z-index: 10;
}

.kip-utility-bar__holder {
  transition: width 0.25s, display 0.25s;
  background-color: white;
  border-left: 1px solid #e7e7e7;
  box-shadow: -2px 0 4px rgba(212, 212, 212, 0.25);
}
.kip-utility-bar__holder--collapsed {
  width: 2rem;
}
.kip-utility-bar__holder--expanded {
  width: 15rem;
}
.kip-utility-bar__content {
  display: none;
  position: sticky;
  top: 0;
  margin: -1em 2rem 0;
  min-width: 11rem;
}
.kip-utility-bar__content--expanded {
  display: block;
}
.kip-utility-bar__toggle-button {
  position: sticky;
  top: calc(50% - 0.25rem);
  width: 0;
  height: 0;
  padding: 0.5rem;
  margin-top: -1px;
}
.kip-utility-bar__header {
  color: #505d68;
  font-size: 0.79rem;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 0.889rem;
  text-transform: uppercase;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  letter-spacing: 0.25em;
  border-bottom: 1px solid #e7e7e7;
  padding: 2em 0 1em;
  margin-bottom: 1em;
}
.kip-utility-bar__subhead {
  color: #505d68;
  font-size: 0.79rem;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 0.889rem;
  text-transform: uppercase;
  font-weight: 400;
  padding: 1em 0;
  letter-spacing: 0.25em;
}
.kip-utility-bar__option {
  background-color: #3187c5;
  width: 2.75em;
  height: 2.75em;
  border-radius: 2.75em;
  color: #fff;
  text-align: center;
  user-select: none;
  cursor: pointer;
}
.kip-utility-bar__option:hover {
  background-color: rgb(38.8414634146, 107.012195122, 156.1585365854);
}
.kip-utility-bar__more-link {
  cursor: pointer;
  color: #3187c5;
  text-align: center;
  padding: 0.5em 0;
  user-select: none;
}

.kip-booking {
  border-radius: 0.625rem;
  padding: 1rem;
  transition: border 0.1s ease-in, box-shadow 0.1s ease-in;
}
.kip-booking--selected {
  border-color: #3889d4;
}
.kip-booking .kip-info, .kip-booking .kip-info--auto {
  margin-bottom: 0;
}
.kip-booking .kip-info__entry {
  margin-bottom: 0;
}
.kip-booking .kip-info__subtitle {
  margin-bottom: 0;
}
.kip-booking:focus-within {
  border: 1px solid #3889d4;
  box-shadow: 0 0 0 0.2rem rgba(0, 84, 159, 0.25);
}
.kip-booking:hover {
  border: 1px solid #3889D4;
  box-shadow: 0 2px 4px rgba(56, 137, 212, 0.25);
  cursor: pointer;
  transition: border 0.12s ease, box-shadow 0.12s ease;
}