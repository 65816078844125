body {
  color: $raven;
  background-color: $gray-100;
}

.kip-container {
  background-color: $body__background-color;
  height: calc(100% - #{$kip-navbar__height});
}

.kip-container__scrollable {
  -webkit-overflow-scrolling: touch;
}

.page-divider {
  border: 1px solid #ccc;
}

.kip-landmark__header {
  color: $raven;
  height: calc(2 * #{$kip-navbar__height});
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around; // space-evenly not supported by Edge :(

  margin: {
    top: 1rem;
    bottom: 1rem;
  }
}

.kip-content-scrollable__parent {
  @include make-row;

  margin-right: 0;
  margin-left: 0;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.kip-content-scrollable__body {
  width: 100%;
  overflow: auto;
}

.kip-content-scrollable__body--fill {
  @extend .kip-content-scrollable__body;

  flex: 1;
}

.kip-content-scrollable__footer {
  @include make-col-ready;

  @include media-breakpoint-up(md) {
    display: none;
  }

  border-top: $border-width solid $border-color !important; // Override .border-top
  box-shadow: $box-shadow !important; // Override .shadow
  margin-top: ($spacer);
  padding-top: ($spacer);
  padding-bottom: ($spacer);
}


// Hack to fix display of computer activity content in FMS Lesson History
kip-student-lesson-history .kip-explore-computer-container {
  min-height: 1000px;
}
