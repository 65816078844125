.kip-navbar-logo {
  height: 1.6rem;
  user-select: none;
}

.navbar {
  min-height: #{$kip-navbar__height};
}

.navbar .kip-dropdown__menu--top-right::after {
  right: -$spacer;

  @include media-breakpoint-up(sm) {
    right: -$spacer * 0.55;
  }
}
