$contact__border: 1px solid #3889d4;
$contact__shadow: 0 2px 4px rgb(56 137 212 / 25%);
$contact__background--add: #fefefe;

.kip-document {
  border: 1px solid $border-color;
  border-radius: $border-radius--large;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $spacer;
  transition: all 0.15s linear;

  &:hover {
    border: $contact__border;
    box-shadow: $contact__shadow;
  }

  &__btn {
    align-self: start;
    font-size: 0.79rem;
    font-weight: 600;
  }

  &__text--add {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1rem;
    letter-spacing: 0.125rem;
    text-transform: uppercase;
  }

  &--add {
    border: 1px solid #ccc;
    border-radius: $border-radius--large;
    transition: all 0.2s linear;
    align-items: center;
    background: $contact__background--add;
    border-style: dashed;
    color: $primary;
    justify-content: center;
    width: 100%;

    &:hover {
      border: $contact__border;
      box-shadow: $contact__shadow;
    }
  }
}
