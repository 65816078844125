$interaction-color: $corporate-blue;

@mixin make-selected-interaction {
  color: white;
  background-color: $interaction-color;
  border: 0;
  cursor: pointer;
}

.kip-picker {
  &__day {
    transition: all 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #e7e7e7;
    border-radius: 50%;
    height: 2.369rem;
    width: 2.369rem;

    @include media-breakpoint-up(sm) {
      height: 3.157rem;
      width: 3.157rem;
    }

    &:hover:not(.disabled) {
      @include make-selected-interaction;
    }

    &--selected:not(.disabled) {
      @include make-selected-interaction;
    }

    &.disabled {
      background-color: $input-disabled-bg;

      // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
      opacity: 1;
    }

    &--selected.disabled {
      background-color: #505d68;
      color: white;
    }
  }
}
