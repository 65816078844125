@use "sass:color";

@mixin make-lesson-result-indicator($indicator-color) {
  border-left: 0.3em solid color.change($indicator-color, $alpha: 0.5);
}

.kip-history {
  color: $raven;

  .kip-section-toggle {
    cursor: pointer;

    &:hover {
      background-color: $background--secondary;
    }
  }
}

.history-list {
  &__time-spent {
    width: 30%;

    @include media-breakpoint-up(lg) {
      width: 7em;
    }
  }

  &__activity-attempted {
    width: 30%;

    @include media-breakpoint-up(lg) {
      width: 7em;
    }
  }

  &__percentage {
    width: 3.5em;
  }

  &__result-indicator {
    &--success {
      @include make-lesson-result-indicator($kip-lesson-color--success);
    }

    &--warning {
      @include make-lesson-result-indicator($kip-lesson-color--warning);
    }

    &--danger {
      @include make-lesson-result-indicator($kip-lesson-color--danger);
    }
  }

  &__text-result-indicator {
    &--success {
      color: $kip-lesson-color--success;
    }

    &--warning {
      color: $kip-lesson-color--warning;
    }

    &--danger {
      color: $kip-lesson-color--danger;
    }
  }
}
