.kip-card-paged {
  // @TODO: Remove when kip-info margins are fixed.

  .kip-info__entry {
    margin-bottom: 0;
  }

  &__entry {
    &:nth-of-type(n+2) {
      padding-top: $spacer;
      border-top: 1px solid $border-color;
    }
  }

  &__pagination {
    font-size: $root__type--small;

    :disabled {
      color: #cfcfcf;
    }
  }
}
