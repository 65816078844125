.kip-search {
  &__results {
    min-width: 33vw;
  }

  &__input {
    background-color: transparent;
    border: 1px solid rgb(255 255 255 / 10%);
    padding: 0.125rem 0.5rem;
    color: white;

    &:focus {
      border: 1px solid rgb(255 255 255 / 100%);
    }
  }

  &__header {
    align-items: center;
    border-bottom: 1px solid $border-color;
    display: flex;
    font-size: $h2-font-size;
    font-weight: $font-weight-light;
  }

  &__clear {
    background-color: transparent;
    border-color: transparent;
    color: #fff;
  }
}
