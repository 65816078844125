@import "global-styles/variables";

$body__background-color: #f9f9f9;
$corporate-blue: $primary;
$blue: $primary;
$body-color: $raven;
$secondary: $raven;
$background--main: #fff;
$background--secondary: #f8f8f8;
$kip-navbar__height: 3rem;
$font-family-sans-serif: "Open Sans",
  -apple-system,
  blinkmacsystemfont,
  "Segoe UI",
  "Roboto",
  "Helvetica Neue",
  arial,
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol" !default;
$headings-margin-bottom: 0;
$headings-font-family: inherit;
$headings-font-weight: 500;
$headings-line-height: 1.5;
$headings-color: inherit;
$btn-padding-x-lg: 1.728rem;
$btn-font-size-lg: 1rem;
$kip-header__height: calc(2 * #{$kip-navbar__height});
$kip-header__padding--x: 1rem;
$kip-header__padding--y: 1rem;
$kip-header__padding: $kip-header__padding--y $kip-header__padding--x;
$kip-breadcrumb__spacer: 0.1618rem;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1400px
);
