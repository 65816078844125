.kip-payment-types {
  display: flex;
  width: 100%;

  :first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  :last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &__option {
    align-items: center;
    background: #fff;
    border: 1px solid #dfdfdf;
    box-sizing: border-box;
    color: #505d68;
    display: flex;
    flex: 1;
    font-weight: 600;
    font-size: 0.89rem;
    height: 3rem;
    justify-content: center;
    letter-spacing: 0.1em;
    line-height: 1rem;
    text-align: center;

    &--selected {
      background: #f5faff;
      border: 1px solid #3889d4;
      border-radius: 4px 0 0 4px;
      box-sizing: border-box;
      color: $primary;
    }
  }
}
