$bundle__border: 1px solid #3889d4;
$bundle__border--is-selected: 1px solid #3889d4;
$bundle-color: #505d68;
$bundle__shadow: 0 2px 4px rgb(56 137 212 / 25%);

.kip-current-bundle {
  border: 1px solid $border-color;
  border-radius: 10px;
  display: flex;
  cursor: pointer;
  min-height: $kip-card__min-height;

  &:hover {
    border: $bundle__border;
    box-shadow: $bundle__shadow;
  }

  &--is-a-choice {
    flex-direction: column;
  }

  &--is-selected {
    border: $bundle__border--is-selected;
  }

  &__name {
    color: $bundle-color;
    line-height: 30px;
    font-size: 1.383rem;
    font-weight: 300;
  }

  &__summary {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: space-around;
  }

  &__terms {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  &__section {
    display: flex;
    flex-direction: column;
    padding: $spacer;

    @include media-breakpoint-down(lg) {
      &.border-right {
        border-right: none !important; // Override .border-right utility class for breakpoint
      }
    }
  }

  &__enrolment {
    font-size: 0.889em;
    color: $bundle-color;
    margin-bottom: $spacer * 0.5;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__data-group {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
  }

  &__key {
    font-style: normal;
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 25px;
    letter-spacing: 0.01em;
    color: $bundle-color;
  }

  &__value {
    color: rgb(0 84 159 / 80%);
    font-style: normal;
    font-weight: 300;
    font-size: 1.602rem;

    @include media-breakpoint-up(xxl) {
      font-size: 1.802rem;
    }
  }

  &__selector {
    align-items: center;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 1px solid $border-color;

    &--is-selected {
      background-color: #f5faff;
      border-top: 1px solid #80a9cf;
    }
  }

  &__select {
    font-weight: 600;
    font-size: 13.56px;
    line-height: 18px;
    letter-spacing: 0.1em;
    color: #00549f;
    opacity: 0.8;
    min-height: 48px;
  }

  &__list-container {
    list-style: none;
    padding: 0;
  }

  hr.kip-divider {
    width: 62%;
    border-color: $border-color;
    margin: 0;
  }
}
