.file-uploader-drop-area {
  border: 2px dashed #ccc;
  border-radius: 10px;
  margin: 20px auto;
  padding: 20px;
  height: 400px;
  overflow-y: auto;
}

.file-uploader-drop-area-small {
  border: 2px dashed #ccc;
  border-radius: 10px;
  margin: 10px auto;
  padding: 10px;
  overflow-y: auto;
}

.file-uploader-drop-area-small.highlight {
  border-color: purple;
}

.file-uploader-form {
  margin-bottom: 10px;
}

.file-uploader-gallery {
  margin-top: 10px;
}

.file-uploader-problems {
  margin-top: 10px;
}

.file-uploader-gallery img {
  width: 150px;
  margin-bottom: 10px;
  margin-right: 10px;
  vertical-align: middle;
}

.file-uploader-file {
  display: none;
}

.file-uploader-progress {
  margin: 10px;
}
