$account-color: #505d68;

.kip-current-account {
  border: 1px solid $border-color;
  border-radius: 10px;
  display: flex;
  min-height: $kip-card__min-height;

  &--is-a-choice {
    flex-direction: column;

    &:hover {
      @include bootstrap-focus-style;
    }
  }

  &--is-selected {
    border: 1px solid #80a9cf;
  }

  &__name {
    color: $account-color;
    line-height: 30px;
    font-size: 1.383rem;
    font-weight: 300;
  }

  &__summary {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: space-around;
  }

  &__section {
    display: flex;
    flex-direction: column;
    padding: $spacer;
  }

  &__data-group {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
  }

  &__selector {
    align-items: center;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 1px solid $border-color;

    &--is-selected {
      background-color: #f5faff;
      border-top: 1px solid #80a9cf;
    }
  }

  &__select {
    font-weight: 600;
    font-size: 13.56px;
    line-height: 18px;
    letter-spacing: 0.1em;
    color: #00549f;
    opacity: 0.8;
    min-height: 48px;
  }

  &__list-container {
    list-style: none;
    padding: 0;
  }

  hr.kip-divider {
    width: 62%;
    border-color: $border-color;
    margin: 0;
  }
}
