.kip-booking {
  border-radius: 0.625rem;
  padding: 1rem;
  transition:
    border 0.1s ease-in,
    box-shadow 0.1s ease-in;

  &--selected {
    border-color: #3889d4;
  }

  .kip-info {
    margin-bottom: 0;
  }

  .kip-info__entry {
    margin-bottom: 0;
  }

  .kip-info__subtitle {
    margin-bottom: 0;
  }

  &:focus-within {
    @include bootstrap-focus-style;
  }

  &:hover {
    @include make-card-hover;
  }
}
