ol.kip-breadcrumbs {
  align-items: center;
  display: flex;
  list-style: none;
  height: 1rem;
  margin: 0;
  padding-left: 0;
  line-height: auto;

  li {
    display: inline;
  }

  li+li::before {
    color: $text-muted;
    content: "/";
    margin-right: $kip-breadcrumb__spacer;
    white-space: nowrap;
  }

  a,
  fa-icon {
    color: $text-muted;
    margin-right: $kip-breadcrumb__spacer;
  }
}
