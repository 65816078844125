@use "sass:color";

$sidebar__border-color: $border-color;
$sidebar__border-color--hover: $corporate-blue;
$sidebar__bg-color: $background--main;
$sidebar__bg-color--hover: $background--secondary;
$sidebar__icon-color: #6e6e6e;
$sidebar__text-color: #3c3c3c;
$sidebar__width: 15rem;
$indicator__border-width: 0.236rem;

@mixin make-icon-label-grid {
  display: grid;

  grid-template: {
    areas: "icon text";
    columns: 1rem 1fr;
    rows: 3rem;
  }

  column-gap: 1.618rem;
  align-items: center;
  padding: 0 1rem;
}

@mixin make-indicator {
  background-color: $sidebar__bg-color--hover;
  box-shadow: inset 0.236rem 0 0 0 $sidebar__border-color--hover;
}

.kip-sidebar {
  height: 100%;
  overflow: auto;
  background-color: $sidebar__bg-color;
  border-right: 1px solid $sidebar__border-color;
  width: 100%;

  @include media-breakpoint-up(md) {
    width: $sidebar__width;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  &__group a {
    &~* {
      display: none;
    }

    &.active~* {
      @include make-indicator;

      display: grid;
    }

    &.active {
      @include make-indicator;

      text-shadow: 0 0 0 $sidebar__text-color;
    }
  }

  a {
    @include make-icon-label-grid;

    color: $sidebar__text-color;
    text-decoration: none;

    &:hover {
      @include make-indicator;

      background-color: color.adjust($sidebar__bg-color--hover, $lightness: -2%);
    }

    &.active {
      @include make-indicator;

      font-weight: 600;

      &:hover {
        background-color: color.adjust($sidebar__bg-color--hover, $lightness: -2%);
      }
    }
  }

  &__icon {
    grid-area: icon;
  }

  &__label {
    grid-area: text;
  }
}
