@use "sass:math";
@use "sass:color";

$interaction-color: #3187c5;

@mixin make-interaction($color: $interaction-color) {
  background-color: $color;
  cursor: pointer;
}

.kip-day-finder {
  align-items: center;
  background-color: white;
  display: flex;
  height: $kip-header__height;
  max-height: $kip-header__height;
  padding: math.div($spacer, 2);

  &__day-picker {
    align-items: center;
    display: flex;
    justify-content: center;

    @include media-breakpoint-up(xl) {
      width: 15rem;
    }

    &:hover {
      color: $interaction-color;
      cursor: pointer;
    }
  }

  &__today-icon {
    height: 2rem;
    user-select: none;

    &:hover {
      cursor: pointer;
    }
  }

  &__browse {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
  }

  &__label {
    font-size: 0.878rem;
    font-weight: 500;
    letter-spacing: 0.02em;
    line-height: 0.889rem;
    text-transform: uppercase;
  }

  &__entry {
    align-items: center;
    display: flex;
    font-size: $h2-font-size;
    font-weight: 500;
    height: 2.6rem;
    justify-content: center;
    width: 2.6rem;

    &--circle {
      border-radius: 50%;

      &:hover {
        @include make-interaction(#f3f3f1);
      }
    }

    &--is-today {
      color: $interaction-color;
      border: 1px solid $interaction-color;

      &:hover {
        @include make-interaction($interaction-color);

        border: 1px solid $interaction-color;
        color: white;
      }
    }

    &--is-selected {
      @include make-interaction;

      color: white;

      &:hover {
        @include make-interaction(color.adjust($interaction-color, $lightness: -5%));
      }
    }
  }
}
